/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import Departments from "../pages/departments/departments";
import SubDepartment from "../pages/subDepartment/subDepartment";
import Users from "../pages/users/users";
import Customers from "../pages/customers/customers";
import CommingSoon from "../pages/configuration/commingSoon";
import Areas from "../pages/configuration/areas/areas";
import School from "../pages/configuration/school/school";
import Driver from "../pages/configuration/driver/driver";
import Profile from "../pages/users/profile";
import Settings from "../pages/settings/settings";
import Reports from "../pages/reports/mainReports";
import TreeReport from "../pages/treeReport/treeReport";

export const Routes = withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const { isAuthorized, menuConfig, userLastLocation } = useSelector(
        ({ auth, urls, builder: { menuConfig } }) => ({
            menuConfig,
            isAuthorized: (localStorage.getItem('USER_ID') != '' && localStorage.getItem('USER_ID') != undefined && localStorage.getItem('USER_ID') != null) ? true : false,
            userLastLocation: routerHelpers.getLastLocation()
        }),
        shallowEqual
    );

    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <LayoutContextProvider history={history} menuConfig={menuConfig}>
            <Switch>
                {!isAuthorized ? (
                    /* Render auth page when user at `/auth` and not authorized. */
                    <AuthPage />
                ) : (
                        /* Otherwise redirect to root page (`/`) */
                        <Redirect from="/auth" to={userLastLocation} />
                    )}

                <Route path="/error" component={ErrorsPage} />
                <Route path="/logout" component={LogoutPage} />
                <Route path="/pages/departments" component={Departments} />
                <Route path="/pages/subDepartment/:parent?" component={SubDepartment} />
                <Route path="/pages/users" component={Users} />
                <Route path="/pages/customers/:filter?" component={Customers} />
                <Route path="/pages/configuration/commingSoon" component={CommingSoon} />
                <Route path="/pages/configuration/areas/:parent?" component={Areas} />
                <Route path="/pages/configuration/school" component={School} />
                <Route path="/pages/configuration/driver" component={Driver} />
                <Route path="/pages/profile" component={Profile} />
                <Route path="/pages/settings" component={localStorage.getItem('USER_LEVEL') == 3 ? Settings : HomePage} />
                <Route path="/pages/reports/:reportId?" component={Reports} />
                <Route path="/pages/treeReport" component={TreeReport} />

                {!isAuthorized ? (
                    /* Redirect to `/auth` when user is not authorized */
                    <Redirect to="/auth/login" />
                ) : (
                        <Layout>
                            <HomePage userLastLocation={userLastLocation} />
                        </Layout>
                    )}
            </Switch>
        </LayoutContextProvider>
    );
});
