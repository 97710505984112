import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Tooltip,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
class Finance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allDepartments: this.props.allDepartments,
      multiValue:
        Object.keys(this.props.financeDataMultiValue).length > 0
          ? this.props.financeDataMultiValue
          : [],
      reportActionList:
        Object.keys(this.props.financeData).length > 0
          ? this.props.financeData
          : [],
      toolTipArray: false,
    };
  }
  componentDidMount = () => {
    console.log(this.props.financeData, "this.props.financeData");
  };
  handleMultiChange = (option) => {
    this.setState(
      (state) => {
        return {
          multiValue: option,
        };
      },
      () => {
        this.props.handleGetTabData(
          8,
          this.state.reportActionList,
          this.state.multiValue
        );
      }
    );
  };

  checkRow = (event, reportId) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    const newArray = this.state.reportActionList;
    var nameElement = name;
    newArray[reportId + "_" + nameElement] = value;

    this.setState({ reportActionList: newArray }, function() {
      this.props.handleGetTabData(
        8,
        this.state.reportActionList,
        this.state.multiValue
      );
    });
  };
  addRowHover = (index) => {
    this.setState({ toolTipArray: true });
  };
  removeRowHover = (index) => {
    this.setState({ toolTipArray: false });
  };
  setReportActionRadio() {
    var reportActionRadioHTML = "";
    if (
      this.state.multiValue != undefined &&
      this.state.multiValue != null &&
      this.state.multiValue.length > 0
    ) {
      reportActionRadioHTML = this.state.multiValue.map(
        (multiValueData, index) => {
          return (
            <Row
              key={index}
              className="text-left mt-1 p-2 pl-5 font-weight-500 main-color-1 mb-4"
            >
              <Col md={3}>
                <Label>- {multiValueData.name}</Label>
              </Col>
              <Col md={9}>
                <Row style={{ color: "black" }}>
                  <Col md={3}>
                    <div className="custom-control custom-checkbox p-0">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={"reportAction-1-" + multiValueData.id}
                        checked={
                          this.state.reportActionList[multiValueData.id + "_1"]
                        }
                        name={"1"}
                        onClick={(event) =>
                          this.checkRow(event, multiValueData.id)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"reportAction-1-" + multiValueData.id}
                      >
                        &nbsp;
                      </label>
                      <span>كل شيئ</span>
                    </div>
                  </Col>
                  {/* <Col md={3}>
                                    <div className="custom-control custom-checkbox p-0">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-2-' + multiValueData.id} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={this.state.reportActionList[multiValueData.id + '_2'] || this.state.reportActionList[multiValueData.id + '_1']} name={'2'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-2-' + multiValueData.id}>&nbsp;</label>
                                        <span>عرض</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox p-0">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-3-' + multiValueData.id} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={this.state.reportActionList[multiValueData.id + '_3'] || this.state.reportActionList[multiValueData.id + '_1']} name={'3'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-3-' + multiValueData.id}>&nbsp;</label>
                                        <span>تعديل/اضافة</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox p-0">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-4-' + multiValueData.id} name={'4'} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={this.state.reportActionList[multiValueData.id + '_4'] || this.state.reportActionList[multiValueData.id + '_1']} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-4-' + multiValueData.id}>&nbsp;</label>
                                        <span>حذف</span>
                                    </div>
                                </Col> */}
                  {/* <Col md={3}>
                                    <div className="custom-control custom-checkbox p-0">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-5-' + multiValueData.id} name={'5'} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={this.state.reportActionList[multiValueData.id + '_5'] || this.state.reportActionList[multiValueData.id + '_1']} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-5-' + multiValueData.id}>&nbsp;</label>
                                        <span>ارسال رسالة</span>
                                    </div>
                                </Col> */}
                  {/* <Col md={3}>
                                    <div className="custom-control custom-checkbox p-0">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-6-' + multiValueData.id} name={'6'} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={this.state.reportActionList[multiValueData.id + '_6'] || this.state.reportActionList[multiValueData.id + '_1']} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-6-' + multiValueData.id}>&nbsp;</label>
                                        <span>نقل صاحب كشف</span>
                                    </div>
                                </Col> */}
                  {/* <Col md={3}>
                                    <div className="custom-control custom-checkbox p-0">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-7-' + multiValueData.id} name={'7'} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={this.state.reportActionList[multiValueData.id + '_7'] || this.state.reportActionList[multiValueData.id + '_1']} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-7-' + multiValueData.id}>&nbsp;</label>
                                        <span>طباعة البطاقة</span>
                                    </div>
                                </Col> */}
                  {index == 0 && (
                    <Col md={3} className="pr-0">
                      <Tooltip
                        className="ml-3"
                        style={{ zIndex: 2000 }}
                        placement="bottom"
                        isOpen={
                          this.state.toolTipArray == undefined
                            ? false
                            : this.state.toolTipArray
                        }
                        target={"toolTip" + index}
                        autohide={false}
                      >
                        {
                          <span
                            style={{ zIndex: 100 }}
                            className="report-tooltip-span"
                          >
                            <p>
                              سيتم تطبيق التغييرات التي تحصل على هذا العنصر
                              لباقي العناصر
                            </p>
                          </span>
                        }
                      </Tooltip>
                      <div
                        onClick={this.applayToAll_reportActionList.bind(
                          this,
                          multiValueData.id
                        )}
                        id={"toolTip" + index}
                        onMouseOver={this.addRowHover.bind(this)}
                        onMouseOut={this.removeRowHover.bind(this)}
                        className="permetion-matrix-applay-to-all-btn mt-3"
                      >
                        <i className="far fa-check-circle"></i> تطبيق للكل
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          );
        }
      );
    }
    return reportActionRadioHTML;
  }

  applayToAll_reportActionList = (reportId, event) => {
    if (reportId != undefined) {
      if (
        this.state.multiValue != undefined &&
        this.state.multiValue != null &&
        this.state.multiValue.length > 0
      ) {
        this.state.multiValue.map((multiValueData, index) => {
          const newArray = this.state.reportActionList;

          var nameElement = multiValueData.id + "_1";
          newArray[nameElement] = this.state.reportActionList[reportId + "_1"];

          var nameElement = multiValueData.id + "_2";
          newArray[nameElement] = this.state.reportActionList[reportId + "_2"];

          var nameElement = multiValueData.id + "_3";
          newArray[nameElement] = this.state.reportActionList[reportId + "_3"];

          var nameElement = multiValueData.id + "_4";
          newArray[nameElement] = this.state.reportActionList[reportId + "_4"];

          var nameElement = multiValueData.id + "_5";
          newArray[nameElement] = this.state.reportActionList[reportId + "_5"];

          var nameElement = multiValueData.id + "_6";
          newArray[nameElement] = this.state.reportActionList[reportId + "_6"];

          var nameElement = multiValueData.id + "_7";
          newArray[nameElement] = this.state.reportActionList[reportId + "_7"];

          this.setState({ reportActionList: newArray });
        });
      }
    }
  };
  render() {
    if (localStorage.getItem("USER_LEVEL") == 5) {
      return (
        <div>
          <p className="report-no-permission main-color-1">
            <i className="far fa-times-circle"></i> لا تمتلك صلاحية لاستعراض هذا
            التقرير
          </p>
        </div>
      );
    }
    return (
      <div>
        <div
          className="mt-n5 mr-3 ml-3"
          style={{ minHeight: 355, maxHeight: 355, backgroundColor: "#ECECEF" }}
        >
          <Row className="mt-5">
            <Col md={12}>
              <div>
                <Label className="text-left mt-3 p-3 pl-5 font-weight-500 main-color-1">
                  المراكز :{" "}
                </Label>
                <div className="searchable-list">
                  <Picky
                    value={this.state.multiValue}
                    options={this.state.allDepartments}
                    onChange={this.handleMultiChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    selectAllText="اختر جميع المراكز"
                    placeholder="اختر"
                    filterPlaceholder="اختر المركز"
                  />
                </div>
              </div>

              {this.state.multiValue != undefined &&
                this.state.multiValue != null &&
                this.state.multiValue.length > 0 && (
                  <div
                    className="mt-3 pt-2"
                    style={{
                      minHeight: 238,
                      maxHeight: 238,
                      overflowX: "hidden",
                      overflowY: "auto",
                    }}
                  >
                    <div>{this.setReportActionRadio()}</div>
                  </div>
                )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Finance;
