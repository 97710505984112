import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, Tooltip } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiValue: (Object.keys(this.props.reportMultiValue).length > 0 ? this.props.reportMultiValue : []),
            reportActionList: (Object.keys(this.props.reportData).length > 0 ? this.props.reportData : []),
            allReports: [
                {
                    'id': '1',
                    'name': 'معلومات الناخبين حسبب اللجنة التابع لها'
                },
                {
                    'id': '2',
                    'name': 'عدد الناخبين حسب المدرسة'
                },
                {
                    'id': '3',
                    'name': 'عدد الناخبين حسب اللجان الفرعية'
                },
                {
                    'id': '4',
                    'name': 'عناوين المدارس (المراكز الانتخابية)'
                },
                {
                    'id': '5',
                    'name': 'عدد الناخبين حسب اللجان الاساسية'
                },
                {
                    'id': '6',
                    'name': 'كشف لجنة فرعية (صاحب كشف) تفصيلي'
                },
                {
                    'id': '7',
                    'name': 'تقرير احصائي لعدد الناخبين حسب اللجان الرئيسية والفرعية'
                },
                {
                    'id': '8',
                    'name': 'معلومات تفصيلية لأصحاب الكشوفات'
                },
                {
                    'id': '9',
                    'name': 'تقرير العمليات المالية'
                },
                {
                    'id': '10',
                    'name': 'تقرير العمليات المالية حسب الجمعية'
                }
            ],
            toolTipArray: false,
        }
    }

    handleMultiChange = (option) => {
        this.setState(state => {
            return {
                multiValue: option
            };
        },()=>{
            this.props.handleGetTabData(1, this.state.reportActionList, this.state.multiValue)

        });
    }

    checkRow = (event, reportId) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const newArray = this.state.reportActionList
        var nameElement = name
        newArray[reportId + '_' + nameElement] = value;



        this.setState({ reportActionList: newArray }, function () {
            this.props.handleGetTabData(1, this.state.reportActionList, this.state.multiValue)
        });


    }
    addRowHover = (index) => {
        this.setState({ toolTipArray: true })
    }
    removeRowHover = (index) => {
        this.setState({ toolTipArray: false })
    }
    setReportActionRadio() {
        var reportActionRadioHTML = "";
        if (this.state.multiValue != undefined && this.state.multiValue != null && this.state.multiValue.length > 0) {
            reportActionRadioHTML = this.state.multiValue.map((multiValueData, index) => {
                return (
                    <Row key={index} className='text-left mt-1 p-2 pl-5 font-weight-500 main-color-1'>
                        <Col md={5}>
                            <Label>- {multiValueData.name}</Label>
                        </Col>
                        <Col md={7}>
                            <Row style={{ 'color': 'black' }}>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-1-' + multiValueData.id} checked={this.state.reportActionList[multiValueData.id + '_1']} name={'1'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-1-' + multiValueData.id}>&nbsp;</label>
                                        <span>كل شيئ</span>
                                    </div>
                                </Col>
                                {/* <Col md={3}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-2-' + multiValueData.id} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={(this.state.reportActionList[multiValueData.id + '_2'] || this.state.reportActionList[multiValueData.id + '_1'])} name={'2'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-2-' + multiValueData.id}>&nbsp;</label>
                                        <span>عرض</span>
                                    </div>
                                </Col> */}
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-3-' + multiValueData.id} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={(this.state.reportActionList[multiValueData.id + '_3'] || this.state.reportActionList[multiValueData.id + '_1'])} name={'3'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-3-' + multiValueData.id}>&nbsp;</label>
                                        <span>تصدير</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-4-' + multiValueData.id} name={'4'} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={(this.state.reportActionList[multiValueData.id + '_4'] || this.state.reportActionList[multiValueData.id + '_1'])} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-4-' + multiValueData.id}>&nbsp;</label>
                                        <span>PDF</span>
                                    </div>
                                </Col>

                                {index == 0 &&
                                    <Col md={3} className='pr-0'>
                                        <Tooltip
                                            className="ml-3"
                                            style={{ zIndex: 2000 }}
                                            placement="bottom"
                                            isOpen={this.state.toolTipArray == undefined ? false : this.state.toolTipArray}
                                            target={'toolTip' + index}
                                            autohide={false}
                                        >
                                            {<span style={{ zIndex: 100 }} className='report-tooltip-span'>
                                                <p>سيتم تطبيق التغييرات التي تحصل على هذا العنصر لباقي العناصر</p>
                                            </span>}
                                        </Tooltip>
                                        <div onClick={this.applayToAll_reportActionList.bind(this, multiValueData.id)} id={'toolTip' + index} onMouseOver={this.addRowHover.bind(this)} onMouseOut={this.removeRowHover.bind(this)} className='permetion-matrix-applay-to-all-btn'><i className='far fa-check-circle'></i> تطبيق للكل</div>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                )
            })
        }
        return reportActionRadioHTML
    }

    applayToAll_reportActionList = (reportId, event) => {
        if (reportId != undefined) {
            if (this.state.multiValue != undefined && this.state.multiValue != null && this.state.multiValue.length > 0) {


                this.state.multiValue.map((multiValueData, index) => {
                    const newArray = this.state.reportActionList;

                    var nameElement = multiValueData.id + '_1'
                    newArray[nameElement] = this.state.reportActionList[reportId + '_1'];

                    var nameElement = multiValueData.id + '_3'
                    newArray[nameElement] = this.state.reportActionList[reportId + '_3'];

                    var nameElement = multiValueData.id + '_4'
                    newArray[nameElement] = this.state.reportActionList[reportId + '_4'];

                    this.setState({ reportActionList: newArray });
                })
            }

        }
    }

    render() {

        // console.log('report',this.props);

        return (
            <div>
                <div className='mt-n5 mr-3 ml-3' style={{ minHeight: 355, maxHeight: 355, backgroundColor: '#ECECEF' }}>
                    <Row className='mt-5'>
                        <Col md={12}>
                            <div>
                                <Label className='text-left mt-3 p-3 pl-5 font-weight-500 main-color-1'>التقارير : </Label>
                                <div className='searchable-list'>
                                    <Picky
                                        value={this.state.multiValue}
                                        options={this.state.allReports}
                                        onChange={this.handleMultiChange}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        multiple={true}
                                        includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={200}
                                        selectAllText='اختر جميع التقارير'
                                        placeholder='اختر'
                                        filterPlaceholder='اختر التقرير'
                                    />
                                </div>
                            </div>

                            {this.state.multiValue != undefined && this.state.multiValue != null && this.state.multiValue.length > 0 &&
                                <div className='mt-3 pt-2' style={{ minHeight: 238, maxHeight: 238, 'overflowX': 'hidden', 'overflowY': 'auto' }}>
                                    <div>
                                        {this.setReportActionRadio()}
                                    </div>
                                </div>
                            }
                        </Col>

                    </Row>
                </div>
            </div>
        )
    }
}

export default Reports;