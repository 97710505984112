import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Picky from "react-picky";
import "react-picky/dist/picky.css";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multiValue:
        Object.keys(this.props.customersDataMultiValue).length > 0
          ? this.props.customersDataMultiValue
          : [],
      reportActionList:
        Object.keys(this.props.customersData).length > 0
          ? this.props.customersData
          : [],
    };
  }

  checkRow = (event, reportId) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    const newArray = this.state.reportActionList;
    var nameElement = name;
    newArray[reportId + "_" + nameElement] = value;

    this.setState({ reportActionList: newArray }, function() {
      this.props.handleGetTabData(
        4,
        this.state.reportActionList,
        this.state.multiValue
      );
    });
  };

  setReportActionRadio() {
    return (
      <Row className="text-left mt-1 p-2 pl-5 font-weight-500 main-color-1">
        <Col
          md={5}
          className="text-left mt-3 p-3 pl-5 font-weight-500 main-color-1"
        >
          سيتم تطبيق الصلاحية على هذه الاقسام:
          <ul>
            <li className="mt-4 mb-4">
              <h5>الناخبين المصوتين / غير المصوتين</h5>
            </li>
            <li className="mt-4 mb-4">
              <h5>الناخبين الذين يحق لهم التصويت</h5>
            </li>
            <li className="mt-4 mb-4">
              <h5>الناخبين الذين لا يحق لهم التصويت</h5>
            </li>
          </ul>
        </Col>
        <Col md={7} className="mt-4">
          <Row style={{ color: "black" }} className="mt-5 mb-5">
            <Col md={6}>
              <div className="custom-control custom-checkbox p-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-1-1"}
                  checked={this.state.reportActionList["1_1"]}
                  name={"1"}
                  onClick={(event) => this.checkRow(event, 1)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-1-1"}
                >
                  &nbsp;
                </label>
                <span>كل شيئ</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-2-2"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["2_2"] ||
                    this.state.reportActionList["1_1"]
                  }
                  name={"2"}
                  onClick={(event) => this.checkRow(event, 2)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-2-2"}
                >
                  &nbsp;
                </label>
                <span>عرض</span>
              </div>
            </Col>
          </Row>
          <Row style={{ color: "black" }}>
            <Col md={6}>
              <div className="custom-control custom-checkbox p-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-3-3"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["3_3"] ||
                    this.state.reportActionList["1_1"]
                  }
                  name={"3"}
                  onClick={(event) => this.checkRow(event, 3)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-3-3"}
                >
                  &nbsp;
                </label>
                <span>تعديل / اضافة</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-4-4"}
                  name={"4"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["4_4"] ||
                    this.state.reportActionList["1_1"]
                  }
                  onClick={(event) => this.checkRow(event, 4)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-4-4"}
                >
                  &nbsp;
                </label>
                <span>حذف</span>
              </div>
            </Col>
          </Row>
          <Row style={{ color: "black" }} className="mt-5 mb-5">
            <Col md={6}>
              <div className="custom-control custom-checkbox p-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-5-5"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["5_5"] ||
                    this.state.reportActionList["1_1"]
                  }
                  name={"5"}
                  onClick={(event) => this.checkRow(event, 5)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-5-5"}
                >
                  &nbsp;
                </label>
                <span>تصدير</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-6-6"}
                  name={"6"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["6_6"] ||
                    this.state.reportActionList["1_1"]
                  }
                  onClick={(event) => this.checkRow(event, 6)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-6-6"}
                >
                  &nbsp;
                </label>
                <span>استيراد</span>
              </div>
            </Col>
          </Row>
          <Row style={{ color: "black" }}>
            <Col md={6}>
              <div className="custom-control custom-checkbox p-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-7-7"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["7_7"] ||
                    this.state.reportActionList["1_1"]
                  }
                  name={"7"}
                  onClick={(event) => this.checkRow(event, 7)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-7-7"}
                >
                  &nbsp;
                </label>
                <span>ارسال رسالة</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-8-8"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["8_8"] ||
                    this.state.reportActionList["1_1"]
                  }
                  name={"8"}
                  onClick={(event) => this.checkRow(event, 8)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-8-8"}
                >
                  &nbsp;
                </label>
                <span>PDF</span>
              </div>
            </Col>
          </Row>
          <Row style={{ color: "black" }} className="mt-5 mb-5">
            <Col md={6}>
              <div className="custom-control custom-checkbox p-0">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-9-9"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["9_9"] ||
                    this.state.reportActionList["1_1"]
                  }
                  name={"9"}
                  onClick={(event) => this.checkRow(event, 9)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-9-9"}
                >
                  &nbsp;
                </label>
                <span>تعيين كمنتخب</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={"reportAction-10-10"}
                  disabled={this.state.reportActionList["1_1"]}
                  checked={
                    this.state.reportActionList["10_10"] ||
                    this.state.reportActionList["1_1"]
                  }
                  name={"10"}
                  onClick={(event) => this.checkRow(event, 10)}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"reportAction-10-10"}
                >
                  &nbsp;
                </label>
                <span>طباعة البطاقة</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  render() {
    
    return (
      <div>
        {localStorage.getItem("USER_LEVEL") == 5 ? (
          <div>
            <p className="report-no-permission main-color-1">
              <i className="far fa-times-circle"></i> لا تمتلك صلاحية لاستعراض
              هذا التقرير
            </p>
          </div>
        ) : (
          <div
            className="mt-n5 mr-3 ml-3"
            style={{
              minHeight: 355,
              maxHeight: 355,
              backgroundColor: "#ECECEF",
            }}
          >
            <Row className="mt-5">
              <Col md={12}>{this.setReportActionRadio()}</Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default Customers;
