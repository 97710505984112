import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class ImportCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressUploadFile: 0,
            displayErrorFileMsg: false,
            excelRow: 6,
            errorCount: 0,
            data: [],//errors
            succCount: 0,
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleValidSubmit(event) {
        var data = new FormData();
        var importCustomersData = document.querySelector('input[name=importCustomers]').files[0];


        if (importCustomersData != undefined && importCustomersData != null && importCustomersData != '') {
            data.append("data", importCustomersData);
            data.append("name", 'data');
            data.append("type", 'importCustomers');
            data.append("userId", localStorage.getItem('USER_ID'));
            data.append("token", localStorage.getItem('USER_TOKEN'));
            data.append("countrow", this.state.excelRow);
            fetch(window.API_URL_DATA, {
                // mode: 'no-cors',
                method: "POST",
                body: data,
                headers: {
                    Accept: 'application/json',
                },
            }).then(function (response) {
                if (!response.ok) {
                }
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    this.getImportCustomersStatus();
                    if (data['resNo'] == 0) {
                        this.props.handleImportAction();
                    } else {
                        this.setState({
                            errorCount: data['errorCount'],
                            data: data['errors'],
                            succCount: data['succCount'],
                        })
                    }
                }
            });
        } else {
            this.setState({ displayErrorFileMsg: true, progressUploadFile: 0 });
        }
        setTimeout(function(){ window.location.reload(); }, 3000);
        
    }
    getImportCustomersStatus() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getImportCustomersStatus',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                localStorage.setItem('uploadExcelFileStatus', data['description']);
                localStorage.setItem('uploadExcelFileTotal', data['keyword_SEO']);
                localStorage.setItem('uploadExcelFileFinished', data['desc_SEO']);
            }
        });
    }
    handleFileChange = (event) => {
        var fileReader = new FileReader();
        const scope = this
        if (event.target.files[0].type.includes("application/vnd.ms-excel")) {
            this.setState({ displayErrorFileMsg: false });
            this.setState({ progressUploadFile: 10 });
            fileReader.readAsBinaryString(event.target.files[0]);
            fileReader.onprogress = function (data) {
                if (data.lengthComputable) {
                    var progress = parseInt(((data.loaded / data.total) * 100), 10);
                    scope.setState({ progressUploadFile: progress });
                }
            }
        } else {
            this.setState({ displayErrorFileMsg: true, progressUploadFile: 0 });
        }
        this.setState({
            importCustomers: event.target.files[0]['name']
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value,
        })
    }
    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }

    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }
    render() {

        var mainCol = []
        var dataCol = []
        var actionCol = []

        if (this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {
                let errorMsg = rowData['errMsg'];
                let errors = rowData['data'];
                let lineNo = rowData['lineNo'];
                mainCol.push(
                    <div key={index} className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-4 col-sm-4 col-md-4 col-lg-4 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {lineNo}
                        </div>

                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-8 col-sm-8 col-md-8 col-lg-8 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[0] == undefined || errors[0] == '' ? '-' : errors[0]}
                        </div>
                    </div>
                )

                var checkEvenRow = index % 2;
                dataCol.push(
                    <div className='row flex-row flex-nowrap' key={index} >
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[1] == undefined || errors[1] == '' ? '-' : (errors[1] == 1 ? 'ذكر' : 'انثى')}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[2] == undefined || errors[2] == '' ? '-' : errors[2]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[3] == undefined || errors[3] == '' ? '-' : errors[3]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[4] == undefined || errors[4] == '' ? '-' : errors[4]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[5] == undefined || errors[5] == '' ? '-' : errors[5]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[6] == undefined || errors[6] == '' ? '-' : errors[6]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[7] == undefined || errors[7] == '' ? '-' : errors[7]}
                        </div>
                    </div>
                )
                actionCol.push(
                    <div className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('login-error-msg col-12 col-sm-4 col-md-12 col-lg-12 table-body-row overflow-text-length text-right action-btn-view')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} >
                            {errorMsg}
                        </div>
                    </div>
                )

            })
        }
        return (
            <div>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'>استيراد
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn mr-5' onClick={this.props.handleCloseImportSideBar}></i>
                            </Col>
                        </Row>
                    </div>


                    <div className='sidebar-body-view'>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            {this.state.errorCount <= 0 &&
                                <div className='mb-3'>
                                    <label>قائمة الناخبين</label>
                                    <span className={'fileInput-span col-md-12 mb-3'}>
                                        <i className="fas fa-file-upload"></i> <span className='pointer-cursor task-action-form-fields-font'>تحميل</span>
                                        <input type="file" className='fileInput-width fileInput-opacity' onChange={(e) => this.handleFileChange(e)} name='importCustomers'></input>
                                    </span>
                                    {(this.state.progressUploadFile != undefined && this.state.progressUploadFile > 0) &&
                                        <Progress striped color="success" value={this.state.progressUploadFile} className='mb-1 mt-1'>{this.state.progressUploadFile} %</Progress>
                                    }
                                    {(this.state.displayErrorFileMsg == true) &&
                                        <div className='invalid-feedback d-block mb-1 mt-n3'>قم بتحميل ملف excel</div>
                                    }
                                    <AvField name="excelRow" type='number' label='رقم سطر بداية القراءة من الملف' placeholder='ادخل رقم سطر بداية القراءة من الملف' value={this.state.excelRow} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                </div>
                            }
                            {this.state.errorCount > 0 &&
                                <div>
                                    <div className='success-error pr-2 mb-1'>تم استيراد  <span style={{ color: '#1118f7' }}>{this.state.succCount == undefined || this.state.succCount == '' ? 0 : this.state.succCount}</span> بنجاح</div>
                                    <div className='login-error-msg pr-2 mb-5' style={{ fontSize: 19 }}>يوجد خطأ في البيانات التالية وعددها : {this.state.errorCount}</div>
                                    <Row className='m-1 mr-4 ml-4 mb-4 overflow-auto' style={{ maxHeight: 490 }}>
                                        <div className='col-md-3 col-sm-3 col-3 col-lg-3'>

                                            <div className="row flex-row flex-nowrap table-header-row" >
                                                <div className=" overflow-text-length d-inline-flex col-4">
                                                    <div>رقم السطر</div>
                                                </div>
                                                <div className=" overflow-text-length col-8">
                                                    <div>الرقم الوطني</div>
                                                </div>
                                            </div>
                                            {mainCol}
                                        </div>
                                        <div className='col-md-7 col-sm-7 col-7 col-lg-7 pr-0' style={{ 'overflowX': 'auto', 'overflowY': 'hidden', 'width': '100%' }}>
                                            <div className="row flex-row flex-nowrap">
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>الجنس</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>تاريخ الميلاد</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>الاسم الاول</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>اسم الاب</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>اسم الجد</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>اسم العائلة</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>المدرسة</div>
                                            </div>
                                            {dataCol}
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-2 col-lg-2'>
                                            <div className="row flex-row flex-nowrap" >
                                                <div className="col-12 col-sm-4 col-md-12 col-lg-12 table-header-row overflow-text-length text-right pl-4">الخطأ</div>
                                            </div>
                                            {actionCol}
                                        </div>
                                    </Row>
                                </div>
                            }
                            <div className="text-left">
                                {this.state.errorCount <= 0 &&
                                    <button type="submit" className="btn form-save-btn mr-1 ml-1 mr-2"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                }
                                <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseImportSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> إلغاء</button>
                            </div>
                        </AvForm>


                    </div>


                </div>
            </div>
        )
    }
}
export default ImportCustomer;