import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomersForm from "./customersForm";
import ImportCustomer from "./importCustomer";
import Picky from "react-picky";
import "react-picky/dist/picky.css";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      activePage: 1,
      limit: 10,
      dataCount: 0,
      openMenu: -1,
      openCheckedAction: false,
      checkedRow: [],
      showMoreData: [],
      showSubMoreData: [],
      openRightModal: false,
      sortColNum: "",
      selectedSortType: [],
      data: [],
      loading: 1,
      customerData: [],
      selectAtLeastOneFlag: 0,
      actionLoading: 0,
      colorTypes: [],
      adminType: -1,
      sortType: "",
      allMembers: [],
      allSchools: [],
      allArea: [],
      allDepartement: [],
      openImportModal: false,
      allSpecificArea: [],
      voted:
        this.props.voted != "" &&
        this.props.voted != undefined &&
        this.props.voted != null
          ? this.props.voted
          : 0,
      confirmSendSms: 0,
      regionId:
        this.props.regionId != "" &&
        this.props.regionId != undefined &&
        this.props.regionId != null
          ? this.props.regionId
          : "",
      stationId:
        this.props.stationId != "" &&
        this.props.stationId != undefined &&
        this.props.stationId != null
          ? this.props.stationId
          : "",
      permations: {},
      showNormalUserData: false,
      allMembersId: [],
      canVote:
        this.props.canVote != "" &&
        this.props.canVote != undefined &&
        this.props.canVote != null
          ? this.props.canVote
          : -1,
      allMemberList: [],
      multiValue: [],
      centerId: "",
      allSubDepartement: [],
    };
  }

  componentWillMount() {
    // console.log(this.props.match.params);
    if (
      this.props.match != undefined &&
      this.props.match.params != undefined &&
      this.props.match.params.filter == "all"
    ) {
      this.getPermation();
    } else if (
      this.props.match != undefined &&
      this.props.match.params != undefined &&
      this.props.match.params.filter == "voted"
    ) {
      this.setState(
        {
          voted: 1,
        },
        function() {
          this.getPermation();
        }
      );
    } else if (
      this.props.match != undefined &&
      this.props.match.params != undefined &&
      this.props.match.params.filter == "notVoted"
    ) {
      this.setState(
        {
          voted: -1,
        },
        function() {
          this.getPermation();
        }
      );
    } else {
      this.getPermation();
    }
  }
  getPermation() {
    var permations = {};
    let showNormalUserData = false;
    if (localStorage.getItem("USER_LEVEL") == 1) {
      permations =
        JSON.parse(localStorage.getItem("permations")) != undefined &&
        JSON.parse(localStorage.getItem("permations")) != null &&
        JSON.parse(localStorage.getItem("permations"))["customersData"] !=
          undefined &&
        JSON.parse(localStorage.getItem("permations"))["customersData"] != null
          ? JSON.parse(localStorage.getItem("permations"))["customersData"]
          : {};
    } else if (localStorage.getItem("USER_LEVEL") == 3) {
      permations = {
        "1_1": true, // all
        "2_2": true, // show data
        "3_3": true, // add & edit
        "4_4": true, // delete
        "5_5": true, // export
        "6_6": true, // import
        "7_7": true, // sms
        "8_8": true, // pdf
        "9_9": true, // mark as voited
        "10_10": true, // print cards
      };
    } else {
      showNormalUserData = true;
      permations = {
        "1_1": 0, // all
        "2_2": true, // show data
        "3_3": true, // add & edit
        "4_4": true, // delete
        "5_5": 0, // export
        "6_6": 0, // import
        "7_7": 0, // sms
        "8_8": 0, // pdf
        "9_9": 0, // mark as voited
        "10_10": true, // print cards
      };
    }
    this.setState(
      {
        showNormalUserData: showNormalUserData,
        permations: permations,
      },
      function() {
        if (this.state.showNormalUserData == true) {
          this.getMemberIdByAdmin();
        } else {
          this.getCustomerCount();
          this.getCustomerLimit();
          // this.getMembers();
          this.getSchools();
          this.getAllArea();
          this.getAllDepartement();
        }
      }
    );
  }
  getMemberIdByAdmin() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getPostByAdminAndFeaturedOne",
        adminId: localStorage.getItem("USER_ID"),
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState(
            {
              allMembersId: data,
            },
            function() {
              if (
                this.state.allMembersId != null &&
                this.state.allMembersId != undefined &&
                this.state.allMembersId.length > 0
              ) {
                this.getCustomerCount();
                this.getCustomerLimit();
                this.getMembers();
                this.getSchools();
                this.getAllArea();
                this.getAllDepartement();
              } else {
                this.getMembers();
                this.getSchools();
                this.getAllArea();
                this.getAllDepartement();
                this.setState({
                  loading: 0,
                });
              }
            }
          );
        }
      });
  }
  getDepartementByMemberId() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getBreadcrumb",
        id: this.state.memberId,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            allDepartement: data,
          });
        }
      });
  }

  getAllDepartement() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getParentDepartments",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            allDepartement: data,
          });
        }
      });
  }
  getAllSubDepartement() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getDataByCatIdAndLimit",
        catId: 2,
        title: "",
        page: "",
        limit: "",
        sort: "id",
        sortType: "",
        getType: "all",
        parentId: this.state.stationId,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            allSubDepartement: data,
          });
        }
      });
  }

  getSpecificArea() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getSpecificArea",
        areaId: this.state.regionId,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            allSpecificArea: data,
          });
        }
      });
  }

  getAllArea() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getArea",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            allArea: data,
          });
        }
      });
  }

  getSchools() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "schoolFilterByName",
        fieldName: "deleted",
        fieldValue: "0",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            allSchools: data,
          });
        }
      });
  }

  getMembers() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllMembers",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }

          let allMemberList = [];
          data.map((memberData, index) => {
            allMemberList.push({
              id: memberData["id"],
              name:
                memberData["keyword_SEO"] +
                " " +
                memberData["desc_SEO"] +
                " " +
                memberData["countries"] +
                " " +
                memberData["location"],
            });
          });
          this.setState({
            allMembers: data,
            allMemberList: allMemberList,
          });
        }
      });
  }

  getCustomerCount() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getCustomers",
        title: this.state.searchTitle,
        getType: "count",
        schoolId: this.state.schoolId,
        specificThingId: this.state.specificThingId,
        regionId: this.state.regionId,
        memberId:
          this.state.showNormalUserData == true && this.state.allMembersId != ""
            ? this.state.allMembersId[0]["id"]
            : this.state.memberId,
        driverId: "",
        stationId: this.state.stationId,
        voted: this.state.voted,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        canVote: this.state.canVote,
        centerId: this.state.centerId,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            dataCount: data[0]["counter"],
          });
        }
      });
  }

  getCustomerLimit() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getCustomers",
        getType: "limit",
        title: this.state.searchTitle,
        page: this.state.activePage,
        limit: this.state.limit,
        sortType: this.state.sortType,
        sort: this.state.sortColNum,
        schoolId: this.state.schoolId,
        specificThingId: this.state.specificThingId,
        regionId: this.state.regionId,
        memberId:
          this.state.showNormalUserData == true && this.state.allMembersId != ""
            ? this.state.allMembersId[0]["id"]
            : this.state.memberId,
        driverId: "",
        stationId: this.state.stationId,
        voted: this.state.voted,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        canVote: this.state.canVote,
        centerId: this.state.centerId,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            data: data,
            loading: 0,
          });
        }
      });
  }

  handleRefresh = () => {
    this.setState(
      {
        activePage: 1,
        searchTitle: "",
        stationId: "",
        memberId: "",
        regionId: "",
        specificThingId: "",
        schoolId: "",
        voted: 0,
        canVote: -1,
      },
      function() {
        this.getCustomerCount();
        this.getCustomerLimit();
      }
    );
  };

  handleChangeLimit = (event) => {
    this.setState({ limit: event.target.value, activePage: 1 }, function() {
      this.getCustomerLimit();
    });
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };

  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  // returnRowData = () => {
  //     var result = []
  //     if (this.state.loading == 0 && this.state.data.length > 0) {
  //         this.state.data.map((rowData, index) => {

  //             result.push(

  //                 <Col md={12} key={index} id={rowData.userId} className='row-data-view'>
  //                     <Row className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('table-body-row table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
  //                         <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
  //                             <div className="custom-control custom-checkbox">
  //                                 <input type="checkbox" className="custom-control-input" id={"rowCheck-" + rowData['id']} onClick={() => this.checkRow('byId', rowData['id'])} readOnly checked={this.state.checkedRow.indexOf(rowData['id']) > -1 ? true : false} />
  //                                 <label className="custom-control-label" htmlFor={"rowCheck-" + rowData['id']}>&nbsp;</label>
  //                             </div>
  //                             <div className="overflow-text-length ml-3">{rowData['name']} {rowData['secoundName']} {rowData['thirdName']} {rowData['fourthName']}</div>
  //                         </Col>
  //                         <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
  //                             <div className="overflow-text-length ml-3">{rowData['idNumber'] == '' ? '-' : rowData['idNumber']}</div>
  //                         </Col>
  //                         <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
  //                             <div className="overflow-text-length ml-3" style={{ 'direction': 'ltr' }}>{rowData['phoneNumber'] == '' ? '-' : rowData['phoneNumber']}</div>
  //                         </Col>
  //                         <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
  //                             <div className="overflow-text-length ml-3" style={{ 'direction': 'ltr' }}>{rowData['phoneNumber2'] == '' ? '-' : rowData['phoneNumber2']}</div>
  //                         </Col>
  //                         <Col md={2} className='text-right action-btn-view'>
  //                             <i className='flaticon-edit-1' title='تعديل' onClick={(event) => this.openSidebarModal(event, 'edit', rowData)}></i>
  //                             <i className='flaticon-delete' title='حذف' onClick={(event) => this.deleteRow(event, rowData['id'])}></i>
  //                         </Col>
  //                     </Row>
  //                 </Col>
  //             )
  //         })
  //     }
  //     return result
  // }

  openSidebarModal = (event, type, data) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        customerData: data,
        status: type,
        openRightModal: true,
      },
      function() {}
    );
  };

  openImportModal = (event, type, data) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState(
      {
        openImportModal: true,
      },
      function() {}
    );
  };

  sendSms = (event, id) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            {id == "all" ? (
              <p>هل تريد ارسال رسالة نصية لغير المنتخبين ؟</p>
            ) : (
              <p>هل ترغب بارسال رسالة نصية ؟</p>
            )}

            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              إلغاء{" "}
            </Button>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={() => {
                this.handleClickSendSms(event, id);
              }}
            >
              {" "}
              نعم{" "}
            </Button>
          </div>
        );
      },
    });
  };

  handleClickSendSms = (event, id) => {
    event.preventDefault();
    // here call api that send sms

    var customerId = id;
    if (id == "all") {
      customerId = 0;
    }

    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "sendMsgToCustomers",
        customersId: customerId,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui alert-box-view">
                  <p style={{ color: "#024502" }}>
                    {" "}
                    <i
                      className="far fa-check-circle ml-1"
                      style={{ fontSize: "15px" }}
                    ></i>{" "}
                    تم الارسال بنجاح{" "}
                  </p>
                  <Button
                    variant="contained"
                    className="delete-reject-action-btn mr-2"
                    onClick={onClose}
                  >
                    {" "}
                    إغلاق{" "}
                  </Button>
                </div>
              );
            },
          });
        }
      });
  };

  deleteRow(event, id) {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <h5>هل أنت متأكد ؟</h5>
            <p>هل تريد حذف هذا الناخب ؟</p>
            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              إلغاء{" "}
            </Button>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={() => {
                this.handleClickDelete(event, id);
                onClose();
              }}
            >
              {" "}
              نعم{" "}
            </Button>
          </div>
        );
      },
    });
  }
  UnVoteRow(event, id) {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <h5>هل أنت متأكد ؟</h5>
            <p>هل تريد تحويل هذا الناخب الي غير منتخب ؟</p>
            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              إلغاء{" "}
            </Button>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={() => {
                this.handleClickUnVote(event, id);
                onClose();
              }}
            >
              {" "}
              نعم{" "}
            </Button>
          </div>
        );
      },
    });
  }
  handleClickDelete(event, id) {
    event.preventDefault();
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteCustomers",
        customersIds: id,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          // var deletedElement = document.getElementById(id);
          // deletedElement.style.display = 'none'
          var newCount = parseInt(this.state.dataCount) - 1;
          this.setState({ dataCount: newCount });

          if (newCount <= this.state.limit || this.state.activePage == 1) {
            this.setState({ activePage: 1 });
            this.getCustomerLimit();
            if (this.state.activePage == 1) {
              this.setState({ activePage: 2 });
            }
          }
          if (newCount % this.state.limit == 0) {
            var activePage = parseInt(this.state.activePage) - 1;
            this.setState({ activePage: activePage });
            this.getCustomerLimit();
          }
        }
      });
  }
  handleClickUnVote(event, id) {
    event.preventDefault();
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "returnUnVoted",
        selectedId: [id],
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.getCustomerLimit();
        }
      });
  }
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getCustomerLimit();
    });
  };

  handleSearchChange = (event) => {
    this.setState(
      { searchTitle: event.target.value, activePage: 1 },
      function() {
        this.getCustomerCount();
        this.getCustomerLimit();
      }
    );
  };

  openCheckedActionMenu = () => {
    this.setState({
      openCheckedAction: !this.state.openCheckedAction,
      selectAtLeastOneFlag: 0,
    });
  };

  handleCloseSideBar = () => {
    this.setState({ openRightModal: false, customerData: [] });
  };

  handleCloseImportSideBar = () => {
    this.setState({ openImportModal: false });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        if (name == "regionId") {
          this.getSpecificArea();
          this.setState({ specificThingId: "" }, function() {
            this.getCustomerCount();
            this.getCustomerLimit();
          });
        } else if (name == "stationId") {
          this.getAllSubDepartement();
          this.setState(
            { multiValue: [], memberId: "", centerId: "" },
            function() {
              this.getCustomerCount();
              this.getCustomerLimit();
            }
          );
        } else if (name == "centerId") {
          this.getMembersByDepId();
          this.setState({ multiValue: [], memberId: "" }, function() {
            this.getCustomerCount();
            this.getCustomerLimit();
          });
        } else {
          this.getCustomerCount();
          this.getCustomerLimit();
        }
      }
    );
  };

  getMembersByDepId() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllOwnersBySubDeparment",
        id: this.state.stationId,
        getType: "all",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        id: this.state.centerId,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          // this.setState({
          //     data: data['data'],
          // });

          let allMemberList = [];
          allMemberList.push({
            id: "",
            name: "الكل",
          });
          data.map((memberData, index) => {
            allMemberList.push({
              id: memberData["id"],
              name:
                memberData["keyword_SEO"] +
                " " +
                memberData["desc_SEO"] +
                " " +
                memberData["countries"] +
                " " +
                memberData["location"],
            });
          });
          this.setState({
            allMembers: data,
            allMemberList: allMemberList,
          });
        }
      });
  }

  handleFormAction = (status) => {
    this.setState({ openRightModal: false, customerData: [] }, function() {
      if (status == "edit") {
        this.getCustomerLimit();
      } else {
        this.setState(
          { dataCount: parseInt(this.state.dataCount) + 1 },
          function() {
            this.getCustomerLimit();
          }
        );
      }
    });
  };

  handleImportAction = (status) => {
    this.setState({ openImportModal: false, activePage: 1 }, function() {
      this.getCustomerCount();
      this.getCustomerLimit();
    });
  };

  checkRow = (actionType, rowId) => {
    if (actionType == "all") {
      var selectedArray = this.state.checkedRow;
      if (document.getElementById("customCheck-0").checked == true) {
        if (this.state.data.length > 0) {
          this.state.data.map((rowData, index) => {
            if (this.state.checkedRow.indexOf(rowData.id) <= -1) {
              selectedArray.push(rowData.id);
            }
          });
        }
      } else {
        if (this.state.data.length > 0) {
          this.state.data.map((rowData, index) => {
            if (this.state.checkedRow.indexOf(rowData.id) > -1) {
              var index = selectedArray.indexOf(rowData.id);
              selectedArray.splice(index, 1);
            }
          });
        }
      }
      this.setState({ checkedRow: selectedArray });
    } else {
      if (this.state.checkedRow.indexOf(rowId) > -1) {
        var selectedArray = this.state.checkedRow;
        var index = selectedArray.indexOf(rowId);
        selectedArray.splice(index, 1);
      } else {
        var selectedArray = this.state.checkedRow;
        selectedArray.push(rowId);
      }
      this.setState({ checkedRow: selectedArray });
    }
  };

  rowActionMain(event, actionType) {
    event.preventDefault();

    this.setState({
      selectAtLeastOneFlag: 0,
      // actionLoading: 1
    });

    if (this.state.checkedRow.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui alert-box-view">
              <h5>هل أنت متأكد ؟</h5>
              {actionType == "deleteSelected" ? (
                <p>هل تريد حذف الناخبين المحددين ؟</p>
              ) : actionType == "printCard" ? (
                <p>هل تريد طباعة البطاقة للناخبين ؟</p>
              ) : actionType == "unVoteSelected" ? (
                <p className="mb-0">
                  هل تريد تعيين العناصر المحددة كغير ناخبين ؟
                </p>
              ) : (
                <p className="mb-0">هل تريد تعيين العناصر المحددة كمنتخبين ؟</p>
              )}
              {actionType == "setAsVoted" && (
                <p style={{ "font-size": "11px", color: "#943232" }}>
                  سيتم تعديل الناخبين المسموح لهم بالانتخاب فقط
                </p>
              )}
              {actionType == "printCard" && (
                <p style={{ "font-size": "11px", color: "#943232" }}>
                  سيتم طباعة بطاقات الناخبين المسموح لهم بالانتخاب فقط
                </p>
              )}
              <Button
                variant="contained"
                className="delete-reject-action-btn mr-2"
                onClick={onClose}
              >
                {" "}
                إلغاء{" "}
              </Button>
              <Button
                variant="contained"
                className="delete-accept-action-btn"
                onClick={() => {
                  this.rowAction(event, actionType);
                  onClose();
                }}
              >
                {" "}
                نعم{" "}
              </Button>
            </div>
          );
        },
      });
    } else {
      this.setState({
        selectAtLeastOneFlag: 1,
        // actionLoading: 0
      });
    }
  }

  rowAction = (event, actionType) => {
    this.setState({
      selectAtLeastOneFlag: 0,
      actionLoading: 1,
    });

    var apiAction = "deleteCustomers";
    var deleteType = "";
    if (actionType == "setAsVoted") {
      apiAction = "updateCustomersVotedIds";
      deleteType = "2";
    } else if (actionType == "printCard") {
      apiAction = "printCard";
    }

    if (actionType == "printCard") {
      if (this.state.checkedRow.length > 0) {
        // alert('print cards api')
        fetch(window.API_URL_DATA, {
          method: "POST",
          body: JSON.stringify({
            type: "getPostByCatAndCustomersForPrintCard",
            customersId: this.state.checkedRow.join(),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            pdf: 1,
            customer: 1,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (typeof data !== "Nothing found") {
              if (data == "tokenExpired") {
                localStorage.setItem("USER_ID", "");
                window.location.href = "/logout";
              }
              if (data["resNo"] == 0) {
                this.reportAlert(
                  event,
                  "تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي"
                );
              } else {
                this.reportAlert(event, "تعذر استلام طلبك !");
              }
            }
          });
        this.setState({
          actionLoading: 0,
        });
      } else {
        this.setState({
          selectAtLeastOneFlag: 1,
          actionLoading: 0,
        });
      }
    } else if (actionType == "unVoteSelected") {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: "returnUnVoted",
          selectedId: this.state.checkedRow,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            document.getElementById("customCheck-0").checked = false;
            this.setState(
              {
                actionLoading: 0,
                checkedRow: [],
                openCheckedAction: false,
              },
              function() {
                this.getCustomerCount();
                this.getCustomerLimit();
              }
            );
          }
        });
    } else {
      if (this.state.checkedRow.length > 0) {
        fetch(window.API_URL_DATA, {
          method: "POST",
          body: JSON.stringify({
            type: apiAction,
            actionType: actionType,
            customersIds: this.state.checkedRow.join(),
            userId: localStorage.getItem("USER_ID"),
            token: localStorage.getItem("USER_TOKEN"),
            deleteType: deleteType,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Request-Headers":
              "Origin, X-Requested-With, Content-Type, Accept",
          },
        })
          .then(function(response) {
            return response.json();
          })
          .then((data) => {
            if (typeof data !== "Nothing found") {
              if (data == "tokenExpired") {
                localStorage.setItem("USER_ID", "");
                window.location.href = "/logout";
              }
              document.getElementById("customCheck-0").checked = false;
              this.setState(
                {
                  actionLoading: 0,
                  checkedRow: [],
                  openCheckedAction: false,
                },
                function() {
                  if (actionType == "deleteSelected") {
                    this.setState(
                      {
                        activePage: 1,
                      },
                      function() {
                        this.getCustomerCount();
                        this.getCustomerLimit();
                      }
                    );
                  } else if (actionType == "setAsVoted") {
                    this.getCustomerLimit();
                  }
                }
              );
            }
          });
      } else {
        this.setState({
          selectAtLeastOneFlag: 1,
          actionLoading: 0,
        });
      }
    }
  };

  sortCoulmn = (colNum) => {
    this.setState({ sortColNum: colNum }, function() {
      if (
        (this.state.selectedSortType[colNum] == "" ||
          this.state.selectedSortType[colNum] == undefined ||
          this.state.selectedSortType[colNum] == null) &&
        this.state.selectedSortType[colNum] != 0
      ) {
        var sortVal = 0; // desc
        var sortType = "desc";
      } else if (this.state.selectedSortType[colNum] == 1) {
        var sortVal = 0; // desc
        var sortType = "desc";
      } else {
        var sortVal = 1; // asc
        var sortType = "asc";
      }
      const newArray = this.state.selectedSortType;
      newArray[colNum] = sortVal;
      this.setState(
        { selectedSortType: newArray, sortType: sortType },
        function() {
          this.getCustomerLimit();
        }
      );
    });
  };

  setUpOptionsList(data, type, index) {
    if (type == "allMembers") {
      return (
        <option value={data.id} key={index}>
          {data.keyword_SEO} {data.desc_SEO} {data.countries} {data.location}{" "}
        </option>
      );
    } else if (type == "allSchools") {
      return (
        <option value={data.id} key={"school-" + index}>
          {data.name}
        </option>
      );
    } else if (type == "allArea") {
      return (
        <option value={data.id} key={"area-" + index}>
          {data.name}
        </option>
      );
    } else if (type == "allDepartement") {
      return (
        <option value={data.id} key={"dep-" + index}>
          {data.name}
        </option>
      );
    } else if (type == "allSpecificArea") {
      return (
        <option value={data.id} key={"specificArea-" + index}>
          {data.name}
        </option>
      );
    } else if (type == "allSubDepartement") {
      return (
        <option value={data.id} key={"subDepartement-" + index}>
          {data.keyword_SEO} {data.desc_SEO} {data.countries} {data.location}
        </option>
      );
    }
  }
  exportCustomers = (event, type) => {
    event.preventDefault();
    var searchTitle = this.state.searchTitle;
    if (searchTitle == undefined || searchTitle == "" || searchTitle == null) {
      searchTitle = "";
    }

    var sortColNum = this.state.sortColNum;
    if (sortColNum == undefined || sortColNum == "" || sortColNum == null) {
      sortColNum = "";
    }

    var sortType = this.state.sortType;
    if (sortType == undefined || sortType == "" || sortType == null) {
      sortType = "";
    }

    var schoolId = this.state.schoolId;
    if (schoolId == undefined || schoolId == "" || schoolId == null) {
      schoolId = "";
    }

    var specificThingId = this.state.specificThingId;
    if (
      specificThingId == undefined ||
      specificThingId == "" ||
      specificThingId == null
    ) {
      specificThingId = "";
    }

    var regionId = this.state.regionId;
    if (regionId == undefined || regionId == "" || regionId == null) {
      regionId = "";
    }

    var memberId = this.state.memberId;
    if (memberId == undefined || memberId == "" || memberId == null) {
      memberId = "";
    }

    var centerId = this.state.centerId;
    if (centerId == undefined || centerId == "" || centerId == null) {
      centerId = "";
    }
    var stationId = this.state.stationId;
    if (stationId == undefined || stationId == "" || stationId == null) {
      stationId = "";
    }

    var voted = this.state.voted;
    if (voted == undefined || voted == "" || voted == null) {
      voted = "";
    }
    if (type == "pdf") {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: "getCustomers",
          title: searchTitle,
          export: 1,
          sort: sortColNum,
          sortType: sortType,
          schoolId: schoolId,
          specificThingId: specificThingId,
          regionId: regionId,
          memberId: memberId,
          driverId: "",
          stationId: stationId,
          centerId: centerId,
          voted: voted,
          fromLimit: -1,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          pdf: 1,
          canVote: this.state.canVote,
          getType: "",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            if (data["resNo"] == 0) {
              this.reportAlert(
                event,
                "تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي"
              );
            } else {
              this.reportAlert(event, "تعذر استلام طلبك !");
            }
          }
        });
    } else {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: "getCustomers",
          title: searchTitle,
          export: 1,
          sort: sortColNum,
          sortType: sortType,
          schoolId: schoolId,
          specificThingId: specificThingId,
          regionId: regionId,
          memberId: memberId,
          driverId: "",
          stationId: stationId,
          centerId: centerId,
          voted: voted,
          fromLimit: -1,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          canVote: this.state.canVote,
          getType: "",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            if (data["resNo"] == 0) {
              this.reportAlert(
                event,
                "تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي"
              );
            } else {
              this.reportAlert(event, "تعذر استلام طلبك !");
            }
          }
        });
    }
  };
  reportAlert = (event, text) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p>{text}</p>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={onClose}
            >
              {" "}
              اغلاق{" "}
            </Button>
          </div>
        );
      },
    });
  };
  handleMultiChange = (option) => {
    this.setState(
      (state) => {
        return {
          multiValue: option,
          memberId: option["id"],
        };
      },
      function() {
        this.getCustomerCount();
        this.getCustomerLimit();
      }
    );
  };
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef != null &&
      this.wrapperRef &&
      this.wrapperRef.current != null &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ openCheckedAction: false, selectAtLeastOneFlag: 0 });
    }
  }

  render() {
    if (localStorage.getItem("USER_LEVEL") == 5) {
      return (
        <div>
          <p className="report-no-permission main-color-1">
            <i className="far fa-times-circle"></i> لا تمتلك صلاحية لاستعراض هذا
            التقرير
          </p>
        </div>
      );
    }
    var mainCol = [];
    var dataCol = [];
    var actionCol = [];

    if (this.state.loading == 0 && this.state.data.length > 0) {
      this.state.data.map((rowData, index) => {
        var color = "rgb(130, 120, 128)";

        if (rowData["canVote"] == 1) {
          if (rowData["voted"] == 1) {
            color = "rgb(0, 88, 154)";
          }
        } else {
          color = "rgb(191 27 6)";
        }

        mainCol.push(
          <div
            key={index}
            className="row flex-row flex-nowrap table-body-row-border row-even-bg-color"
          >
            <div
              className={
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-2 col-sm-2 col-md-2 col-lg-2 table-body-row main-color-1 font-weight-500 overflow-text-length pt-2 pb-0"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ borderRight: "6px solid " + color }}
            >
              {((this.state.permations["1_1"] != undefined &&
                this.state.permations["1_1"] == true) ||
                (this.state.permations["4_4"] != undefined &&
                  this.state.permations["4_4"] == true)) &&
                this.props.dashboardCounts != 1 && (
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"rowCheck-" + rowData["id"]}
                      onClick={() => this.checkRow("byId", rowData["id"])}
                      readOnly
                      checked={
                        this.state.checkedRow.indexOf(rowData["id"]) > -1
                          ? true
                          : false
                      }
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"rowCheck-" + rowData["id"]}
                    >
                      &nbsp;
                    </label>
                  </div>
                )}
            </div>

            <div
              className={
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-10 col-sm-10 col-md-10 col-lg-10 table-body-row main-color-1 font-weight-500 overflow-text-length"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["customerFullName"]}{" "}
              {rowData["customerFullName"] == "" && "-"}
            </div>
          </div>
        );

        var checkEvenRow = index % 2;
        dataCol.push(
          <div className="row flex-row flex-nowrap" key={index}>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["idNumber"] == "" ? "-" : rowData["idNumber"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ direction: "ltr" }}
            >
              {rowData["phoneNumber"] == "" ? "-" : rowData["phoneNumber"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["centerName"] == "" ? "-" : rowData["centerName"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["memberName"] == "" ? "-" : rowData["memberName"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["stationName"] == "" ? "-" : rowData["stationName"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ direction: "ltr" }}
            >
              {rowData["phoneNumber2"] == "" ? "-" : rowData["phoneNumber2"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["regionName"] == "" ? "-" : rowData["regionName"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["specificThingName"] == ""
                ? "-"
                : rowData["specificThingName"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["schoolName"] == "" ? "-" : rowData["schoolName"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["address"] == "" ? "-" : rowData["address"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["work"] == "" || rowData["work"] == undefined
                ? "-"
                : rowData["work"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["facebook"] == "" ? "-" : rowData["facebook"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["whatsapp"] == "" ? "-" : rowData["whatsapp"]}
            </div>
          </div>
        );
        if (this.props.dashboardCounts != 1) {
          actionCol.push(
            <div className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
              <div
                className={
                  (this.state["activeIndex"] == index
                    ? "hover-scroll-table-row  "
                    : "") +
                  "col-12 col-sm-4 col-md-12 col-lg-12 table-body-row overflow-text-length text-right action-btn-view"
                }
                onMouseOver={this.addRowHover.bind(this, index)}
                onMouseOut={this.removeRowHover.bind(this, index)}
              >
                {((this.state.permations["1_1"] != undefined &&
                  this.state.permations["1_1"] == true) ||
                  (this.state.permations["4_4"] != undefined &&
                    this.state.permations["4_4"] == true)) && (
                  <i
                    className="flaticon-delete"
                    title="حذف"
                    onClick={(event) => this.deleteRow(event, rowData["id"])}
                  ></i>
                )}
                {((this.state.permations["1_1"] != undefined &&
                  this.state.permations["1_1"] == true) ||
                  (this.state.permations["3_3"] != undefined &&
                    this.state.permations["3_3"] == true)) && (
                  <i
                    className="flaticon-edit-1"
                    title="تعديل"
                    onClick={(event) =>
                      this.openSidebarModal(event, "edit", rowData)
                    }
                  ></i>
                )}
                {
                // ((this.state.permations["1_1"] != undefined &&
                //   this.state.permations["1_1"] == true) ||
                //   (this.state.permations["3_3"] != undefined &&
                //     this.state.permations["3_3"] == true))
                    localStorage.getItem('USER_LEVEL') == 3
                    &&
                  rowData.voted == 1 && (
                    <i
                      className="flaticon-edit"
                      title="تحويل الى غير ناخب"
                      onClick={(event) => this.UnVoteRow(event, rowData["id"])}
                    ></i>
                  )}
                {((this.state.permations["1_1"] != undefined &&
                  this.state.permations["1_1"] == true) ||
                  (this.state.permations["7_7"] != undefined &&
                    this.state.permations["7_7"] == true)) &&
                  rowData["canVote"] == 1 &&
                  rowData["voted"] != 1 && (
                    <i
                      className="flaticon-chat-1"
                      title="ارسال رسالة نصية"
                      onClick={(event) => this.sendSms(event, rowData["id"])}
                    ></i>
                  )}
                {this.state.permations["1_1"] != undefined &&
                  this.state.permations["1_1"] != true &&
                  this.state.permations["4_4"] != undefined &&
                  this.state.permations["4_4"] != true &&
                  this.state.permations["3_3"] != undefined &&
                  this.state.permations["3_3"] != true &&
                  this.state.permations["7_7"] != undefined &&
                  this.state.permations["7_7"] != true && (
                    <i class="flaticon-circle"></i>
                  )}
              </div>
            </div>
          );
        }
      });
    }
    return (
      <div>
        {this.props.dashboardFilter != 1 && (
          <Row>
            {((this.state.permations["1_1"] != undefined &&
              this.state.permations["1_1"] == true) ||
              (this.state.permations["2_2"] != undefined &&
                this.state.permations["2_2"] == true)) && (
              <Col md={12}>
                {this.props.dashboardCounts != 1 && (
                  <div className="kt-portlet kt-portlet--height-fluid p-3">
                    <Row>
                      <Col md={4}>
                        <AvForm className="form-inline">
                          <Col md={12}>
                            <Row>
                              <Col md={2}>
                                <Button
                                  variant="contained"
                                  className="refresh-action-btn"
                                  onClick={this.handleRefresh}
                                  title="تحديث"
                                >
                                  <i className="flaticon-refresh reset-filter-btn"></i>
                                </Button>
                              </Col>
                              <Col md={10}>
                                <AvInput
                                  type="text"
                                  name="searchTitle"
                                  placeholder="بحث"
                                  value={this.state.searchTitle}
                                  onChange={this.handleSearchChange}
                                  className="form-input-width"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12}>
                            <Row className="mt-2">
                              <Col md={6} className="mb-1">
                                <AvField
                                  type="select"
                                  name="schoolId"
                                  value={this.state.schoolId}
                                  onChange={this.handleInputChange.bind(this)}
                                  className="form-input-width"
                                >
                                  <option
                                    style={{
                                      display: "none",
                                    }}
                                    value=""
                                  >
                                    المدرسة
                                  </option>
                                  <option value="">الكل</option>
                                  {this.state.allSchools.map((data, input) =>
                                    this.setUpOptionsList(
                                      data,
                                      "allSchools",
                                      input
                                    )
                                  )}
                                </AvField>
                              </Col>
                              <Col md={6} className="mb-1">
                                <AvField
                                  type="select"
                                  name="regionId"
                                  value={this.state.regionId}
                                  onChange={this.handleInputChange.bind(this)}
                                  className="form-input-width"
                                >
                                  <option
                                    style={{
                                      display: "none",
                                    }}
                                    value=""
                                  >
                                    المنطقة
                                  </option>
                                  <option value="">الكل</option>
                                  {this.state.allArea.map((data, input) =>
                                    this.setUpOptionsList(
                                      data,
                                      "allArea",
                                      input
                                    )
                                  )}
                                </AvField>
                              </Col>
                              {this.state.showNormalUserData != true && (
                                <Col md={12} className="mb-1">
                                  <AvField
                                    type="select"
                                    name="stationId"
                                    value={this.state.stationId}
                                    onChange={this.handleInputChange.bind(this)}
                                    className="form-input-width"
                                  >
                                    <option
                                      style={{
                                        display: "none",
                                      }}
                                      value=""
                                    >
                                      المركز
                                    </option>
                                    <option value="">الكل</option>
                                    {this.state.allDepartement.map(
                                      (data, input) =>
                                        this.setUpOptionsList(
                                          data,
                                          "allDepartement",
                                          input
                                        )
                                    )}
                                  </AvField>
                                </Col>
                              )}
                              {this.state.showNormalUserData != true && (
                                <Col md={6} className="mb-1">
                                  <AvField
                                    type="select"
                                    name="centerId"
                                    value={this.state.centerId}
                                    onChange={this.handleInputChange.bind(this)}
                                    className="form-input-width"
                                  >
                                    <option
                                      style={{
                                        display: "none",
                                      }}
                                      value=""
                                    >
                                      اللجنة
                                    </option>
                                    {this.state.stationId == "" ? (
                                      <option value="">اختر المركز اولا</option>
                                    ) : (
                                      <option value="">الكل</option>
                                    )}
                                    {this.state.stationId != "" &&
                                      this.state.allSubDepartement.map(
                                        (data, input) =>
                                          this.setUpOptionsList(
                                            data,
                                            "allSubDepartement",
                                            input
                                          )
                                      )}
                                  </AvField>
                                </Col>
                              )}
                              {this.state.showNormalUserData != true && (
                                <Col md={6} className="mb-1">
                                  <div>
                                    <div className="member-searchable-list">
                                      <Picky
                                        value={this.state.multiValue}
                                        options={
                                          this.state.centerId != ""
                                            ? this.state.allMemberList
                                            : []
                                        }
                                        onChange={this.handleMultiChange}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        multiple={false}
                                        includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={200}
                                        placeholder="صاحب الكشف"
                                        filterPlaceholder={
                                          this.state.centerId == ""
                                            ? "اختر اللجنة اولا"
                                            : "اختر صاحب الكشف"
                                        }
                                      />
                                    </div>
                                  </div>
                                </Col>
                              )}

                              {/* {this.state.showNormalUserData != true &&
                                                                <Col md={6} className='mb-1'>
                                                                    <AvField type="select" name="memberId" value={this.state.memberId} onChange={this.handleInputChange.bind(this)} className='form-input-width'>
                                                                        <option style={{
                                                                            'display': 'none'
                                                                        }} value="">صاحب الكشف</option>
                                                                        <option value=''>الكل</option>
                                                                        {this.state.allMembers.map((data, input) => this.setUpOptionsList(data, 'allMembers', input))}
                                                                    </AvField>
                                                                </Col>
                                                            } */}
                              {/* {this.state.showNormalUserData != true &&
                                                                <Col md={6} className='mb-1'>
                                                                    <AvField type="select" name="centerId" value={this.state.centerId} onChange={this.handleInputChange.bind(this)} className='form-input-width'>
                                                                        <option style={{
                                                                            'display': 'none'
                                                                        }} value="">المركز</option>

                                                                        {(this.state.memberId == 0 || this.state.memberId == null || this.state.memberId == undefined || this.state.memberId == '') &&
                                                                            <option disabled={true}>اختر صاحب الكشف أولا</option>
                                                                        }
                                                                        {(this.state.memberId != 0 && this.state.memberId != null && this.state.memberId != undefined && this.state.memberId != '') &&
                                                                            <option value=''>الكل</option>
                                                                        }
                                                                        {this.state.allDepartement.map((data, input) => this.setUpOptionsList(data, 'allDepartement', input))}
                                                                    </AvField>
                                                                </Col>
                                                            } */}

                              <Col md={6} className="mb-1">
                                <AvField
                                  type="select"
                                  name="specificThingId"
                                  value={this.state.specificThingId}
                                  onChange={this.handleInputChange.bind(this)}
                                  className="form-input-width"
                                >
                                  <option
                                    style={{
                                      display: "none",
                                    }}
                                    value=""
                                  >
                                    معلم بارز
                                  </option>

                                  {(this.state.regionId == 0 ||
                                    this.state.regionId == null ||
                                    this.state.regionId == undefined ||
                                    this.state.regionId == "") && (
                                    <option disabled={true}>
                                      اختر المنطقة أولا
                                    </option>
                                  )}
                                  {this.state.regionId != 0 &&
                                    this.state.regionId != null &&
                                    this.state.regionId != undefined &&
                                    this.state.regionId != "" && (
                                      <option value="">الكل</option>
                                    )}
                                  {this.state.allSpecificArea.map(
                                    (data, input) =>
                                      this.setUpOptionsList(
                                        data,
                                        "allSpecificArea",
                                        input
                                      )
                                  )}
                                </AvField>
                              </Col>
                              <Col md={6} className="mb-1">
                                <AvField
                                  type="select"
                                  name="voted"
                                  value={this.state.voted}
                                  onChange={this.handleInputChange.bind(this)}
                                  className="form-input-width"
                                >
                                  <option
                                    style={{
                                      display: "none",
                                    }}
                                    value=""
                                  >
                                    منتخب / غير منتخب
                                  </option>
                                  <option value="0">الكل</option>
                                  <option value="1">منتخب</option>
                                  <option value="-1">غير منتخب</option>
                                </AvField>
                              </Col>
                              <Col md={12} className="mb-1">
                                <AvField
                                  type="select"
                                  name="canVote"
                                  value={this.state.canVote}
                                  onChange={this.handleInputChange.bind(this)}
                                  className="form-input-width"
                                >
                                  <option
                                    style={{
                                      display: "none",
                                    }}
                                    value=""
                                  >
                                    مسموح / غير مسموح له الانتخاب
                                  </option>
                                  <option value="-1">الكل</option>
                                  <option value="1">مسموح له الانتخاب</option>
                                  <option value="0">
                                    غير مسموح له الانتخاب
                                  </option>
                                </AvField>
                              </Col>
                            </Row>
                          </Col>
                        </AvForm>
                      </Col>
                      <Col md={8} className="float-right text-right">
                        {((this.state.permations["1_1"] != undefined &&
                          this.state.permations["1_1"] == true) ||
                          (this.state.permations["7_7"] != undefined &&
                            this.state.permations["7_7"] == true)) && (
                          <Button
                            variant="contained"
                            className="send-sms-btn"
                            style={{ fontSize: 14 }}
                            onClick={(event) => this.sendSms(event, "all")}
                          >
                            {" "}
                            ارسال رسالة&nbsp;&nbsp;<i class="flaticon-chat"></i>
                          </Button>
                        )}
                        {((this.state.permations["1_1"] != undefined &&
                          this.state.permations["1_1"] == true) ||
                          (this.state.permations["8_8"] != undefined &&
                            this.state.permations["8_8"] == true)) && (
                          <Button
                            variant="contained"
                            className="pdf-btn ml-2 mr-0"
                            style={{ fontSize: 14 }}
                            onClick={(event) =>
                              this.exportCustomers(event, "pdf")
                            }
                          >
                            {" "}
                            Pdf &nbsp;&nbsp;<i class="far fa-file-pdf"></i>
                          </Button>
                        )}
                        {((this.state.permations["1_1"] != undefined &&
                          this.state.permations["1_1"] == true) ||
                          (this.state.permations["5_5"] != undefined &&
                            this.state.permations["5_5"] == true)) && (
                          <Button
                            variant="contained"
                            className="export-btn ml-2"
                            style={{ fontSize: 14 }}
                            onClick={(event) =>
                              this.exportCustomers(event, "excel")
                            }
                          >
                            {" "}
                            تصدير &nbsp;&nbsp;
                            <i class="fas fa-file-download"></i>
                          </Button>
                        )}
                        {/* {((this.state.permations['1_1'] != undefined && this.state.permations['1_1'] == true) || (this.state.permations['6_6'] != undefined && this.state.permations['6_6'] == true)) &&
                                                    <Button variant="contained" className='import-action-btn ml-2' style={{ fontSize: 14 }} onClick={(event) => this.openImportModal(event, 'add', '')}> استيراد &nbsp;&nbsp;<i className='far fa-file-excel pl-1'></i></Button>
                                                } */}
                        {((this.state.permations["1_1"] != undefined &&
                          this.state.permations["1_1"] == true) ||
                          (this.state.permations["3_3"] != undefined &&
                            this.state.permations["3_3"] == true)) &&
                          this.state.showNormalUserData != true && (
                            <Button
                              variant="contained"
                              className="main-action-btn"
                              style={{ fontSize: 14 }}
                              onClick={(event) =>
                                this.openSidebarModal(event, "add", "")
                              }
                            >
                              {" "}
                              إضافة ناخب &nbsp;&nbsp;
                              <i className="flaticon2-plus-1 pl-1"></i>
                            </Button>
                          )}
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            )}
          </Row>
        )}
        {(this.state.permations["1_1"] != undefined &&
          this.state.permations["1_1"] == true) ||
        (this.state.permations["2_2"] != undefined &&
          this.state.permations["2_2"] == true) ? (
          <Row>
            <Col md={12}>
              <div className="kt-portlet kt-portlet--height-fluid p-3">
                {this.state.loading == 1 && (
                  <div className="data-loading-spinner">Loading...</div>
                )}

                {this.state.loading == 0 && this.state.data.length <= 0 && (
                  <Row>
                    <Col md={12} className="text-center no-data-available-view">
                      <img src="/media/electra/no-data-icon-68.png" />
                      <p>لا يوجد بيانات .&nbsp;.&nbsp; !</p>
                    </Col>
                  </Row>
                )}

                {this.state.loading == 0 && this.state.data.length > 0 && (
                  <Row className="m-1">
                    <Col md={2} className="pl-0 mb-2">
                      {this.props.hideColorCode != 1 && (
                        <div className="color-code-view">
                          <Row>
                            <Col md={1}>
                              <i
                                className="fas fa-square"
                                style={{ color: "rgb(0, 88, 154)" }}
                              ></i>
                            </Col>
                            <Col md={10}>منتخب</Col>
                          </Row>
                          <Row>
                            <Col md={1}>
                              <i
                                className="fas fa-square"
                                style={{ color: "rgb(130, 120, 128)" }}
                              ></i>
                            </Col>
                            <Col md={10}>غير منتخب</Col>
                          </Row>
                          <Row>
                            <Col md={1}>
                              <i
                                className="fas fa-square"
                                style={{ color: "rgb(191 27 6)" }}
                              ></i>
                            </Col>
                            <Col md={10}>غير مسموح له الانتخاب</Col>
                          </Row>
                        </div>
                      )}
                    </Col>
                    <Col md={10} className="pl-0 text-right">
                      <div className="d-inline-flex mb-2">
                        <div className="table-display-limit-text-1 mr-2 ml-2">
                          {" "}
                          عرض
                        </div>
                        <AvForm className="form-inline table-display-limit-select">
                          <AvField
                            type="select"
                            name="limit"
                            value={this.state.limit}
                            onChange={this.handleChangeLimit}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </AvField>
                        </AvForm>
                        <div className="table-display-limit-text-2 mr-2 ml-2">
                          {" "}
                          مدخلات{" "}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}

                {this.state.loading == 0 && this.state.data.length > 0 && (
                  <Row className="m-1">
                    <div className="col-md-3 col-sm-3 col-3 col-lg-3">
                      <div
                        className="row flex-row flex-nowrap table-header-row"
                        ref={this.wrapperRef}
                      >
                        <div
                          className=" overflow-text-length d-inline-flex"
                          style={{
                            borderRight: "6px solid rgba(0, 63, 90, 0)",
                          }}
                        >
                          {((this.state.permations["1_1"] != undefined &&
                            this.state.permations["1_1"] == true) ||
                            (this.state.permations["4_4"] != undefined &&
                              this.state.permations["4_4"] == true) ||
                            (this.state.permations["9_9"] != undefined &&
                              this.state.permations["9_9"] == true) ||
                            (this.state.permations["10_10"] != undefined &&
                              this.state.permations["10_10"] == true)) &&
                            this.props.dashboardCounts != 1 && (
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheck-0"
                                  onClick={() => this.checkRow("all", "")}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheck-0"
                                >
                                  &nbsp;
                                </label>
                              </div>
                            )}
                          {((this.state.permations["1_1"] != undefined &&
                            this.state.permations["1_1"] == true) ||
                            (this.state.permations["4_4"] != undefined &&
                              this.state.permations["4_4"] == true) ||
                            (this.state.permations["9_9"] != undefined &&
                              this.state.permations["9_9"] == true) ||
                            (this.state.permations["10_10"] != undefined &&
                              this.state.permations["10_10"] == true)) &&
                            this.props.dashboardCounts != 1 && (
                              <button
                                type="button"
                                className="btn btn-sm  dropdown-toggle waves-effect arrow-none p-0"
                                data-toggle="dropdown"
                                aria-expanded="true"
                                onClick={(event) =>
                                  this.openCheckedActionMenu()
                                }
                              ></button>
                            )}
                          {((this.state.permations["1_1"] != undefined &&
                            this.state.permations["1_1"] == true) ||
                            (this.state.permations["4_4"] != undefined &&
                              this.state.permations["4_4"] == true) ||
                            (this.state.permations["9_9"] != undefined &&
                              this.state.permations["9_9"] == true) ||
                            (this.state.permations["10_10"] != undefined &&
                              this.state.permations["10_10"] == true)) &&
                            this.props.dashboardCounts != 1 && (
                              <div
                                className={
                                  (this.state.openCheckedAction === true
                                    ? "show "
                                    : "") +
                                  "dropdown-menu customer-dropdown-menu"
                                }
                                x-placement="bottom-start"
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "auto",
                                }}
                              >
                                <label className="pl-1 pr-1 pt-1">
                                  الإجراء{" "}
                                  {this.state.actionLoading == 1 && (
                                    <i className="kt-spinner kt-spinner--md kt-spinner--light ml-2 action-loading-style"></i>
                                  )}
                                </label>
                                {this.state.permations["9_9"] != undefined &&
                                  this.state.permations["9_9"] == true && (
                                    <a
                                      className="dropdown-item dropdown-item-active-style"
                                      href="#/"
                                      onClick={(event) => {
                                        this.rowActionMain(event, "setAsVoted");
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="far fa-check-circle"
                                        style={{ color: "#28c10c" }}
                                      ></i>{" "}
                                      تم التصويت
                                    </a>
                                  )}
                                {this.state.permations["10_10"] != undefined &&
                                  this.state.permations["10_10"] == true && (
                                    <a
                                      className="dropdown-item dropdown-item-active-style"
                                      href="#/"
                                      onClick={(event) => {
                                        this.rowActionMain(event, "printCard");
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="far fa-address-card"
                                        style={{ color: "rgb(0, 88, 154)" }}
                                      ></i>{" "}
                                      طباعة البطاقة
                                    </a>
                                  )}
                                {this.state.permations["4_4"] != undefined &&
                                  this.state.permations["4_4"] == true && (
                                    <a
                                      className="dropdown-item dropdown-item-active-style"
                                      href="#/"
                                      onClick={(event) => {
                                        this.rowActionMain(
                                          event,
                                          "deleteSelected"
                                        );
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="far fa-times-circle"
                                        style={{ color: "red" }}
                                      ></i>{" "}
                                      حذف{" "}
                                    </a>
                                  )}
                                {
                                // ((this.state.permations["1_1"] != undefined &&
                                //   this.state.permations["1_1"] == true) ||
                                //   (this.state.permations["3_3"] != undefined &&
                                //     this.state.permations["3_3"] == true)) 
                                localStorage.getItem('USER_LEVEL') == 3
                                    &&
                                     (
                                  <a
                                    className="dropdown-item dropdown-item-active-style"
                                    href="#/"
                                    onClick={(event) => {
                                      this.rowActionMain(
                                        event,
                                        "unVoteSelected"
                                      );
                                    }}
                                  >
                                    {" "}
                                    <i
                                      className="far flaticon-edit"
                                      style={{ color: "red" }}
                                    ></i>{" "}
                                    تحويل الى غير منتخب{" "}
                                  </a>
                                )}
                                {this.state.selectAtLeastOneFlag == 1 && (
                                  <div className="dropdown-item error-selected-row-null">
                                    اختر على الأقل ناخب واحد
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                        <div className=" overflow-text-length">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("name")}
                          >
                            اسم الناخب{" "}
                            {this.state.sortColNum == "name" ? (
                              this.state.selectedSortType["name"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>

                      {mainCol}
                    </div>
                    <div
                      className={
                        this.props.dashboardCounts != 1
                          ? "col-md-7 col-sm-7 col-7 col-lg-7 pr-0"
                          : "col-md-9 col-sm-9 col-9 col-lg-9 pr-0"
                      }
                      style={{
                        overflowX: "auto",
                        overflowY: "hidden",
                        width: "100%",
                      }}
                    >
                      <div className="row flex-row flex-nowrap">
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          <div className="overflow-text-length ml-0">
                            <div
                              className="pointer-cursor"
                              onClick={() => this.sortCoulmn("idNumber")}
                            >
                              الرقم الوطني{" "}
                              {this.state.sortColNum == "idNumber" ? (
                                this.state.selectedSortType["idNumber"] == 0 ? (
                                  <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                                ) : (
                                  <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                                )
                              ) : (
                                <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          <div className="overflow-text-length ml-0">
                            <div
                              className="pointer-cursor"
                              onClick={() => this.sortCoulmn("phoneNumber")}
                            >
                              رقم الهاتف (1){" "}
                              {this.state.sortColNum == "phoneNumber" ? (
                                this.state.selectedSortType["phoneNumber"] ==
                                0 ? (
                                  <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                                ) : (
                                  <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                                )
                              ) : (
                                <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          المركز المباشر لصاحب الكشف
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          صاحب الكشف
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          المركز الرئيسي
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          <div className="overflow-text-length ml-0">
                            <div
                              className="pointer-cursor"
                              onClick={() => this.sortCoulmn("phoneNumber2")}
                            >
                              رقم الهاتف (2){" "}
                              {this.state.sortColNum == "phoneNumber2" ? (
                                this.state.selectedSortType["phoneNumber2"] ==
                                0 ? (
                                  <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                                ) : (
                                  <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                                )
                              ) : (
                                <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          المنطقة
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          المعلم البارز
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          اسم المدرسة
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          العنوان
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          العمل
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          Facebook
                        </div>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                          Whatsapp
                        </div>
                      </div>
                      {dataCol}
                    </div>
                    {((this.state.permations["1_1"] != undefined &&
                      this.state.permations["1_1"] == true) ||
                      (this.state.permations["4_4"] != undefined &&
                        this.state.permations["4_4"] == true) ||
                      (this.state.permations["3_3"] != undefined &&
                        this.state.permations["3_3"] == true) ||
                      (this.state.permations["7_7"] != undefined &&
                        this.state.permations["7_7"] == true)) &&
                      this.props.dashboardCounts != 1 && (
                        <div className="col-md-2 col-sm-2 col-2 col-lg-2">
                          <div className="row flex-row flex-nowrap">
                            <div className="col-12 col-sm-4 col-md-12 col-lg-12 table-header-row overflow-text-length text-right pl-4">
                              الإجراء
                            </div>
                          </div>
                          {actionCol}
                        </div>
                      )}
                  </Row>
                )}

                {/* {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={12}>
                                        <Row className='table-header-row'>
                                            <Col md={3} className='d-inline-flex'>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="customCheck-0" onClick={() => this.checkRow('all', '')} />
                                                    <label className="custom-control-label" htmlFor="customCheck-0">&nbsp;</label>
                                                </div>
                                                <button type="button" className="btn btn-sm  dropdown-toggle waves-effect arrow-none p-0" data-toggle="dropdown" aria-expanded="true" onClick={(event) => this.openCheckedActionMenu()}>
                                                </button>
                                                <div className={(this.state.openCheckedAction === true ? 'show ' : '') + "dropdown-menu"} x-placement="bottom-start" style={{ maxHeight: "300px", overflowY: "auto" }}>
                                                    <label className='pl-1 pr-1 pt-1'>الإجراء {this.state.actionLoading == 1 && <i className='kt-spinner kt-spinner--md kt-spinner--light ml-2 action-loading-style'></i>}</label>
                                                    <a className="dropdown-item dropdown-item-active-style" href="#/" onClick={() => { this.rowAction('deleteSelected'); }}>حذف</a>
                                                    {this.state.selectAtLeastOneFlag == 1 &&
                                                        <div className="dropdown-item error-selected-row-null">اختر على الأقل ناخب واحد</div>
                                                    }
                                                </div>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('name')}>اسم الناخب {this.state.sortColNum == 1 ? (this.state.selectedSortType[1] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('idNumber')}>الرقم الوطني {this.state.sortColNum == 1 ? (this.state.selectedSortType[1] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={3} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('phoneNumber')}>رقم الهاتف (1) {this.state.sortColNum == 3 ? (this.state.selectedSortType[3] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('phoneNumber2')}>رقم الهاتف (2) {this.state.sortColNum == 4 ? (this.state.selectedSortType[4] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='text-right'>
                                                الإجراء
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} className='table-body-scroll table-body-row-view'>
                                        {this.returnRowData()}
                                    </Col>
                                </Row>
                            } */}

                {this.state.loading == 0 && this.state.data.length > 0 && (
                  <Row className="m-1">
                    <Col
                      md={6}
                      className="pagination-style float-left text-left"
                    >
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.dataCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        prevPageText={<i className="flaticon2-right-arrow"></i>}
                        nextPageText={<i className="flaticon2-left-arrow"></i>}
                        hideFirstLastPages
                        innerClass={
                          "pagination  pagination-rounded pagenation-view my-2"
                        }
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                      />
                    </Col>
                    <Col md={6}>
                      <div className="mt-2 float-right text-right">
                        عرض{" "}
                        {this.state.activePage != 1
                          ? this.state.activePage +
                            (this.state.limit * (this.state.activePage - 1) -
                              this.state.activePage +
                              1)
                          : this.state.activePage}{" "}
                        الى{" "}
                        {this.state.activePage * this.state.limit >
                        this.state.dataCount
                          ? this.state.dataCount
                          : this.state.activePage * this.state.limit}{" "}
                        من {this.state.dataCount} مدخلات
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          <div>
            <p className="report-no-permission main-color-1">
              <i className="far fa-times-circle"></i> لا تمتلك صلاحية لاستعراض
              هذه الصفحة
            </p>
          </div>
        )}
        <Drawer
          anchor={"left"}
          open={this.state.openRightModal}
          className="sidebar-modal-view"
        >
          <CustomersForm
            ref="customersForm"
            status={this.state.status}
            customerData={this.state.customerData}
            handleCloseSideBar={this.handleCloseSideBar}
            handleFormAction={this.handleFormAction}
            allArea={this.state.allArea}
            allDepartement={this.state.allDepartement}
          />
        </Drawer>

        <Drawer
          anchor={"left"}
          open={this.state.openImportModal}
          className="sidebar-modal-view"
        >
          <ImportCustomer
            ref="importCustomer"
            handleCloseImportSideBar={this.handleCloseImportSideBar}
            handleImportAction={this.handleImportAction}
          />
        </Drawer>
      </div>
    );
  }
}
export default Customers;
