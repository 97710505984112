import React, { Component } from "react";
import Report1 from "./report1";
import Report2 from "./report2";
import Report3 from "./report3";
import Report4 from "./report4";
import Report5 from "./report5";
import Report6 from "./report6";
import Report7 from "./report7";
import Report8 from "./report8";
import Reports9 from "./Reports9";
import Report10 from "./Report10";

class MainReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // reportPermission: { '1_2': 'true', '4_3': 'true', '6_4': 'true', '2_1': 'false' },
      reportPermission: {},
      allPermission: 0,
      viewPermission: 0,
      excelPermission: 0,
      pdfPermission: 0,
      openReport: 0,
    };
  }

  componentWillMount() {
    if (localStorage.getItem("USER_LEVEL") == 3) {
      this.setState({
        allPermission: 1,
        viewPermission: 1,
        excelPermission: 1,
        pdfPermission: 1,
        openReport: 1,
      });
    } else if (localStorage.getItem("USER_LEVEL") == 5) {
      this.setState({
        allPermission: 1,
        viewPermission: 1,
        excelPermission: 1,
        pdfPermission: 1,
        openReport: 2,
      });
    } else {
      if (Object.keys(localStorage.getItem("permations")).length > 0) {
        var permations = JSON.parse(localStorage.getItem("permations"));
        if (
          permations != undefined &&
          permations != null &&
          permations != "" &&
          permations["reportData"] != null &&
          permations["reportData"] != undefined &&
          permations["reportData"] != ""
        ) {
          permations = permations["reportData"];
          this.setState({ reportPermission: permations }, function() {
            this.checkPermission();
          });
        }
      }
    }
  }

  checkPermission = () => {
    // check user level first
    
    if (localStorage.getItem("USER_LEVEL") == 3) {
      // super admin
      this.setState({
        allPermission: 1,
        viewPermission: 1,
        excelPermission: 1,
        pdfPermission: 1,
        openReport: 1,
      });
    } else {
      if (Object.keys(this.state.reportPermission).length > 0) {
        for (var key in this.state.reportPermission) {
          // explode key to check report num
          var keyValue = key.split("_");
          if (
            keyValue[0] == this.props.match.params.reportId &&
            (this.state.reportPermission[key] == "true" ||
              this.state.reportPermission[key] == true)
          ) {
            if (keyValue[1] == 1) {
              this.setState({ allPermission: 1, openReport: 1 });
            } else if (keyValue[1] == 2) {
              this.setState({ viewPermission: 1, openReport: 1 });
            } else if (keyValue[1] == 3) {
              this.setState({ excelPermission: 1, openReport: 1 });
            } else if (keyValue[1] == 4) {
              this.setState({ pdfPermission: 1, openReport: 1 });
            }
          }
        }
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        allPermission: 0,
        viewPermission: 0,
        excelPermission: 0,
        pdfPermission: 0,
        openReport: 0,
      },
      function() {
        this.checkPermission();
      }
    );
  }

  render() {
    // console.log(this.state);

    return (
      <div>
        {/*
                ترتيب ارقام التقارير نفس ترتيبهم بالقائمة بالرئيسية
                */}
        {this.state.openReport == 1 ? (
          <div>
            {console.log(
              "this.props.match.params.reportId",
              this.props.match.params.reportId
            )}
            {this.props.match.params.reportId == 1 && (
              <Report1
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"معلومات الناخبين حسب اللجنة التابع لها"*/}
            {this.props.match.params.reportId == 2 && (
              <Report2
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"عدد الناخبين حسب المدرسة"*/}
            {this.props.match.params.reportId == 3 && (
              <Report3
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"عدد الناخبين حسب اللجان الفرعية"*/}
            {this.props.match.params.reportId == 4 && (
              <Report4
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"عناوين المدارس (المراكز الانتخابية)"*/}
            {this.props.match.params.reportId == 5 && (
              <Report5
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"عدد الناخبين حسب اللجان الاساسية"*/}
            {this.props.match.params.reportId == 6 && (
              <Report6
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"كشف لجنة فرعية (صاحب كشف ) تفصيلي"*/}
            {this.props.match.params.reportId == 7 && (
              <Report7
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"كشف لجنة فرعية (صاحب كشف ) تفصيلي"*/}
            {this.props.match.params.reportId == 8 && (
              <Report8
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"معلومات تفصيلية لاصحاب الكشوفات"*/}
            {this.props.match.params.reportId == 9 && (
              <Reports9
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
               {this.props.match.params.reportId == 10 && (
              <Report10
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"معلومات تفصيلية لاصحاب الكشوفات"*/}
          </div>
        ) : this.state.openReport == 2 && this.props.match.params.reportId == 9 ? (
          <div>
            {this.props.match.params.reportId == 9 && (
              <Reports9
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
             {this.props.match.params.reportId == 10 && (
              <Report10
                allPermission={this.state.allPermission}
                viewPermission={this.state.viewPermission}
                excelPermission={this.state.excelPermission}
                pdfPermission={this.state.pdfPermission}
              />
            )}{" "}
            {/*"معلومات تفصيلية لاصحاب الكشوفات"*/}
          </div>
        ) : (
          <div>
            <p className="report-no-permission main-color-1">
              <i className="far fa-times-circle"></i> لا تمتلك صلاحية لاستعراض
              هذا التقرير
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default MainReports;
