import React, { Component, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ColorCode from "../general/colorCode";
import SubDepartmentForm from "./subDepartmentForm";
import { Redirect, Link } from "react-router-dom";
import ImportCustomer from "./importCustomer";
import ShowCustomer from "./showCustomer";
import CustomersForm from "../customers/customersForm";
import AddCardsForm from "./AddCards";

class SubDepartment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      limit: 10,
      dataCount: 0,
      openMenu: -1,
      openCheckedAction: false,
      checkedRow: [],
      sortColName: "id",
      selectedSortType: [],
      data: [],
      loading: 1,
      openAddCardsModal: false,
      subDepartmentData: [],
      selectAtLeastOneFlag: 0,
      actionLoading: 0,
      openleftModal: false,
      sortType: "",
      allAdminData: [],
      parent: this.props.match.params.parent,
      childCount: 0,
      lastParentId: 0,
      changeParentId: "",
      AllParents: [],
      Breadcrumb: "",
      searchTitle: "",
      openImportModal: false,
      openCustomerModal: false,
      openAddCustomerModal: false,
      memberId: "",
      childNumber: "",
      permations: {},
      allArea: [],
      centerId: "",
    };
  }
  componentWillMount() {
    this.getPermation();
  }
  getPermation() {
    var permations = {};
    let parentId = this.state.parent;

    let allPermation = [parentId] + "_1";
    let showPermation = [parentId] + "_2";
    let addPermation = [parentId] + "_3";
    let deletePermation = [parentId] + "_4";
    let smsPermation = [parentId] + "_5";
    let movePermation = [parentId] + "_6";
    let printCards = [parentId] + "_7";

    if (localStorage.getItem("USER_LEVEL") == 1) {
      permations =
        JSON.parse(localStorage.getItem("permations")) != undefined &&
        JSON.parse(localStorage.getItem("permations")) != null &&
        JSON.parse(localStorage.getItem("permations"))["departementData"] !=
          undefined &&
        JSON.parse(localStorage.getItem("permations"))["departementData"] !=
          null
          ? JSON.parse(localStorage.getItem("permations"))["departementData"]
          : {};
      let parent = 0;
      for (const key in permations) {
        parent = key.split("_")[0];
        let perm = key.split("_")[1];
        if (parent == this.state.parent) {
          let arr = JSON.parse(localStorage.getItem("parentPermations"));

          arr["_" + perm] = permations[key];
          localStorage.setItem("parentPermations", JSON.stringify(arr));
        }
      }
    } else if (localStorage.getItem("USER_LEVEL") == 3) {
      permations = {
        [allPermation]: true,
        [showPermation]: true,
        [addPermation]: true,
        [deletePermation]: true,
        [smsPermation]: true,
        [movePermation]: true,
        [printCards]: true,
      };
    } else {
      permations = {
        [allPermation]: 0,
        [showPermation]: 0,
        [addPermation]: 0,
        [deletePermation]: 0,
        [smsPermation]: 0,
        [movePermation]: 0,
        [printCards]: 0,
      };
    }
    this.setState(
      {
        permations: permations,
      },
      function() {
        this.getAllAdminData();
      }
    );
  }

  getAllAdminData() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getUsers",
        getType: "all",
        adminType: -1,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState(
            {
              allAdminData: data,
            },
            function() {
              this.getAllArea();
              this.getBreadcrumb();
              this.getAllParents();
              this.getDepartmentCount();
              this.getDepartmentLimit();
            }
          );
        }
      });
  }
  getDepartmentLimit() {
    this.setState({ loading: 1 });
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getDataByCatIdAndLimit",
        catId: 2,
        title: this.state.searchTitle,
        page: this.state.activePage,
        limit: this.state.limit,
        sort: this.state.sortColName,
        sortType: this.state.sortType,
        getType: "limit",
        parentId: this.state.parent,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            data: data,
            loading: 0,
          });
        }
      });
  }
  getBreadcrumb() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getBreadcrumb",
        id: this.state.parent,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            Breadcrumb: data,
          });
        }
      });
  }
  getAllParents() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllDepartment",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            AllParents: data,
          });
        }
      });
  }
  handleChangeParent(event, memberData, id) {
    event.preventDefault();
    if (this.state.changeParentId != "") {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: "editDataInPost",
          parent: this.state.changeParentId,
          id: id,
          seoKeyword: memberData.keyword_SEO,
          catId: 2,
          smallDesc: memberData.small_desc,
          lang: 1,
          adminId: memberData.adminType,
          featured: memberData.featured,
          seoDesc: memberData.desc_SEO,
          countries: memberData.countries,
          googleMap: memberData.location,
          desc: memberData.description,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            this.getDepartmentCount();
            this.getDepartmentLimit();
          }
        });
      return "done";
    } else {
      return "wrong";
    }
  }
  getDepartmentCount() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getDataByCatIdAndLimit",
        catId: 2,
        title: this.state.searchTitle,
        page: this.state.page,
        limit: this.state.limit,
        sort: this.state.sortColName,
        sortType: this.state.sortType,
        getType: "count",
        parentId: this.state.parent,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            dataCount: data[0]["counter"],
          });
        }
      });
  }
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };

  handleRefresh = () => {
    this.setState({ activePage: 1, searchTitle: "" }, function() {
      this.getDepartmentCount();
      this.getDepartmentLimit();
    });
  };
  handleSearchChange = (event) => {
    this.setState(
      { searchTitle: event.target.value, activePage: 1 },
      function() {
        this.getDepartmentCount();
        this.getDepartmentLimit();
      }
    );
  };
  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };

  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getDepartmentLimit();
    });
  };

  openActionMenu = (event, id) => {
    event.preventDefault();
    if (this.state.openMenu == id) {
      this.setState({ openMenu: -1 });
    } else {
      this.setState({ openMenu: id });
    }
  };

  handleChangeLimit = (event) => {
    this.setState({ limit: event.target.value, activePage: 1 }, function() {
      this.getDepartmentLimit();
    });
  };
  sortCoulmn = (colNum) => {
    this.setState({ sortColName: colNum }, function() {
      if (
        (this.state.selectedSortType[colNum] == "" ||
          this.state.selectedSortType[colNum] == undefined ||
          this.state.selectedSortType[colNum] == null) &&
        this.state.selectedSortType[colNum] != 0
      ) {
        var sortVal = 0; // desc
      } else if (this.state.selectedSortType[colNum] == 1) {
        var sortVal = 0; // desc
      } else {
        var sortVal = 1; // asc
      }
      const newArray = this.state.selectedSortType;
      newArray[colNum] = sortVal;
      let sort = sortVal == 0 ? "desc" : "asc";
      this.setState({ selectedSortType: newArray, sortType: sort }, function() {
        this.getDepartmentLimit();
      });
    });
  };
  deleteRow(event, id, featured, total, canttotal) {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        if (featured == 0 && this.state.childNumber > 0) {
          return (
            <div className="custom-ui alert-box-view">
              <h5 style={{ color: "#fd397a" }} className="mb-4">
                * ملاحظة : هذا القسم غير فارغ ,لا يمكنك حذفه حالياُ{" "}
              </h5>
              <Button
                variant="contained"
                className="delete-reject-action-btn mr-2"
                onClick={onClose}
              >
                {" "}
                الغاء{" "}
              </Button>
            </div>
          );
          // } else if (featured == 1 && (total > 0 || canttotal > 0)) {
          //     return (
          //         <div className='custom-ui alert-box-view'>
          //             <h5 style={{ color: '#fd397a' }} className='mb-4'>* ملاحظة : لا يمكنك حذف صاحب الكشف عند وجود ناخبين تابعين له</h5>
          //             <Button variant="contained" className='delete-reject-action-btn mr-2' onClick={onClose}> الغاء </Button>
          //         </div>
          //     );
        } else {
          return (
            <div className="custom-ui alert-box-view">
              <h5>هل انت متأكد ؟</h5>
              {featured == 0 ? (
                <p>سيتم حذف جميع معلومات هذا المركز / اللجنة ؟</p>
              ) : (
                <p>سيتم حذف جميع معلومات صاحب الكشف ؟</p>
              )}
              <Button
                variant="contained"
                className="delete-reject-action-btn mr-2"
                onClick={onClose}
              >
                {" "}
                الغاء{" "}
              </Button>
              <Button
                variant="contained"
                className="delete-accept-action-btn"
                onClick={() => {
                  this.handleClickDelete(event, id, featured);
                  onClose();
                }}
              >
                {" "}
                حذف{" "}
              </Button>
            </div>
          );
        }
      },
    });
  }
  move(event, memberData, id) {
    let ParentsHTML = "";
    if (this.state.AllParents.length > 0) {
      ParentsHTML = this.state.AllParents.map(
        (AllParentsData, AllParentsIndex) => {
          return (
            <option key={AllParentsIndex} value={AllParentsData["id"]}>
              {AllParentsData["keyword_SEO"]} {AllParentsData["desc_SEO"]}{" "}
              {AllParentsData["countries"]} {AllParentsData["location"]}
            </option>
          );
        }
      );
    }
    if (ParentsHTML == "") {
      ParentsHTML = "لا يوجد بيانات لعرضها";
    }
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <h5> اللجنة الجديدة</h5>
            <AvForm>
              <AvField
                type="select"
                name="changeParentId"
                value={this.state.changeParentId}
                required
                onChange={this.handleInputChange}
                className="p-1 pl-3"
                errorMessage="الرجاء ادخال قيمة صحيحة"
              >
                <option
                  style={{
                    display: "none",
                  }}
                  value=""
                  selected="selected"
                >
                  اختر اللجنة الجديدة
                </option>
                {ParentsHTML}
              </AvField>
            </AvForm>
            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              الغاء{" "}
            </Button>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={() => {
                let flag = this.handleChangeParent(event, memberData, id);
                if (flag != "wrong") {
                  onClose();
                }
              }}
            >
              {" "}
              نقل{" "}
            </Button>
          </div>
        );
      },
    });
  }
  handleClickDelete(event, id, featured) {
    event.preventDefault();
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "deleteDataInPost",
        id: id,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        featured: featured,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          // var deletedElement = document.getElementById(id);
          // deletedElement.style.display = 'none'
          // var newCount = parseInt(this.state.dataCount) - 1
          // this.setState({ dataCount: newCount });

          // if (newCount <= this.state.limit || this.state.activePage == 1) {
          //     this.setState({ activePage: 1 });
          //     this.getDepartmentLimit();
          // }
          // if (newCount % this.state.limit == 0) {
          //     var activePage = parseInt(this.state.activePage) - 1
          //     this.setState({ activePage: activePage });
          this.getDepartmentLimit();
          this.getDepartmentCount();
          // }
        }
      });
  }
  getChildDepartmentCountOnDelete(event, id, featured, total, canttotal) {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getDataByCatIdAndLimit",
        catId: 2,
        title: this.state.searchTitle,
        page: this.state.page,
        limit: this.state.limit,
        sort: this.state.sortColName,
        sortType: this.state.sortType,
        getType: "count",
        parentId: id,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState(
            {
              childNumber: data[0]["counter"],
            },
            function() {
              this.deleteRow(event, id, featured, total, canttotal);
            }
          );
        }
      });
  }
  showCustomers(event, dataForModal, parent) {
    this.setState(
      {
        memberId: parent,
      },
      function() {
        this.openCustomerModal(event, dataForModal);
      }
    );
  }
  addCards(event, dataForModal, parent) {
    this.setState(
      {
        memberId: parent,
      },
      function() {
        this.openAddCardsModal(event, dataForModal);
      }
    );
  }
  getChildDepartmentCount(event, type, dataForModal, parent) {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getDataByCatIdAndLimit",
        catId: 2,
        title: this.state.searchTitle,
        page: this.state.page,
        limit: this.state.limit,
        sort: this.state.sortColName,
        sortType: this.state.sortType,
        getType: "count",
        parentId: parent,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState(
            {
              childCount: data[0]["counter"],
            },
            function() {
              this.openSidebarModal(event, type, dataForModal);
            }
          );
        }
      });
  }
  changeParent = (parent) => {
    this.setState(
      {
        lastParentId: this.state.parent,
        parent: parent,
        activePage: 1,
        limit: 10,
        dataCount: 0,
        openMenu: -1,
        openCheckedAction: false,
        checkedRow: [],
        sortColName: "id",
        selectedSortType: [],
        data: [],
        loading: 1,
        departmentData: [],
        selectAtLeastOneFlag: 0,
        actionLoading: 0,
        openleftModal: false,
        sortType: "",
        allAdminData: [],
        Breadcrumb: "",
        searchTitle: "",
      },
      function() {
        this.getPermation();
        this.getAllAdminData();
      }
    );
  };
  checkRow = (actionType, rowId) => {
    if (actionType == "all") {
      var selectedArray = this.state.checkedRow;
      if (document.getElementById("customCheck-0").checked == true) {
        if (this.state.data.length > 0) {
          this.state.data.map((rowData, index) => {
            if (this.state.checkedRow.indexOf(rowData.id) <= -1) {
              selectedArray.push(rowData.id);
            }
          });
        }
      } else {
        if (this.state.data.length > 0) {
          this.state.data.map((rowData, index) => {
            if (this.state.checkedRow.indexOf(rowData.id) > -1) {
              var index = selectedArray.indexOf(rowData.id);
              selectedArray.splice(index, 1);
            }
          });
        }
      }
      this.setState({ checkedRow: selectedArray });
    } else {
      if (this.state.checkedRow.indexOf(rowId) > -1) {
        var selectedArray = this.state.checkedRow;
        var index = selectedArray.indexOf(rowId);
        selectedArray.splice(index, 1);
      } else {
        var selectedArray = this.state.checkedRow;
        selectedArray.push(rowId);
      }
      this.setState({ checkedRow: selectedArray });
    }
  };
  openCheckedActionMenu = () => {
    this.setState({
      openCheckedAction: !this.state.openCheckedAction,
      selectAtLeastOneFlag: 0,
    });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openSidebarModal = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState(
      {
        subDepartmentData: data,
        status: type,
        openleftModal: true,
      },
      function() {}
    );
  };
  rowActionChildCount(actionType) {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getDataByCatIdAndLimit",
        catId: 2,
        title: this.state.searchTitle,
        page: this.state.page,
        limit: this.state.limit,
        sort: this.state.sortColName,
        sortType: this.state.sortType,
        getType: "count",
        parentId: this.state.checkedRow,
        allParents: "all",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          if (data != null && data[0] != undefined && data[0]["counter"] > 0) {
            this.setState({
              selectAtLeastOneFlag: 2,
            });
          } else {
            this.rowAction(actionType);
          }
        }
      });
  }
  rowAction = (actionType) => {
    this.setState({
      selectAtLeastOneFlag: 0,
      actionLoading: 1,
    });
    if (this.state.checkedRow.length > 0) {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: "deleteDataInPost",
          id: this.state.checkedRow.join(),
          deleteType: 2,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            document.getElementById("customCheck-0").checked = false;
            this.setState(
              {
                actionLoading: 0,
                checkedRow: [],
                openCheckedAction: false,
              },
              function() {
                if (actionType == "deleteSelected") {
                  this.setState(
                    {
                      activePage: 1,
                    },
                    function() {
                      this.getDepartmentCount();
                      this.getDepartmentLimit();
                    }
                  );
                }
              }
            );
          }
        });
    } else {
      this.setState({
        selectAtLeastOneFlag: 1,
        actionLoading: 0,
      });
    }
  };
  getAdminNameById = (id) => {
    for (const key in this.state.allAdminData) {
      if (this.state.allAdminData[key]["userId"] == id) {
        return (
          this.state.allAdminData[key]["fname"] +
          " " +
          this.state.allAdminData[key]["lname"]
        );
      }
    }
  };
  handleCloseSideBar = () => {
    this.setState({ openleftModal: false });
  };
  handleFormAction = (status) => {
    this.setState({ openleftModal: false, subDepartmentData: [] }, function() {
      if (status == "edit") {
        this.getDepartmentLimit();
      } else {
        this.setState(
          { dataCount: parseInt(this.state.dataCount) + 1 },
          function() {
            this.getDepartmentLimit();
          }
        );
      }
    });
  };
  openImportModal = (event, type, id) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState(
      {
        openImportModal: true,
        memberId: id,
      },
      function() {}
    );
  };
  openCustomerModal = (event, dataForModal) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      subDepartmentData: dataForModal,
      openCustomerModal: true,
    });
  };
  openAddCardsModal = (event, dataForModal) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      subDepartmentData: dataForModal,
      openAddCardsModal: true,
    });
  };
  openAddCustomerModal = (event, id, departmentId) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({
      openAddCustomerModal: true,
      memberId: id,
      centerId: departmentId,
    });
  };

  handleCloseImportSideBar = () => {
    this.setState({ openImportModal: false }, function() {
      this.getDepartmentCount();
      this.getDepartmentLimit();
    });
  };
  handleCloseAddCards = () => {
    this.setState({ openAddCardsModal: false }, function() {
      this.getDepartmentCount();
      this.getDepartmentLimit();
    });
  };
  handleCloseCustomerSideBar = () => {
    this.setState({ openCustomerModal: false });
  };
  handleCloseAddCustomerSideBar = () => {
    this.setState({ openAddCustomerModal: false });
  };
  handleImportAction = (status) => {
    this.setState({ openImportModal: false, activePage: 1 }, function() {
      this.getDepartmentCount();
      this.getDepartmentLimit();
    });
  };
  handleCustomerAction = (status) => {
    this.setState({ openCustomerModal: false, activePage: 1 }, function() {
      this.getDepartmentCount();
      this.getDepartmentLimit();
    });
  };
  sendSms = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p>هل تريد ارسال رسالة نصية لغير المنتخبين ؟</p>
            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              إلغاء{" "}
            </Button>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={() => {
                this.handleClickSendSms(event);
              }}
            >
              {" "}
              نعم{" "}
            </Button>
          </div>
        );
      },
    });
  };
  rowActionMain(event, actionType) {
    event.preventDefault();

    this.setState({
      selectAtLeastOneFlag: 0,
      // actionLoading: 1
    });

    if (this.state.checkedRow.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui alert-box-view">
              <h5>هل أنت متأكد ؟</h5>
              <p>هل تريد حذف العناصر المحددة ؟</p>
              <Button
                variant="contained"
                className="delete-reject-action-btn mr-2"
                onClick={onClose}
              >
                {" "}
                إلغاء{" "}
              </Button>
              <Button
                variant="contained"
                className="delete-accept-action-btn"
                onClick={() => {
                  this.rowAction(actionType);
                  onClose();
                }}
              >
                {" "}
                نعم{" "}
              </Button>
            </div>
          );
        },
      });
    } else {
      this.setState({
        selectAtLeastOneFlag: 1,
        // actionLoading: 0
      });
    }
  }
  getAllArea() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getArea",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            allArea: data,
          });
        }
      });
  }
  handleClickSendSms = (event) => {
    event.preventDefault();
    // here call api that send sms

    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getPostByCatAndCustomersForSMS",
        id: this.state.parent,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="custom-ui alert-box-view">
                  <p style={{ color: "#024502" }}>
                    {" "}
                    <i
                      className="far fa-check-circle ml-1"
                      style={{ fontSize: "15px" }}
                    ></i>{" "}
                    تم الارسال بنجاح{" "}
                  </p>
                  <Button
                    variant="contained"
                    className="delete-reject-action-btn mr-2"
                    onClick={onClose}
                  >
                    {" "}
                    إغلاق{" "}
                  </Button>
                </div>
              );
            },
          });
        }
      });
  };

  printCards = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p>هل تريد طباعة بطاقات الناخبين ؟</p>
            <p style={{ "font-size": "11px", color: "#943232" }}>
              سيتم طباعة بطاقات الناخبين المسموح لهم بالانتخاب فقط
            </p>
            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              إلغاء{" "}
            </Button>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={() => {
                this.handleClickPrintCards(event);
                onClose();
              }}
            >
              {" "}
              نعم{" "}
            </Button>
          </div>
        );
      },
    });
  };
  handleClickPrintCards = (event) => {
    event.preventDefault();
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getPostByCatAndCustomersForPrintCard",
        id: this.state.parent,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        pdf: 1,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          if (data["resNo"] == 0) {
            this.reportAlert(
              event,
              "تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي"
            );
          } else {
            this.reportAlert(event, "تعذر استلام طلبك !");
          }
        }
      });
  };
  reportAlert = (event, text) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p>{text}</p>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={onClose}
            >
              {" "}
              اغلاق{" "}
            </Button>
          </div>
        );
      },
    });
  };
  render() {
    var mainCol = [];
    var dataCol = [];
    var actionCol = [];
    if (this.state.loading == 0 && this.state.data.length > 0) {
      this.state.data.map((rowData, index) => {
        var color = "rgb(0, 88, 154)";
        if (rowData["featured"] == 1) {
          color = "rgb(130, 120, 128)";
        }
        mainCol.push(
          <div
            key={index}
            className="row flex-row flex-nowrap table-body-row-border row-even-bg-color"
          >
            <div
              className={
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") + " table-body-row overflow-text-length"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ borderRight: "6px solid " + color }}
            >
              {/* {((this.state.permations[this.state.parent + '_4'] != undefined && this.state.permations[this.state.parent + '_4'] == true) || (this.state.permations[this.state.parent + '_1'] != undefined && this.state.permations[this.state.parent + '_1'] == true) || JSON.parse(localStorage.getItem('parentPermations'))['_4'] == true || JSON.parse(localStorage.getItem('parentPermations'))['_1'] == true) &&
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"rowCheck-" + rowData['id']} onClick={() => this.checkRow('byId', rowData['id'])} readOnly checked={this.state.checkedRow.indexOf(rowData['id']) > -1 ? true : false} />
                                    <label className="custom-control-label" htmlFor={"rowCheck-" + rowData['id']}>&nbsp;</label>
                                </div>
                            } */}
            </div>
            <div
              onClick={
                rowData["featured"] != 1
                  ? () => this.changeParent(rowData["id"])
                  : ""
              }
              title={
                rowData["keyword_SEO"] == ""
                  ? "-"
                  : rowData["keyword_SEO"] +
                    " " +
                    rowData["desc_SEO"] +
                    " " +
                    rowData["countries"] +
                    " " +
                    rowData["location"]
              }
              className={
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                ((rowData["featured"] != 1 ? " pointer-cursor " : "") +
                  "overflow-text-length ml-0") +
                (rowData["featured"] != 1 ? " main-color-1 " : "") +
                "col-12 col-sm-12 col-md-12 col-lg-12 table-body-row overflow-text-length"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["keyword_SEO"] == "" ? (
                "-"
              ) : rowData["featured"] != 1 ? (
                <Link to={"/subDepartment/" + rowData["id"]}>
                  {" "}
                  {rowData["keyword_SEO"]} {rowData["desc_SEO"]}{" "}
                  {rowData["countries"]} {rowData["location"]}
                </Link>
              ) : (
                rowData["keyword_SEO"] +
                " " +
                rowData["desc_SEO"] +
                " " +
                rowData["countries"] +
                " " +
                rowData["location"]
              )}
            </div>
          </div>
        );
        var checkEvenRow = index % 2;
        dataCol.push(
          <div className="row flex-row flex-nowrap" key={index}>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["totalDepartment"] == undefined ||
              rowData["totalDepartment"] == "" ||
              rowData["totalDepartment"] == 0
                ? "-"
                : rowData["totalDepartment"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["votersTotal"] == undefined ||
              rowData["votersTotal"] == "" ||
              rowData["votersTotal"] == 0
                ? "-"
                : rowData["votersTotal"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["votedTotal"] == undefined ||
              rowData["votedTotal"] == "" ||
              rowData["votedTotal"] == 0
                ? "-"
                : rowData["votedTotal"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["notVotedTotal"] == undefined ||
              rowData["notVotedTotal"] == "" ||
              rowData["notVotedTotal"] == 0
                ? "-"
                : rowData["notVotedTotal"]}
            </div>
            <div
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["totalCommittees"] == undefined ||
              rowData["totalCommittees"] == "" ||
              rowData["totalCommittees"] == 0
                ? "-"
                : rowData["totalCommittees"]}
            </div>
            <div
              title={
                rowData["description"] == "" ||
                rowData["description"] == undefined
                  ? "-"
                  : rowData["description"]
              }
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["description"] == "" ||
              rowData["description"] == undefined
                ? "-"
                : rowData["description"]}
            </div>
            <div
              title={rowData["small_desc"] == "" ? "-" : rowData["small_desc"]}
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ direction: "ltr" }}
            >
              {rowData["small_desc"] == "" ? "-" : rowData["small_desc"]}
            </div>
            <div
              title={
                rowData["adminType"] == "" || rowData["adminType"] == 0
                  ? "-"
                  : this.getAdminNameById(rowData["adminType"])
              }
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["adminType"] == "" || rowData["adminType"] == 0
                ? "-"
                : this.getAdminNameById(rowData["adminType"])}
            </div>

            <div
              title={rowData["address"] == "" ? "-" : rowData["address"]}
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ direction: "ltr" }}
            >
              {rowData["address"] == "" ? "-" : rowData["address"]}
            </div>
            <div
              title={rowData["job"] == "" ? "-" : rowData["job"]}
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ direction: "ltr" }}
            >
              {rowData["job"] == "" ? "-" : rowData["job"]}
            </div>
            <div
              title={rowData["fb"] == "" ? "-" : rowData["fb"]}
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ direction: "ltr" }}
            >
              {rowData["fb"] == "" ? "-" : rowData["fb"]}
            </div>
            <div
              title={rowData["whatsapp"] == "" ? "-" : rowData["whatsapp"]}
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
              style={{ direction: "ltr" }}
            >
              {rowData["whatsapp"] == "" ? "-" : rowData["whatsapp"]}
            </div>

            <div
              title={rowData["areaName"] == "" ? "-" : rowData["areaName"]}
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["areaName"] == "" ? "-" : rowData["areaName"]}
            </div>
            <div
              title={
                rowData["subAreaName"] == "" ? "-" : rowData["subAreaName"]
              }
              className={
                (checkEvenRow == 0 ? " scroll-row-even-bg-color " : " ") +
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["subAreaName"] == "" ? "-" : rowData["subAreaName"]}
            </div>
          </div>
        );
        actionCol.push(
          <div className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
            <div
              className={
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-4 col-sm-2 col-md-4 col-lg-4 table-body-row overflow-text-length text-right action-btn-view"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {rowData["featured"] == 1 ? (
                <i
                  className="flaticon-edit-1"
                  title="اضافة بطاقات"
                  onClick={(event) =>
                    this.addCards(event, rowData, rowData["id"])
                  }
                ></i>
              ) : (
                "-"
              )}
              {rowData["featured"] == 1 ? (
                <i
                  className="flaticon-eye"
                  title="عرض"
                  onClick={(event) =>
                    this.showCustomers(event, rowData, rowData["id"])
                  }
                ></i>
              ) : (
                "-"
              )}
            </div>
            <div
              className={
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") +
                "col-8 col-sm-2 col-md-8 col-lg-8 table-body-row overflow-text-length text-right action-btn-view"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              {((this.state.permations[this.state.parent + "_3"] != undefined &&
                this.state.permations[this.state.parent + "_3"] == true) ||
                (this.state.permations[this.state.parent + "_1"] != undefined &&
                  this.state.permations[this.state.parent + "_1"] == true) ||
                JSON.parse(localStorage.getItem("parentPermations"))["_3"] ==
                  true ||
                JSON.parse(localStorage.getItem("parentPermations"))["_1"] ==
                  true) && (
                <i
                  className="flaticon-edit-1"
                  title="تعديل"
                  onClick={(event) =>
                    this.getChildDepartmentCount(
                      event,
                      "edit",
                      rowData,
                      rowData["id"]
                    )
                  }
                ></i>
              )}
              {rowData["featured"] == 1 &&
                localStorage.getItem("USER_LEVEL") == 2 && (
                  <i
                    style={{ lineHeight: "0.5" }}
                    class="flaticon2-plus-1"
                    title="أضافة ناخب"
                    onClick={(event) =>
                      this.openAddCustomerModal(
                        event,
                        rowData["id"],
                        rowData["departmentId"]
                      )
                    }
                  ></i>
                )}
              {((this.state.permations[this.state.parent + "_4"] != undefined &&
                this.state.permations[this.state.parent + "_4"] == true) ||
                (this.state.permations[this.state.parent + "_1"] != undefined &&
                  this.state.permations[this.state.parent + "_1"] == true) ||
                JSON.parse(localStorage.getItem("parentPermations"))["_4"] ==
                  true ||
                JSON.parse(localStorage.getItem("parentPermations"))["_1"] ==
                  true) && (
                <i
                  className="flaticon-delete"
                  title="حذف"
                  onClick={(event) =>
                    this.getChildDepartmentCountOnDelete(
                      event,
                      rowData["id"],
                      rowData["featured"],
                      rowData["votersTotal"],
                      rowData["cantVoteTotal"]
                    )
                  }
                ></i>
              )}
              {rowData["featured"] == 1 && (
                <i
                  style={{ lineHeight: "0.5" }}
                  class="far fa-file-excel"
                  title="استيراد ناخبين"
                  onClick={(event) =>
                    this.openImportModal(event, "add", rowData["id"])
                  }
                ></i>
              )}
              {rowData["featured"] != 1 && (
                <Link to={"/subDepartment/" + rowData["id"]}>
                  <i
                    className="flaticon-map"
                    title="ادارة"
                    onClick={() => this.changeParent(rowData["id"])}
                  ></i>
                </Link>
              )}
              {((this.state.permations[this.state.parent + "_6"] != undefined &&
                this.state.permations[this.state.parent + "_6"] == true) ||
                (this.state.permations[this.state.parent + "_1"] != undefined &&
                  this.state.permations[this.state.parent + "_1"] == true) ||
                JSON.parse(localStorage.getItem("parentPermations"))["_6"] ==
                  true ||
                JSON.parse(localStorage.getItem("parentPermations"))["_1"] ==
                  true) &&
                rowData["featured"] == 1 && (
                  <i
                    className="flaticon-refresh"
                    title="نقل صاحب الكشف"
                    onClick={(event) =>
                      this.move(event, rowData, rowData["id"])
                    }
                  ></i>
                )}
            </div>
          </div>
        );
      });
    }
    let fullBreadcrumb = "";
    if (this.state.Breadcrumb.length > 0) {
      fullBreadcrumb = this.state.Breadcrumb.map((data, index) => {
        if (data != null && data["parent"] == 0) {
          if (data["id"] != this.state.parent) {
            return (
              <Link
                to={"/subDepartment/" + data["id"]}
                className="breadCrumbName mr-1"
                onClick={() => this.changeParent(data["id"])}
              >
                {data["name"]}{" "}
              </Link>
            );
          } else {
            return (
              <span>
                <span className="breadCrumbDash"></span>
                {data["name"]}{" "}
              </span>
            );
          }
        } else if (data != null && data["id"] != this.state.parent) {
          return (
            <Link
              to={"/subDepartment/" + data["id"]}
              className="breadCrumbName mr-1"
              onClick={() => this.changeParent(data["id"])}
            >
              {" "}
              <span className="breadCrumbDash">|</span> {data["keyword_SEO"]}{" "}
              {data["desc_SEO"]} {data["countries"]} {data["location"]}{" "}
            </Link>
          );
        } else {
          return (
            <span>
              <span className="breadCrumbDash">|</span> {data["keyword_SEO"]}{" "}
              {data["desc_SEO"]} {data["countries"]} {data["location"]}
            </span>
          );
        }
      });
    }
    return (
      <div>
        <div className="breadCrumbDev">
          <Link to={"/departments"} className="mainPageBreadCrunb mr-1">
            المراكز الانتخابية <span className="breadCrumbDash">|</span>
          </Link>
          {fullBreadcrumb}
        </div>
        <Row>
          <Col md={12}>
            <div className="kt-portlet kt-portlet--height-fluid p-3">
              <Row>
                <Col md={4}>
                  <AvForm className="form-inline">
                    <Col md={12}>
                      <Row>
                        <Col md={2}>
                          <Button
                            variant="contained"
                            className="refresh-action-btn"
                            onClick={this.handleRefresh}
                            title="تحديث"
                          >
                            <i className="flaticon-refresh reset-filter-btn"></i>
                          </Button>
                        </Col>
                        <Col md={10}>
                          <AvInput
                            type="text"
                            name="searchTitle"
                            placeholder="بحث"
                            value={this.state.searchTitle}
                            onChange={this.handleSearchChange}
                            className="form-input-width"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </AvForm>
                </Col>
                <Col md={8} className="float-right text-right">
                  {((this.state.permations[this.state.parent + "_7"] !=
                    undefined &&
                    this.state.permations[this.state.parent + "_7"] == true) ||
                    (this.state.permations[this.state.parent + "_1"] !=
                      undefined &&
                      this.state.permations[this.state.parent + "_1"] ==
                        true) ||
                    JSON.parse(localStorage.getItem("parentPermations"))[
                      "_7"
                    ] == true ||
                    JSON.parse(localStorage.getItem("parentPermations"))[
                      "_1"
                    ] == true) && (
                    <Button
                      variant="contained"
                      className="print-cards-btn"
                      style={{ fontSize: 14 }}
                      onClick={(event) => this.printCards(event)}
                    >
                      {" "}
                      طباعة البطاقات&nbsp;&nbsp;
                      <i class="far fa-address-card"></i>
                    </Button>
                  )}
                  {((this.state.permations[this.state.parent + "_5"] !=
                    undefined &&
                    this.state.permations[this.state.parent + "_5"] == true) ||
                    (this.state.permations[this.state.parent + "_1"] !=
                      undefined &&
                      this.state.permations[this.state.parent + "_1"] ==
                        true) ||
                    JSON.parse(localStorage.getItem("parentPermations"))[
                      "_5"
                    ] == true ||
                    JSON.parse(localStorage.getItem("parentPermations"))[
                      "_1"
                    ] == true) && (
                    <Button
                      variant="contained"
                      className="send-sms-btn ml-2"
                      style={{ fontSize: 14 }}
                      onClick={(event) => this.sendSms(event)}
                    >
                      {" "}
                      ارسال رسالة&nbsp;&nbsp;<i class="flaticon-chat"></i>
                    </Button>
                  )}
                  {((this.state.permations[this.state.parent + "_3"] !=
                    undefined &&
                    this.state.permations[this.state.parent + "_3"] == true) ||
                    (this.state.permations[this.state.parent + "_1"] !=
                      undefined &&
                      this.state.permations[this.state.parent + "_1"] ==
                        true) ||
                    JSON.parse(localStorage.getItem("parentPermations"))[
                      "_3"
                    ] == true ||
                    JSON.parse(localStorage.getItem("parentPermations"))[
                      "_1"
                    ] == true) && (
                    <Button
                      variant="contained"
                      className="main-action-btn"
                      style={{ fontSize: 14 }}
                      onClick={(event) =>
                        this.openSidebarModal(event, "add", "")
                      }
                    >
                      {" "}
                      اضافة جديد &nbsp;&nbsp;
                      <i className="flaticon2-plus-1 pl-1"></i>
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="kt-portlet kt-portlet--height-fluid p-3">
              {this.state.loading == 1 && (
                <div className="data-loading-spinner">Loading...</div>
              )}
              {this.state.loading == 0 && this.state.data.length <= 0 && (
                <Row>
                  <Col md={12} className="text-center no-data-available-view">
                    <img src="/media/electra/no-data-icon-68.png" />
                    <p>لا يوجد بيانات لعرضها .&nbsp;.&nbsp; !</p>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Row className="m-1">
                  <Col md={2} className="pl-0 mb-2">
                    <div className="color-code-view">
                      <Row>
                        <Col md={1}>
                          <i
                            className="fas fa-square"
                            style={{ color: "rgb(0, 88, 154)" }}
                          ></i>
                        </Col>
                        <Col md={10}>لجنة / مركز</Col>
                      </Row>
                      <Row>
                        <Col md={1}>
                          <i
                            className="fas fa-square"
                            style={{ color: "rgb(130, 120, 128)" }}
                          ></i>
                        </Col>
                        <Col md={10}>صاحب كشف</Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={10} className="pl-0 text-right float-right">
                    <div className="d-inline-flex mb-2">
                      <div className="table-display-limit-text-1 mr-2 ml-2">
                        {" "}
                        عرض
                      </div>
                      <AvForm className="form-inline table-display-limit-select">
                        <AvField
                          type="select"
                          name="limit"
                          value={this.state.limit}
                          onChange={this.handleChangeLimit}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </AvField>
                      </AvForm>
                      <div className="table-display-limit-text-2 mr-2 ml-2">
                        {" "}
                        مدخلات{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Row className="m-1">
                  <div className="col-md-3 col-sm-3 col-3 col-lg-3">
                    <div className="row flex-row flex-nowrap table-header-row pr-5">
                      {/* <div className=" overflow-text-length d-inline-flex">
                                                {((this.state.permations[this.state.parent + '_4'] != undefined && this.state.permations[this.state.parent + '_4'] == true) || (this.state.permations[this.state.parent + '_1'] != undefined && this.state.permations[this.state.parent + '_1'] == true) || JSON.parse(localStorage.getItem('parentPermations'))['_4'] == true || JSON.parse(localStorage.getItem('parentPermations'))['_1'] == true) &&
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck-0" onClick={() => this.checkRow('all', '')} />
                                                        <label className="custom-control-label" htmlFor="customCheck-0">&nbsp;</label>
                                                    </div>
                                                }
                                                {((this.state.permations[this.state.parent + '_4'] != undefined && this.state.permations[this.state.parent + '_4'] == true) || (this.state.permations[this.state.parent + '_1'] != undefined && this.state.permations[this.state.parent + '_1'] == true) || JSON.parse(localStorage.getItem('parentPermations'))['_4'] == true || JSON.parse(localStorage.getItem('parentPermations'))['_1'] == true) &&
                                                    <button type="button" className="btn btn-sm  dropdown-toggle waves-effect arrow-none p-0" data-toggle="dropdown" aria-expanded="true" onClick={(event) => this.openCheckedActionMenu()}>
                                                    </button>
                                                }
                                                {((this.state.permations[this.state.parent + '_4'] != undefined && this.state.permations[this.state.parent + '_4'] == true) || (this.state.permations[this.state.parent + '_1'] != undefined && this.state.permations[this.state.parent + '_1'] == true) || JSON.parse(localStorage.getItem('parentPermations'))['_4'] == true || JSON.parse(localStorage.getItem('parentPermations'))['_1'] == true) &&
                                                    <div className={(this.state.openCheckedAction === true ? 'show ' : '') + "dropdown-menu"} x-placement="bottom-start" style={{ maxHeight: "300px", overflowY: "auto" }}>
                                                        <label className='pl-1 pr-1 pt-1'>الأجراءات {this.state.actionLoading == 1 && <i className='kt-spinner kt-spinner--md kt-spinner--light ml-2 action-loading-style'></i>}</label>
                                                        <a className="dropdown-item dropdown-item-active-style" href="#/" onClick={(event) => { this.rowActionMain(event, 'deleteSelected'); }}>حذف</a>
                                                        {this.state.selectAtLeastOneFlag == 1 &&
                                                            <div className="dropdown-item error-selected-row-null">اختر قسم واحد على الأقل</div>
                                                        }
                                                        {this.state.selectAtLeastOneFlag == 2 &&
                                                            <div className="dropdown-item error-selected-row-null">لا يمكن الحذف حاليا , بعض الاقسام المختارة غير فارغة</div>
                                                        }
                                                    </div>
                                                }
                                            </div> */}
                      <div className="overflow-text-length ml-0">
                        <div
                          className="pointer-cursor"
                          onClick={() => this.sortCoulmn("keyword_SEO")}
                        >
                          الاسم{" "}
                          {this.state.sortColName == "keyword_SEO" ? (
                            this.state.selectedSortType["keyword_SEO"] == 0 ? (
                              <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                            ) : (
                              <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                            )
                          ) : (
                            <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                          )}
                        </div>
                      </div>
                    </div>
                    {mainCol}
                  </div>
                  <div
                    className="col-md-6 col-sm-6 col-6 col-lg-6 pr-0"
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      width: "100%",
                    }}
                  >
                    <div className="row flex-row flex-nowrap">
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("totalDepartment")}
                          >
                            عدد المراكز / اللجان{" "}
                            {this.state.sortColName == "totalDepartment" ? (
                              this.state.selectedSortType["totalDepartment"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("votersTotal")}
                          >
                            عدد الناخبين الكلي{" "}
                            {this.state.sortColName == "votersTotal" ? (
                              this.state.selectedSortType["votersTotal"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("votedTotal")}
                          >
                            عدد المصوتيين{" "}
                            {this.state.sortColName == "votedTotal" ? (
                              this.state.selectedSortType["votedTotal"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("notVotedTotal")}
                          >
                            عدد الغير مصوتيين{" "}
                            {this.state.sortColName == "notVotedTotal" ? (
                              this.state.selectedSortType["notVotedTotal"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("totalCommittees")}
                          >
                            عدد اصحاب الكشوفات{" "}
                            {this.state.sortColName == "totalCommittees" ? (
                              this.state.selectedSortType["totalCommittees"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("description")}
                          >
                            الرقم الوطني{" "}
                            {this.state.sortColName == "description" ? (
                              this.state.selectedSortType["description"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("small_desc")}
                          >
                            رقم الهاتف{" "}
                            {this.state.sortColName == "small_desc" ? (
                              this.state.selectedSortType["small_desc"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("adminType")}
                          >
                            الشخص المسؤول{" "}
                            {this.state.sortColName == "adminType" ? (
                              this.state.selectedSortType["adminType"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("address")}
                          >
                            العنوان{" "}
                            {this.state.sortColName == "address" ? (
                              this.state.selectedSortType["address"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("job")}
                          >
                            العمل{" "}
                            {this.state.sortColName == "job" ? (
                              this.state.selectedSortType["job"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("fb")}
                          >
                            Facebook{" "}
                            {this.state.sortColName == "fb" ? (
                              this.state.selectedSortType["fb"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("whatsapp")}
                          >
                            Whatsapp{" "}
                            {this.state.sortColName == "whatsapp" ? (
                              this.state.selectedSortType["whatsapp"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div>المنطقة </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-0">
                          <div>المعلم البارز </div>
                        </div>
                      </div>
                    </div>
                    {dataCol}
                  </div>
                  <div className="col-md-3 col-sm-3 col-3 col-lg-3">
                    <div className="row flex-row flex-nowrap">
                      <div className="col-4 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length text-right pl-4">
                        الناخبين
                      </div>
                      <div className="col-8 col-sm-4 col-md-8 col-lg-8 table-header-row overflow-text-length text-right pl-4">
                        الإجراء
                      </div>
                    </div>
                    {actionCol}
                  </div>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Row className="m-1">
                  <Col md={6} className="pagination-style float-left text-left">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      prevPageText={<i className="flaticon2-right-arrow"></i>}
                      nextPageText={<i className="flaticon2-left-arrow"></i>}
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="mt-2 float-right text-right">
                      عرض{" "}
                      {this.state.activePage != 1
                        ? this.state.activePage +
                          (this.state.limit * (this.state.activePage - 1) -
                            this.state.activePage +
                            1)
                        : this.state.activePage}{" "}
                      الى{" "}
                      {this.state.activePage * this.state.limit >
                      this.state.dataCount
                        ? this.state.dataCount
                        : this.state.activePage * this.state.limit}{" "}
                      من {this.state.dataCount} مدخلات
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>

        <Drawer
          anchor={"left"}
          open={this.state.openleftModal}
          className="sidebar-modal-view"
        >
          {this.state.openleftModal == true && (
            <SubDepartmentForm
              ref="subDepartmentForm"
              parent={this.state.parent}
              status={this.state.status}
              allAdminData={this.state.allAdminData}
              subDepartmentData={this.state.subDepartmentData}
              handleCloseSideBar={this.handleCloseSideBar}
              handleFormAction={this.handleFormAction}
              childCount={this.state.childCount}
              allArea={this.state.allArea}
            />
          )}
        </Drawer>
        <Drawer
          anchor={"left"}
          open={this.state.openImportModal}
          className="sidebar-modal-view"
        >
          <ImportCustomer
            ref="importCustomer"
            memberId={this.state.memberId}
            handleCloseImportSideBar={this.handleCloseImportSideBar}
            handleImportAction={this.handleImportAction}
          />
        </Drawer>
        <Drawer
          anchor={"left"}
          open={this.state.openAddCardsModal}
          className="sidebar-modal-view"
        >
          <AddCardsForm
            memberId={this.state.memberId}
            handleCloseAddCards={this.handleCloseAddCards}
            canEdit={
              (this.state.permations[this.state.parent + "_3"] != undefined &&
                this.state.permations[this.state.parent + "_3"] == true) ||
              (this.state.permations[this.state.parent + "_1"] != undefined &&
                this.state.permations[this.state.parent + "_1"] == true) ||
              JSON.parse(localStorage.getItem("parentPermations"))["_3"] ==
                true ||
              JSON.parse(localStorage.getItem("parentPermations"))["_1"] == true
            }
            canDelete={
              (this.state.permations[this.state.parent + "_4"] != undefined &&
                this.state.permations[this.state.parent + "_4"] == true) ||
              (this.state.permations[this.state.parent + "_1"] != undefined &&
                this.state.permations[this.state.parent + "_1"] == true) ||
              JSON.parse(localStorage.getItem("parentPermations"))["_4"] ==
                true ||
              JSON.parse(localStorage.getItem("parentPermations"))["_1"] == true
            }
            canExport={
              (this.state.permations[this.state.parent + "_7"] != undefined &&
                this.state.permations[this.state.parent + "_7"] == true) ||
              (this.state.permations[this.state.parent + "_1"] != undefined &&
                this.state.permations[this.state.parent + "_1"] == true) ||
              JSON.parse(localStorage.getItem("parentPermations"))["_7"] ==
                true ||
              JSON.parse(localStorage.getItem("parentPermations"))["_1"] == true
            }
          />
        </Drawer>
        <Drawer
          anchor={"left"}
          open={this.state.openAddCustomerModal}
          className="sidebar-modal-view"
        >
          <CustomersForm
            ref="customersForm"
            status={"add"}
            customerData={[]}
            handleCloseSideBar={this.handleCloseAddCustomerSideBar}
            handleFormAction={this.handleCloseAddCustomerSideBar}
            allArea={this.state.allArea}
            memberId={this.state.memberId}
            allDepartement={[]}
            departementFlag={true}
            centerId={this.state.centerId}
          />
        </Drawer>
        <Drawer
          anchor={"left"}
          open={this.state.openCustomerModal}
          className="sidebar-modal-view-lg"
        >
          <ShowCustomer
            memberId={this.state.memberId}
            handleCloseCustomerSideBar={this.handleCloseCustomerSideBar}
            handleCustomerAction={this.handleCustomerAction}
            subDepartmentData={this.state.subDepartmentData}
          />
        </Drawer>
      </div>
    );
  }
}
export default SubDepartment;
