import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

class ColorCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className='color-code-view'>
                <Row>
                    <Col md={1}><i className='fas fa-square' style={{ 'color': '#0EA46D' }}></i></Col>
                    <Col md={10}>الناخبين المصوتيين</Col>
                </Row>
                <Row>
                    <Col md={1}><i className='fas fa-square' style={{ 'color': '#FF0000' }}></i></Col>
                    <Col md={10}>الناخبين غير المصوتيين</Col>
                </Row>
            </div>
        )
    }
}
export default ColorCode;