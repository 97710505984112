import React, { Component } from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorLogin: false,
      errorLoginMsg: '',
      showPasswordField: true,
      resetPasswordEmailError: '',
      token: '',
      errorCode: '',
      resetUserId: '',
      showResetPasswordForm: false,
      successResetPassword: 0
    }
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }




  handleValidSubmit(event) {
    this.setState({
      errorLogin: false,
      errorLoginMsg: '',
      resetPasswordEmailError: '',
      errorCode: ''
      // token: ''
    })

    if (this.state.showPasswordField != true) {
      // call api to send reset code
      if (this.state.showResetPasswordForm == true) {
        this.resetPasswordHandler()
      } else if (this.state.token != null && this.state.token != undefined && this.state.token != '') {
        this.checkCode()
      } else {
        this.getResetPasswordCode()
      }
    } else {
      fetch(window.API_URL_DATA, {
        method: 'POST',
        body: JSON.stringify({
          'type': 'login',
          'email': this.state.username,
          'password': this.state.password,
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        },
      }).then(function (response) {
        return response.json();
      }).then(data => {
        if (typeof data !== 'Nothing found') {
          if (data['resNo'] == 0) {
            // login success
            let parentPermations = {
              '_1': false,
              '_2': false,
              '_3': false,
              '_4': false,
              '_5': false,
              '_6': false,
              '_7': false,
            }
            localStorage.setItem('USER_ID', data['userData']['userId']);
            localStorage.setItem('USER_EMAIL', data['userData']['userEmail']);
            localStorage.setItem('USER_FULL_NAME', data['userData']['fname'] + ' ' + data['userData']['lname']);
            localStorage.setItem('USER_TOKEN', data['userData']['token']);
            localStorage.setItem('LOGO_NAME', data['departmentName']);
            localStorage.setItem('USER_LEVEL', data['userData']['admin']);
            localStorage.setItem('permations', JSON.stringify(data['userData']['permations']));
            localStorage.setItem('parentPermations', JSON.stringify(parentPermations));
            localStorage.setItem('uploadExcelFileStatus', 0);
            localStorage.setItem('uploadExcelFileTotal', 0);
            localStorage.setItem('uploadExcelFileFinished', 0);
            window.location = "/dashboard";
          } else {
            // error in login 
            this.setState({
              errorLogin: true,
              errorLoginMsg: data['resMsg']
            })
          }
        }
      });
    }


  }




  checkCode() {
    fetch(window.API_URL_DATA, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'checkCode',
        'userId': this.state.resetUserId,
        'code': this.state.code,
        'token': this.state.token
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (typeof data !== 'Nothing found') {

        if (data['resNo'] == 0) { // success
          this.setState({ showResetPasswordForm: true })
        } else { // fail
          this.setState({
            errorCode: data['resMsg']
          })
        }

      }
    });
  }

  resendPasswordCode() {
    fetch(window.API_URL_DATA, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'resendCode',
        'email': this.state.username,
        'userId': this.state.resetUserId,
        'token': this.state.token
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (typeof data !== 'Nothing found') {
        // console.log('DATA');
        // console.log(data);

        if (data['resNo'] == 1) { // success
          this.setState({
            token: data['token'],
            resetUserId: data['userId']
          })
        } else { // fail
          this.setState({ resetPasswordEmailError: data['resMsg'] })
        }

      }
    });
  }

  getResetPasswordCode() {
    fetch(window.API_URL_DATA, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'resetPassword',
        'email': this.state.username,
        'userId': this.state.resetUserId,
        'token': this.state.token
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (typeof data !== 'Nothing found') {

        if (data['resMsg'] == 0) { // success
          this.setState({
            token: data['token'],
            resetUserId: data['userId']
          })
        } else { // fail
          this.setState({ resetPasswordEmailError: data['resMsg'] })
        }

      }
    });
  }

  resetPasswordHandler() {
    if (this.state.newPassword != this.state.confirmPassword) {
      this.setState({ errorResetPassword: 'كلمة السر غير متطابقة' })
    } else {
      fetch(window.API_URL_DATA, {
        method: 'POST',
        body: JSON.stringify({
          'type': 'changePassword',
          'code': this.state.code,
          'userId': this.state.resetUserId,
          'newPassword': this.state.newPassword,
          'token': this.state.token
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        },
      }).then(function (response) {
        return response.json();
      }).then(data => {
        if (typeof data !== 'Nothing found') {

          if (data['resNo'] == 0) { // success
            this.setState({
              successResetPassword: 1,
              showPasswordField: true,
              showResetPasswordForm: false,
              token: '',
              password: ''
            })
          } else { // fail
            this.setState({ errorResetPassword: data['resMsg'] })
          }

        }
      });
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value,
      resetPasswordEmailError: '',
      errorCode: ''
      // token: ''
    })

    if (this.state.errorLogin == true) {
      this.setState({
        errorLogin: false,
        errorLoginMsg: ''
      })
    }
  }

  handlePasswordInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value,
      errorResetPassword: ''
    })
  }

  showHideResetPasswordFields = () => {
    this.setState({ showPasswordField: !this.state.showPasswordField, successResetPassword: 0 }, function () {
      if (this.state.showPasswordField == true) {
        this.setState({ token: '', showResetPasswordForm: false })
      }
    })
  }

  render() {
    return (
      <div className='login-form-view-padding'>
        <div className="kt-login__body">
          <div className="kt-login__form">

            <div className="kt-login__title">
              <h3 className='main-color-1'>

                {this.state.showPasswordField == true ?
                  'تسجيل الدخول إلى حساب المستخدم'
                  :
                  'إعادة تعيين كلمة المرور'
                }
              </h3>
            </div>

            <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)} className='login-form-input main-color-1'>

              {(this.state.token != '' && this.state.token != undefined && this.state.token != null && this.state.showResetPasswordForm == false) ?
                <AvField type="number" name="code" label='رمز التأكيد' placeholder='أدخل رمز التأكيد' value={this.state.code} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                :
                this.state.showResetPasswordForm == false &&
                <AvField type="email" name="username" label='البريد الإلكتروني' placeholder='أدخل البريد الإلكتروني' value={this.state.username} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
              }

              {/* <AvField type="email" name="username" label='البريد الإلكتروني' placeholder='أدخل البريد الإلكتروني' value={this.state.username} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" /> */}
              {this.state.showPasswordField == true &&
                <AvField type='password' name="password" label='كلمة المرور' placeholder='أدخل كلمة المرور' value={this.state.password} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
              }


              {this.state.showResetPasswordForm == true &&
                <div>
                  <AvField type='password' name="newPassword" autoComplete="new-password" label='كلمة المرور' placeholder='أدخل كلمة المرور' value={this.state.newPassword} required onChange={this.handlePasswordInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                  <AvField type='password' name="confirmPassword" autoComplete="new-password" label='تأكيد كلمة المرور' placeholder='تأكيد كلمة المرور' value={this.state.confirmPassword} required onChange={this.handlePasswordInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                </div>
              }

              {this.state.showResetPasswordForm == false &&
                <div className='main-color-1 pointer-cursor' onClick={() => this.showHideResetPasswordFields()}>
                  {this.state.showPasswordField == true ?
                    'هل نسيت كلمة السر ؟'
                    :
                    'عودة إلى تسجيل الدخول .'
                  }
                </div>
              }

              {this.state.errorLogin == true &&
                <div className='login-error-msg'>
                  {this.state.errorLoginMsg}
                </div>
              }



              {(this.state.resetPasswordEmailError != '' && this.state.resetPasswordEmailError != null && this.state.resetPasswordEmailError != undefined) &&
                <div className='login-error-msg'>
                  {this.state.resetPasswordEmailError}
                </div>
              }

              {(this.state.errorCode != '' && this.state.errorCode != null && this.state.errorCode != undefined) &&
                <div className='login-error-msg'>
                  {this.state.errorCode}
                </div>
              }

              {(this.state.errorResetPassword != '' && this.state.errorResetPassword != null && this.state.errorResetPassword != undefined) &&
                <div className='login-error-msg'>
                  {this.state.errorResetPassword}
                </div>
              }

              {(this.state.successResetPassword != '' && this.state.successResetPassword != null && this.state.successResetPassword != undefined) &&
                <div className='form-success-msg'>
                  تم إعادة تعيين كلمة المرور بنجاح , الرجاء اعادة تسجيل الدخول
                </div>
              }




              <div className="text-right">
                {this.state.showPasswordField == true &&
                  <button type="submit" className="btn main-action-btn ml-1"> تسجيل الدخول</button>
                }

                {(this.state.token != '' && this.state.token != undefined && this.state.token != null && this.state.showResetPasswordForm == false) &&
                  <button type='button' className="btn import-action-btn ml-1" onClick={() => this.resendPasswordCode()}>إعادة ارسال رمز التأكيد</button>
                }

                {(this.state.showPasswordField != true && this.state.showResetPasswordForm == false) &&
                  <button type="submit" className="btn main-action-btn ml-1">
                    {(this.state.token != '' && this.state.token != undefined && this.state.token != null) ?
                      'تأكيد الرمز'
                      :
                      'أرسل رمز التأكيد'
                    }

                  </button>
                }

                {this.state.showResetPasswordForm == true &&
                  <button type="submit" className="btn main-action-btn ml-1">إعادة تعيين كلمة المرور</button>
                }

              </div>

            </AvForm>

          </div>
        </div>
      </div>
    )
  }
}
export default Login;