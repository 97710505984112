import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Progress, Button } from 'reactstrap';
import Pagination from "react-js-pagination";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { confirmAlert } from 'react-confirm-alert';

class ShowCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberId: this.props.memberId,
            activeTab: 1,
            data: [],
            dataCount: 0,
            activePage: 1,
            limit: 10,
            openMenu: -1,
            openCheckedAction: false,
            checkedRow: [],
            showMoreData: [],
            showSubMoreData: [],
            openRightModal: false,
            sortColNum: '',
            selectedSortType: [],
            loading: 1,
            customerData: [],
            selectAtLeastOneFlag: 0,
            actionLoading: 0,
            colorTypes: [],
            adminType: -1,
            sortType: '',
            allMembers: [],
            allSchools: [],
            allArea: [],
            allDepartement: [],
            openImportModal: false,
            allSpecificArea: [],
            allVoters: this.props.subDepartmentData['votersTotal'],
            notVoted: this.props.subDepartmentData['notVotedTotal'],
            voted: this.props.subDepartmentData['votedTotal'],
            cantVoteTotal: this.props.subDepartmentData['cantVoteTotal'],
            permations: {},
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value,
        })
    }
    componentWillMount() {
        this.getPermation();
    }
    getPermation() {
        var permations = {};
        if (localStorage.getItem('USER_LEVEL') == 1) {
            permations = JSON.parse(localStorage.getItem('permations')) != undefined && JSON.parse(localStorage.getItem('permations')) != null &&
                JSON.parse(localStorage.getItem('permations'))['customersData'] != undefined && JSON.parse(localStorage.getItem('permations'))['customersData'] != null ? JSON.parse(localStorage.getItem('permations'))['customersData'] : {};
        } else if (localStorage.getItem('USER_LEVEL') == 3) {
            permations = {
                '1_1': true, // all
                '8_8': true, // pdf
            };
        } else {
            permations = {
                '1_1': 0, // all
                '8_8': 0, // pdf
            };
        }
        this.setState({
            permations: permations
        }, function () {
            this.reloadComponent();
        })

    }

    reloadComponent() {
        this.setState({
            memberId: this.props.memberId,
            activeTab: 1,
            data: [],
            dataCount: 0,
            activePage: 1,
            limit: 10,
            openMenu: -1,
            openCheckedAction: false,
            checkedRow: [],
            showMoreData: [],
            showSubMoreData: [],
            openRightModal: false,
            sortColNum: '',
            selectedSortType: [],
            loading: 1,
            customerData: [],
            selectAtLeastOneFlag: 0,
            actionLoading: 0,
            colorTypes: [],
            adminType: -1,
            sortType: '',
            allMembers: [],
            allSchools: [],
            allArea: [],
            allDepartement: [],
            openImportModal: false,
            allSpecificArea: [],
            allVoters: '',
            notVoted: this.props.subDepartmentData['notVotedTotal'],
            voted: this.props.subDepartmentData['votedTotal'],
            cantVoteTotal: this.props.subDepartmentData['cantVoteTotal'],
        }, function () {
            this.showCustomersLimit();
            this.showCustomersCount();
        })
    }
    showCustomersLimit() {
        this.setState({
            loading: 1
        })
        let votedStatus = this.state.activeTab == 1 ? 0 : (this.state.activeTab == 2 ? 1 : -1)
        let canVote = this.state.activeTab == 4 ? 0 : (this.state.activeTab == 3 ? -1 : 1);
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'getType': "limit",
                'limit': 10,
                'memberId': this.state.memberId,
                'type': "getCustomers",
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sortType': this.state.sortType,
                'sort': this.state.sortColNum,
                'voted': canVote == 1 ? votedStatus : -1,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'canVote': canVote
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    data: data,
                }, function () {
                    this.setState({
                        loading: 0
                    })
                });

            }
        });
    }
    showCustomersCount() {
        let votedStatus = this.state.activeTab == 1 ? 0 : (this.state.activeTab == 2 ? 1 : -1)
        let canVote = this.state.activeTab == 4 ? 0 : (this.state.activeTab == 3 ? -1 : 1);
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'getType': "count",
                'memberId': this.state.memberId,
                'type': "getCustomers",
                'voted': canVote == 1 ? votedStatus : -1,
                'canVote': canVote,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                var stateVal = this.state.activeTab == 1 ? 'allVoters' : (this.state.activeTab == 2 ? 'voted' : 'notVoted')
                stateVal = this.state.activeTab == 4 ? 'cantVoteTotal' : stateVal;
                this.setState({ [stateVal]: data[0]['counter'] })
                this.setState({
                    dataCount: data[0]['counter'],
                });

            }
        });
    }
    addActive = (index, event) => {
        var stateVal = 'activeTab'
        this.setState({ [stateVal]: index }, function () {
            this.showCustomersCount();
            this.showCustomersLimit();
        })
    }
    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }

    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.showCustomersLimit();
        });
    }
    sortCoulmn = (colNum) => {
        this.setState({ sortColNum: colNum }, function () {
            if ((this.state.selectedSortType[colNum] == '' || this.state.selectedSortType[colNum] == undefined || this.state.selectedSortType[colNum] == null) && this.state.selectedSortType[colNum] != 0) {
                var sortVal = 0 // desc
                var sortType = 'desc'
            } else if (this.state.selectedSortType[colNum] == 1) {
                var sortVal = 0 // desc
                var sortType = 'desc'
            } else {
                var sortVal = 1 // asc
                var sortType = 'asc'
            }
            const newArray = this.state.selectedSortType;
            newArray[colNum] = sortVal;
            this.setState({ selectedSortType: newArray, sortType: sortType }, function () {
                this.showCustomersLimit();
            });
        });
    }
    printCards = (event) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>هل تريد طباعة بطاقات الناخبين ؟</p>
                        <p style={{ 'font-size': '11px', 'color': '#943232' }}>سيتم طباعة بطاقات الناخبين المسموح لهم بالانتخاب فقط</p>
                        <Button variant="contained" className='delete-reject-action-btn mr-2' onClick={onClose}> إلغاء </Button>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={() => { this.handleClickPrintCards(event); onClose() }}> نعم </Button>
                    </div>

                );
            }
        });
    }
    handleClickPrintCards = (event) => {
        event.preventDefault();
        let voted = this.state.activeTab != 4 ? (this.state.activeTab - 1) : '';
        let canVote = this.state.activeTab == 4 ? 0 : 1;
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCustomers',
                'export': 1,
                'memberId': this.state.memberId,
                'sort': this.state.sortColNum,
                'canVote': canVote,
                'voted': voted,
                'sortType': this.state.sortType,
                'fromLimit': -1,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'pdf': 1,
                'getType': '',
                'card': 1,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                if (data['resNo'] == 0) {
                    this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                } else {
                    this.reportAlert(event, 'تعذر استلام طلبك !');
                }

            }
        });

    }
    exportCustomers = (event, type = '') => {
        event.preventDefault();
        let voted = this.state.activeTab != 4 ? (this.state.activeTab - 1) : '';
        let canVote = this.state.activeTab == 4 ? 0 : (type == 'all' ? -1 : 1);
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCustomers',
                'export': 1,
                'memberId': this.state.memberId,
                'sort': this.state.sortColNum,
                'canVote': canVote,
                'voted': voted,
                'sortType': this.state.sortType,
                'fromLimit': -1,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'pdf': 1,
                'getType': '',
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                if (data['resNo'] == 0) {
                    this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                } else {
                    this.reportAlert(event, 'تعذر استلام طلبك !');
                }

            }
        });
    }
    reportAlert = (event, text) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>{text}</p>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={onClose}> اغلاق </Button>
                    </div>

                );
            }
        });
    }
    render() {
        var mainCol = []
        var dataCol = []
        var actionCol = []

        if (this.state.loading == 0 && this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {
                mainCol.push(
                    <div key={index} className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-12 col-md-12 col-lg-12 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['name']} {rowData['secondName']} {rowData['thirdName']} {rowData['fourthName']}
                        </div>
                    </div>
                )
                var checkEvenRow = index % 2;
                dataCol.push(
                    <div className='row flex-row flex-nowrap' key={index} >
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['idNumber'] == '' ? '-' : rowData['idNumber']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {rowData['phoneNumber'] == '' ? '-' : rowData['phoneNumber']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {rowData['phoneNumber2'] == '' ? '-' : rowData['phoneNumber2']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['regionName'] == '' ? '-' : rowData['regionName']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['specificThingName'] == '' ? '-' : rowData['specificThingName']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['memberName'] == '' ? '-' : rowData['memberName']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['stationName'] == '' ? '-' : rowData['stationName']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['centerName'] == '' ? '-' : rowData['centerName']}
                        </div>

                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {(rowData['work'] == '' || rowData['work'] == undefined) ? '-' : rowData['work']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['facebook'] == '' ? '-' : rowData['facebook']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['whatsapp'] == '' ? '-' : rowData['whatsapp']}
                        </div>
                    </div>
                )
            })
        }
        return (
            <div>
                <div className="test-modal-style-lg">
                    <div className='sidebar-modal-view-lg sidebar-header-view-lg'>
                        <Row>
                            <Col md={11}>
                                <div className='main-color-1 font-weight-500 sidebar-header-title-lg'>قائمة الناخبين
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='flaticon2-delete sidebar-header-close-btn mr-5' onClick={this.props.handleCloseCustomerSideBar}></i>
                            </Col>
                        </Row>
                    </div>


                    <div className='sidebar-body-view nav-fill pb-0'>
                        <ul class="nav nav-tabs">
                            <li className={("nav-item") + (this.state['activeTab'] == 1 ? ' active ' : '')} onClick={this.addActive.bind(this, 1)}>
                                <a class="nav-link" >الناخبين المسموح لهم بالتصويت<span className='badge ml-3 mr-3'> {this.state.allVoters == '' ? 0 : this.state.allVoters} </span></a>
                            </li>
                            <li className={("nav-item") + (this.state['activeTab'] == 2 ? ' active ' : '')} onClick={this.addActive.bind(this, 2)}>
                                <a class="nav-link">الناخبين المصوتيين<span className='badge ml-3 mr-3'> {this.state.voted == '' ? 0 : this.state.voted} </span></a>
                            </li>
                            <li className={("nav-item") + (this.state['activeTab'] == 3 ? ' active ' : '')} onClick={this.addActive.bind(this, 3)}>
                                <a class="nav-link">الناخبين غير المصوتيين<span className='badge ml-3 mr-3'> {this.state.notVoted == '' ? 0 : this.state.notVoted} </span></a>
                            </li>
                            <li className={("nav-item") + (this.state['activeTab'] == 4 ? ' active ' : '')} onClick={this.addActive.bind(this, 4)}>
                                <a class="nav-link">الغير مسموح لهم بالتصويت<span className='badge ml-3 mr-3'> {this.state.cantVoteTotal == '' ? 0 : this.state.cantVoteTotal} </span></a>
                            </li>
                        </ul>
                    </div>
                    <div className='mainShowCustomer mr-3 ml-3'>
                        <Row className='mr-3 ml-3 pt-5' style={{ minHeight: '770px' }}>
                            <Col md={12}>
                                <div className='kt-portlet kt-portlet--height-fluid p-3 mr-3 ml-3'>
                                    {this.state.loading == 1 &&
                                        <div className="data-loading-spinner">Loading...</div>
                                    }
                                    {(this.state.loading == 0 && this.state.data.length <= 0) &&
                                        <Row>
                                            <Col md={12} className='text-center no-data-available-view'>
                                                <img src='/media/electra/no-data-icon-68.png' />
                                                <p>لا يوجد بيانات  .&nbsp;.&nbsp; !</p>
                                            </Col>
                                        </Row>
                                    }
                                    {(((this.state.permations['1_1'] != undefined && this.state.permations['1_1'] == true) || (this.state.permations['8_8'] != undefined && this.state.permations['8_8'] == true)) && (this.state.loading == 0 && this.state.data.length > 0)) &&
                                        <Row>
                                            {this.state['activeTab'] == 1 &&
                                                <Col md={9} className='text-left pb-2'>
                                                    <Button variant="contained" className='print-cards-btn' style={{ fontSize: 14 }} onClick={(event) => this.exportCustomers(event, 'all')}> طباعة الكشف كامل &nbsp;&nbsp;<i class="far fa-file-pdf"></i></Button>
                                                </Col>
                                            }
                                            <Col md={this.state['activeTab'] == 1 ? 2 : 11} className={this.state['activeTab'] == 1 ? 'text-right p-0 pb-2' : 'text-right pb-2'}>
                                                <Button variant="contained" className='print-cards-btn' style={{ fontSize: 14 }} onClick={(event) => this.printCards(event)}> طباعة البطاقات&nbsp;&nbsp;<i class="far fa-address-card"></i></Button>
                                            </Col>
                                            <Col md={1} className={'text-left p-0 pb-2'}>
                                                <Button variant="contained" className='pdf-btn ml-2 mr-0' style={{ fontSize: 14 }} onClick={(event) => this.exportCustomers(event)}> Pdf &nbsp;&nbsp;<i class="far fa-file-pdf"></i></Button>
                                            </Col>

                                        </Row>
                                    }
                                    {(this.state.loading == 0 && this.state.data.length > 0) &&
                                        <Row className='m-1'>
                                            <div className='col-md-3 col-sm-3 col-3 col-lg-3'>
                                                <div className="row flex-row flex-nowrap" >
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 table-header-row overflow-text-length">
                                                        <div className='pointer-cursor' onClick={() => this.sortCoulmn('name')}>اسم الناخب {this.state.sortColNum == 'name' ? (this.state.selectedSortType['name'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                    </div>
                                                </div>

                                                {mainCol}
                                            </div>
                                            <div className='col-md-9 col-sm-9 col-9 col-lg-9 pr-0' style={{ 'overflowX': 'auto', 'overflowY': 'hidden', 'width': '100%' }}>
                                                <div className="row flex-row flex-nowrap">
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                        <div className="overflow-text-length ml-0">
                                                            <div className='pointer-cursor' onClick={() => this.sortCoulmn('idNumber')}>الرقم الوطني {this.state.sortColNum == 'idNumber' ? (this.state.selectedSortType['idNumber'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                        <div className="overflow-text-length ml-0">
                                                            <div className='pointer-cursor' onClick={() => this.sortCoulmn('phoneNumber')}>رقم الهاتف (1) {this.state.sortColNum == 'phoneNumber' ? (this.state.selectedSortType['phoneNumber'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                        <div className="overflow-text-length ml-0">
                                                            <div className='pointer-cursor' onClick={() => this.sortCoulmn('phoneNumber2')}>رقم الهاتف (2) {this.state.sortColNum == 'phoneNumber2' ? (this.state.selectedSortType['phoneNumber2'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>المنطقة</div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>المعلم البارز</div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>صاحب الكشف</div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>المركز الرئيسي</div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>المركز المباشر للعضو</div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>العمل</div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>Facebook</div>
                                                    <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>Whatsapp</div>
                                                </div>
                                                {dataCol}
                                            </div>
                                        </Row>
                                    }
                                    {(this.state.loading == 0 && this.state.data.length > 0) &&
                                        <Row className='m-1 customerPaging'>
                                            <Col md={6} className='pagination-style float-left text-left'>
                                                <Pagination
                                                    activePage={this.state.activePage}
                                                    itemsCountPerPage={this.state.limit}
                                                    totalItemsCount={this.state.dataCount}
                                                    pageRangeDisplayed={5}
                                                    onChange={this.handlePageChange}
                                                    prevPageText={<i className="flaticon2-right-arrow"></i>}
                                                    nextPageText={<i className="flaticon2-left-arrow"></i>}
                                                    hideFirstLastPages
                                                    innerClass={'pagination  pagination-rounded pagenation-view my-2'}
                                                    itemClass={'page-item'}
                                                    linkClass={'page-link'}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <div className='mt-2 float-right text-right'>
                                                    عرض {this.state.activePage != 1 ? this.state.activePage + ((this.state.limit * (this.state.activePage - 1)) - this.state.activePage + 1) : this.state.activePage} الى {(this.state.activePage * this.state.limit) > this.state.dataCount ? this.state.dataCount : this.state.activePage * this.state.limit} من {this.state.dataCount} مدخلات
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        )
    }
}
export default ShowCustomer;