import React from "react";
import { Link, Switch, Route, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";

export default function AuthPage() {
  // console.log("authPage");
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root" style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/electra/bg-2.jpg")})`
      }}>
        <div
          id="kt_login"
          className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
        >
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div
              className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
              style={{
                backgroundImage: `url(${toAbsoluteUrl("/media/electra/bg-2.jpg")})`
              }}
            >
              {/* <div className='login-left-image-view text-center'>
                 
                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver mt-4">
                  <div className="kt-grid__item kt-grid__item--middle">
                    <h3 className="kt-login__title login-left-content-style">Electra </h3>
                  </div>
                </div>
              </div> */}
              <div className='login-page-right-side-view-title'>
                
              <img
              className=""
              alt="logo"
              src={toAbsoluteUrl("/media/electra/whiteLogo.png")}
              style={{'height': 'auto', 'width': '250px'}}
            />
              </div>
              {/* <div>
                جميع الحقوق محفوظة
              </div> */}
            </div>

            <div className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper" style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/electra/bg-3.jpg")})`
            }}>
              <Switch>
                <Route path="/auth/login" component={Login} />
                <Route path="/auth/registration" component={Registration} />
                <Route
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
