


if (window.location.hostname == 'localhost' || window.location.hostname =='dev46.hoja-crm.com') {
    window.HTTP_ROOT_PATH = 'https://murad.electrajo.com/API/';
} else {
    // need check online domain
    window.HTTP_ROOT_PATH = 'https://' + window.location.hostname + '/API/'
}


window.API_URL_DATA = window.HTTP_ROOT_PATH+"data.php";
// window.API_URL_USERS = window.HTTP_ROOT_PATH+"users.php";

