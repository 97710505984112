import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { Link } from 'react-router-dom';
// import Driver from '../configuration/driver/driver';
// import Areas from '../configuration/areas/areas';
// import School from '../configuration/school/school'
// import Customers from '../customers/customers';
// import Departments from '../departments/departments'
// import ShowMembersDashboard from '../subDepartment/showMembersDashboard'



class DashboardCounts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            schools: 0,
            areas: 0,
            drivers: 0,
            department: 0,
            members: 0,
            Allvoters: 0,
            voted: 0,
            NotVoted: 0,
            openRightModal: false,
            page: '',
            headerTitle: ''
        }
    }

    componentWillMount() {
        this.getAllcount()
    }

    getAllcount() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllcount',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    schools: data['schools'],
                    areas: data['AllvotersFull'],
                    drivers: data['AllNewVoters'],
                    department: data['department'],
                    members: data['members'],
                    Allvoters: data['Allvoters'],
                    voted: data['voted'],
                    NotVoted: data['NotVoted'],
                });
            }
        });
    }


    viewMore = (page, headerTitle) => {
        this.setState({ openRightModal: true, page: page, headerTitle: headerTitle })
    }

    handleCloseSideBar = () => {
        this.setState({ openRightModal: false, page: '', headerTitle: '' })
    }

    render() {
        return (
            <div>
                <Row>
                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': 'rgb(0, 137, 255) 3px 3px 4px 1px' }}>
                            <Link to="/departments">
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': '#0089ff' }}>{this.state.department}</div>
                                        <div className='dashboard-all-count-main-title first-dashboard-count'>مجموع المراكز الإنتخابية</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                        <i className="far fa-building"></i>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>

                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': '#993d63 3px 3px 4px 1px' }}>
                            <Link to="/reports/8">
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': '#993d63' }}>{this.state.members}</div>
                                        <div className='dashboard-all-count-main-title'>مجموع الأعضاء</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                        <i className="fas fa-user-tie"></i>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>

                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': '#217a21 3px 3px 4px 1px' }}>
                            <Link to="/school">
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': '#217a21' }}>{this.state.schools}</div>
                                        <div className='dashboard-all-count-main-title'>مجموع المدارس</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                        <i className="fas fa-school"></i>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>

                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': '#ff9900 3px 3px 4px 1px' }}>
                            {/* <Link to="/areas"> */}
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': '#ff9900' }}>{this.state.areas}</div>
                                        <div className='dashboard-all-count-main-title'>مجموع كل المصوتين</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                    <i className="fas fa-user-tie"></i>
                                    </Col>
                                </Row>
                            {/* </Link> */}
                        </div>
                    </Col>

                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': '#19adad 3px 3px 4px 1px' }}>
                            <Link to="/customers/all">
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': '#19adad' }}>{this.state.Allvoters}</div>
                                        <div className='dashboard-all-count-main-title'> مجموع الناخبين الكلي</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                        <i className="fas fa-users"></i>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>

                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': '#be10b0 3px 3px 4px 1px' }}>
                            <Link to="/customers/voted">
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': '#be10b0' }}>{this.state.voted}</div>
                                        <div className='dashboard-all-count-main-title'>مجموع المنتخبين</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                        <i className="fas fa-user-check"></i>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>

                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': 'rgb(84 88 138) 3px 3px 4px 1px' }}>
                            <Link to="/customers/notVoted">
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': 'rgb(84 88 138)' }}>{this.state.NotVoted}</div>
                                        <div className='dashboard-all-count-main-title'>مجموع الغير منتخبين</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                        <i className="fas fa-user-times"></i>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>

                    <Col md={6} lg={3}>
                        <div className='dashboard-all-count-box-view' style={{ 'boxShadow': '#9d7272 3px 3px 4px 1px' }}>
                            <Link to="/driver">
                                <Row>
                                    <Col md={9}>
                                        <div className='dashboard-all-count-main-number' style={{ 'color': '#9d7272' }}>{this.state.drivers}</div>
                                        <div className='dashboard-all-count-main-title'>مجموع المصوتين الجدد</div>
                                    </Col>
                                    <Col md={3} className='dashboard-all-count-main-icon'>
                                    <i className="fas fa-user-tie"></i>
                                    </Col>
                                </Row>
                            </Link>
                        </div>
                    </Col>

                </Row>


                <Drawer anchor={'left'} open={this.state.openRightModal} className='sidebar-modal-view'>
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'>{this.state.headerTitle}</div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn  mr-5' onClick={this.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>

                    {/* {this.state.page == 'drivers' && <Driver dashboardCounts={1} />} */}
                    {/* {this.state.page == 'areas' && <Areas dashboardCounts={1} />} */}
                    {/* {this.state.page == 'schools' && <School dashboardCounts={1} />} */}
                    {/* {this.state.page == 'NotVoted' && <Customers dashboardCounts={1} voted={-1} canVote={1} hideColorCode={1} />} */}
                    {/* {this.state.page == 'voted' && <Customers dashboardCounts={1} voted={1} canVote={1} hideColorCode={1} />} */}
                    {/* {this.state.page == 'Allvoters' && <Customers dashboardCounts={1} canVote={1} hideColorCode={1} />} */}
                    {/* {this.state.page == 'departement' && <Departments dashboardCounts={1} />} */}
                    {/* {this.state.page == 'members' && <ShowMembersDashboard dashboardCounts={1} />} */}

                </Drawer>
            </div>
        )
    }
}
export default DashboardCounts;