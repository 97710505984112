import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Progress } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class ImportCustomerForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressUploadFile: 0,
            displayErrorFileMsg: false,
            excelRow: 6,
            data: [],//errors
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleValidSubmit(event) {
        var data = new FormData();
        var importCustomersData = document.querySelector('input[name=importCustomers]').files[0];
        // console.log(importCustomersData);

        if (importCustomersData != undefined && importCustomersData != null && importCustomersData != '') {
            data.append("data", importCustomersData);
            data.append("name", 'data');
            // data.append("type", 'importCustomers');
            data.append("countrow", this.state.excelRow);
            data.append("userId", localStorage.getItem('USER_ID'));
            data.append("token", localStorage.getItem('USER_TOKEN'));
            fetch(window.API_URL_DATA, {
                method: "POST",
                body: data,
                headers: {
                    Accept: 'application/json',
                },
            }).then(function (response) {
                if (!response.ok) {
                }
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.props.handleImportAction();
                    }
                }
            });
        } else {
            this.setState({ displayErrorFileMsg: true, progressUploadFile: 0 });
        }
    }

    handleFileChange = (event) => {
        var fileReader = new FileReader();
        const scope = this
        if (event.target.files[0].type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            this.setState({ displayErrorFileMsg: false });
            this.setState({ progressUploadFile: 10 });
            fileReader.readAsBinaryString(event.target.files[0]);
            fileReader.onprogress = function (data) {
                if (data.lengthComputable) {
                    var progress = parseInt(((data.loaded / data.total) * 100), 10);
                    scope.setState({ progressUploadFile: progress });
                }
            }
        } else {
            this.setState({ displayErrorFileMsg: true, progressUploadFile: 0 });
        }
        this.setState({
            importCustomers: event.target.files[0]['name']
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value,
        })
    }
    render() {
        return (
            <div>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'>استيراد
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn mr-5' onClick={this.props.handleCloseImportSideBar}></i>
                            </Col>
                        </Row>
                    </div>
                    <div className='sidebar-body-view'>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <div className='mb-3'>
                                <label>قائمة الناخبين</label>
                                <span className={'fileInput-span col-md-12 mb-3'}>
                                    <i className="fas fa-file-upload"></i> <span className='pointer-cursor task-action-form-fields-font'>تحميل</span>
                                    <input type="file" className='fileInput-width fileInput-opacity' onChange={(e) => this.handleFileChange(e)} name='importCustomers'></input>
                                </span>
                                {(this.state.progressUploadFile != undefined && this.state.progressUploadFile > 0) &&
                                    <Progress striped color="success" value={this.state.progressUploadFile} className='mb-1 mt-1'>{this.state.progressUploadFile} %</Progress>
                                }
                                {(this.state.displayErrorFileMsg == true) &&
                                    <div className='invalid-feedback d-block mb-1 mt-n3'>قم بتحميل ملف excel</div>
                                }
                                <AvField name="excelRow" type='number' label='رقم سطر بداية القراءة من الملف' placeholder='ادخل رقم سطر بداية القراءة من الملف' value={this.state.excelRow} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                            </div>
                            <div className="text-left">
                                <button type="submit" className="btn form-save-btn mr-1 ml-1 mr-2"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseImportSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> إلغاء</button>
                            </div>
                        </AvForm>
                    </div>
                </div>
            </div>
        )
    }
}
export default ImportCustomerForm;