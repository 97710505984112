import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Label } from "reactstrap";
import PhoneInput from "react-phone-number-input";
// import SmartInput from 'react-phone-number-input/smart-input'
import "../../../../node_modules/react-phone-number-input/style.css";
// import SmartInput from 'smart-input-react';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckbox,
  AvCheckboxGroup,
  AvRadioGroup,
  AvRadio,
} from "availity-reactstrap-validation";
import Picky from "react-picky";
import "react-picky/dist/picky.css";

class UsersForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userData["userId"],
      fname: this.props.userData["fname"],
      lname: this.props.userData["lname"],
      phone: this.props.userData["phone"],
      userEmail: this.props.userData["userEmail"],
      idNumber: this.props.userData["idNumber"],
      level: this.props.userData["admin"],
      password: "",
      confirmPassword: "",
      checkConfirmPassword: false,
      phoneRequired: false,
      submitFaild: "",
      allDepartments: [],
      multiValue:
        this.props.userData.employPermission?.length > 0
          ? this.props.userData.employPermission
          : [],
      levelRequired: false,
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }
  componentDidMount = () => {
    this.getAllDepartments();
  };
  handleValidSubmit(event) {
    this.setState({
      checkConfirmPassword: false,
      phoneRequired: false,
      submitFaild: "",
    });

    var type = this.props.status == "add" ? "addUser" : "editUser";
    var passwordMatch = 1;
    var phoneHaveValue = 0;

    if (
      this.state.password != "" &&
      this.state.password != this.state.confirmPassword
    ) {
      passwordMatch = 0;
    }

    if (
      this.state.phone != "" &&
      this.state.phone != undefined &&
      this.state.phone != undefined
    ) {
      if (this.state.phone.toString().length == 13) {
        if (this.state.phone.startsWith("+9627")) {
          phoneHaveValue = 1;
        }
      }
    }
    // if(this.state.level==2 && this.state.multiValue?.length<1){
    //   this.setState({levelRequired:true})
    //   return
    // }
    if (passwordMatch == 1 && phoneHaveValue == 1) {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: type,
          fname: this.state.fname,
          lname: this.state.lname,
          emailAdress: this.state.userEmail,
          phone: this.state.phone,
          level: this.state.level,
          password: this.state.password,
          idNumber: this.state.idNumber,
          editUserId: this.state.userId,
          employPermission:
            this.state.level == 2 ? this.state.multiValue : undefined,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            if (data["resNo"] == 2 || data["resNo"] == 1) {
              this.setState({ submitFaild: data["resMsg"] });
            } else {
              this.props.handleFormAction(this.props.status);
            }
          }
        });
    } else {
      if (passwordMatch == 0) {
        this.setState({ checkConfirmPassword: true });
      }

      if (phoneHaveValue == 0) {
        this.setState({ phoneRequired: true });
      }
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
      checkConfirmPassword: false,
      phoneRequired: false,
      submitFaild: "",
    });
  };

  handleInputChangePhone(event, fieldName) {
    var name = fieldName;
    var value = event;

    this.setState((prevState) => ({
      phone: name,
      phoneRequired: false,
      submitFaild: "",
    }));
  }

  validateIdNumber = (value, ctx, input, cb) => {
    if (value.toString().length < 10) {
      return false;
    } else {
      return true;
    }
  };
  validateIdNumberLengh = (event, ctx, input, cb) => {
    const target = event.target;
    const value = target.value;
    if (value.toString().length == 10) {
      event.preventDefault();
    }
  };
  getAllDepartments() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllDepartment",
        getAll: "1",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          let allDepartments = [...this.state.allDepartments];
          for (const key in data) {
            if (data[key]["parent"] == "0") {
              allDepartments.push({
                id: data[key]["id"],
                name: data[key]["name"],
              });
            } else {
            }
          }
          this.setState({
            allDepartments: allDepartments,
          });
        }
      });
  }
  handleMultiChange = (option) => {
    this.setState({ multiValue: option });
  };
  render() {
    return (
      <div>
        <div className="test-modal-style">
          <div className="sidebar-header-view">
            <Row>
              <Col md={11}>
                <div className="p-0 main-color-1 font-weight-500 sidebar-header-title">
                  {this.props.status == "add" ? "إضافة" : "تعديل"} مستخدم
                </div>
              </Col>
              <Col md={1} className="pr-5">
                <i
                  className="p-0 flaticon2-delete sidebar-header-close-btn mr-5"
                  onClick={this.props.handleCloseSideBar}
                ></i>
              </Col>
            </Row>
          </div>

          <div className="sidebar-body-view">
            <AvForm
              onValidSubmit={this.handleValidSubmit}
              ref={(c) => (this.form = c)}
            >
              <Row>
                <Col md={6} className="pl-5 pr-5">
                  <AvField
                    name="fname"
                    label="الاسم الأول *"
                    placeholder="أدخل الاسم الأول"
                    value={this.state.fname}
                    required
                    onChange={this.handleInputChange}
                    errorMessage="الرجاء ادخال قيمة صحيحة"
                  />
                  <AvField
                    name="lname"
                    label="اسم العائلة *"
                    placeholder="أدخل اسم العائلة"
                    value={this.state.lname}
                    required
                    onChange={this.handleInputChange}
                    errorMessage="الرجاء ادخال قيمة صحيحة"
                  />
                  <AvField
                    // validate={{ async: this.validateIdNumber }} onKeyPress={this.validateIdNumberLengh.bind(this)}
                    name="idNumber"
                    disabled={this.props.status == "add" ? false : true}
                    type="number"
                    label="الرقم الوطني *"
                    placeholder="أدخل الرقم الوطني"
                    value={this.state.idNumber}
                    required={false}
                    onChange={this.handleInputChange}
                    errorMessage="الرجاء ادخال قيمة صحيحة"
                  />
                  <div
                    className={
                      this.state.phoneRequired == true
                        ? "custom-form-phone-view phoneErrorStyle"
                        : "custom-form-phone-view"
                    }
                  >
                    <label>الهاتف *</label>
                    <PhoneInput
                      maxLength="16"
                      defaultCountry="JO"
                      international
                      placeholder="أدخل رقم الهاتف"
                      name={"phone"}
                      value={this.state.phone}
                      onChange={this.handleInputChangePhone.bind(this, "phone")}
                      className="mt-1 mb-4 phone"
                      required
                    />
                    {this.state.phoneRequired == true && (
                      <div className="phone-error-msg">
                        الرجاء ادخال قيمة صحيحة
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={6} className="pl-5 pr-5">
                  <AvField
                    type="select"
                    name="level"
                    label="تصنيف المستخدم"
                    value={this.state.level}
                    required
                    onChange={this.handleInputChange}
                    className="p-1 pl-3"
                    errorMessage="الرجاء ادخال قيمة صحيحة"
                  >
                    <option
                      style={{
                        display: "none",
                      }}
                      value=""
                      selected="selected"
                    >
                      اختر تصنيف المستخدم
                    </option>
                    {/* <option value={5}>مسؤول التقارير المالية</option> */}
                    <option value={3}>مسؤول رئيسي</option>
                    <option value={1}>مسؤول</option>
                    <option value={2}>موظف</option>
                  </AvField>

                  <AvField
                    name="userEmail"
                    autoComplete="new-password"
                    type="email"
                    label="البريد الإلكتروني *"
                    placeholder="أدخل البريد الإلكتروني"
                    value={this.state.userEmail}
                    required
                    onChange={this.handleInputChange}
                    errorMessage="الرجاء ادخال قيمة صحيحة"
                  />
                  <AvField
                    name="password"
                    autoComplete="new-password"
                    type="password"
                    label={
                      this.props.status == "add"
                        ? "كلمة المرور *"
                        : "كلمة المرور"
                    }
                    placeholder="أدخل كلمة المرور"
                    value={this.state.password}
                    required={this.props.status == "add" ? true : false}
                    onChange={this.handleInputChange}
                    errorMessage="الرجاء ادخال قيمة صحيحة"
                  />
                  <AvField
                    name="confirmPassword"
                    autoComplete="new-password"
                    type="password"
                    label={
                      this.state.password != ""
                        ? "تأكيد كلمة المرور *"
                        : "تأكيد كلمة المرور"
                    }
                    placeholder="تأكيد كلمة المرور"
                    value={this.state.confirmPassword}
                    required={this.state.password != "" ? true : false}
                    onChange={this.handleInputChange}
                    errorMessage="الرجاء ادخال قيمة صحيحة"
                  />
                  {this.state.checkConfirmPassword == true && (
                    <div className="login-error-msg mb-3">
                      كلمة السر غير متطابقة
                    </div>
                  )}
                  {this.state.level == 2 && (
                    <div
                      className={
                        this.state.levelRequired == true
                          ? "mb-3 memberInputRequired"
                          : "mb-3"
                      }
                    >
                      <label>المراكز : </label>
                      <div className="member-searchable-list">
                        <Picky
                          value={this.state.multiValue}
                          options={this.state.allDepartments}
                          onChange={this.handleMultiChange}
                          open={false}
                          valueKey="id"
                          labelKey="name"
                          multiple={true}
                          includeSelectAll={true}
                          includeFilter={true}
                          dropdownHeight={200}
                          selectAllText="اختر جميع المراكز"
                          placeholder="اختر"
                          filterPlaceholder="اختر المركز"
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="pl-5 pr-5">
                  {this.state.submitFaild != "" && (
                    <div className="login-error-msg mb-3">
                      {this.state.submitFaild}
                    </div>
                  )}
                  <div className="text-left">
                    <button
                      type="submit"
                      className="btn form-save-btn mr-1 ml-1"
                    >
                      <i className="flaticon2-check-mark form-save-btn-icon"></i>{" "}
                      حفظ{" "}
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger waves-effect waves-light m-l-10"
                      onClick={this.props.handleCloseSideBar}
                    >
                      <i className="flaticon2-delete form-cancel-btn-icon"></i>{" "}
                      إلغاء
                    </button>
                  </div>
                </Col>
              </Row>
            </AvForm>
          </div>
        </div>
      </div>
    );
  }
}

export default UsersForm;
