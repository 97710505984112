import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class CommingSoon extends Component {
    render() {
        return (
            <div style={{
                'background': 'white',
                'textAlign': 'center'
            }}>
                <div style={{
                    'fontSize': '30px',
                    'paddingTop': '6%'
                }} className='main-color-1'>الصفحة قيد الإنشاء ...</div>
                <img src='/media/electra/image_processing20190911-12520-39kc0d.gif' />

            </div>
        )
    }
}
export default CommingSoon;