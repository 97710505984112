import React, { Component } from "react";
import { Row, Col } from 'reactstrap';
import DashboardAreaCustomers from "./dashboardAreaCustomers"
import DashboardCounts from "./dashboardCounts"
import DashboardDepartement from "./dashboardDepartement"

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewDashboard: false
    }
  }

  componentWillMount() {

    if (localStorage.getItem('USER_LEVEL') == 3) {
      this.setState({ viewDashboard: true })
    } else {
      if (localStorage.getItem('permations') != undefined && localStorage.getItem('permations') != '' && localStorage.getItem('permations') != null && Object.keys(localStorage.getItem('permations')).length > 0) {
        var permations = JSON.parse(localStorage.getItem('permations'))
        // console.log(permations);
        if (permations != undefined && permations != null && permations != '') {
          // console.log(permations[5]);
          if (permations['dashboardData'] != undefined && permations['dashboardData'] != null && permations['dashboardData'] != '' && permations['dashboardData']['1_1'] != undefined && permations['dashboardData']['1_1'] != null && permations['dashboardData']['1_1'] != '') {
            permations = permations['dashboardData']['1_1']
            this.setState({ viewDashboard: permations })
          }

        }

      }
    }
  }

  render() {
    return (
      <>
        <div className='main-dashboard-page'>
          {this.state.viewDashboard == true &&
            <Row style={{ 'marginBottom': '7%' }}>
              <Col md={12} >
                <DashboardCounts />
              </Col>
            </Row>
          }
          {this.state.viewDashboard == true &&
            <Row>
              <Col lg={7} md={12} className="mb-3">
                <div className='main-dashboard-page-chart-view'>
                  <DashboardAreaCustomers />
                </div>
              </Col>
              <Col lg={5} md={12} className='mb-3'>
                <div className='main-dashboard-page-chart-view'>
                  <DashboardDepartement />
                </div>
              </Col>
            </Row>
          }

          {this.state.viewDashboard == false &&
            <Row>
              <Col md={12}>
                <p className='dashboard-no-permission'>Electra</p>
              </Col>
            </Row>
          }

        </div>
      </>
    );
  }
}
export default Dashboard;
