import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
// import SmartInput from 'smart-input-react';
import '../../../../../node_modules/react-phone-number-input/style.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class schoolForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.departmentData['id'],
            phone: this.props.departmentData['small_desc'],
            section: this.props.departmentData['section'],
            name: this.props.departmentData['name'],
            description: this.props.departmentData['description'],
            phoneRequired: false,
            submitFaild: '',
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    componentWillMount() {

    }
    handleValidSubmit(event) {
        this.setState({ phoneRequired: false, submitFaild: '' })
        var phoneHaveValue = 0
        var type = this.props.status == 'add' ? 'addDataToPost' : 'editDataInPost';
        if (this.state.phone != '' && this.state.phone != undefined && this.state.phone != undefined) {
            if (this.state.phone.toString().length == 13) {
                if (this.state.phone.startsWith("+9627")) {
                    phoneHaveValue = 1
                }

            }

        }
        if (phoneHaveValue == 1) {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': type,
                    'title': this.state.name,
                    'catId': 1,
                    'lang': 1,
                    'id': this.state.id,
                    'section': this.state.section,
                    'smallDesc': this.state.phone,
                    'desc': this.state.description,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    this.props.handleFormAction(this.props.status);
                }
            });
        } else {
            if (phoneHaveValue == 0) {
                this.setState({ phoneRequired: true })
            }

        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            phoneRequired: false,
            submitFaild: ''
        })
    }
    handleInputChangePhone(event, fieldName) {
        var name = fieldName
        var value = event;

        this.setState(prevState => ({
            phone: name,
            phoneRequired: false,
            submitFaild: ''
        }
        ))
    }
    render() {
        return (
            <div>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'> نموذج {this.props.status == 'add' ? 'الإضافة' : 'التعديل'}
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn  mr-5' onClick={this.props.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>

                    <div className='sidebar-body-view'>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <Row>
                                <Col md={6} className='pl-5 pr-5'>
                                    <AvField name="name" label='* اسم المدرسة' placeholder='ادخل اسم المدرسة' value={this.state.name} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField name="description" label='* عنوان المدرسة' placeholder='ادخل عنوان المدرسة' value={this.state.description} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                </Col>
                                <Col md={6} className='pl-5 pr-5'>
                                    <div className={this.state.phoneRequired == true ? 'custom-form-phone-view phoneErrorStyle' : 'custom-form-phone-view'}>
                                        <label>* رقم هاتف المندوب</label>
                                        <PhoneInput
                                            maxLength="16"
                                            defaultCountry="JO"
                                            international
                                            placeholder="ادخل رقم هاتف المندوب"
                                            // inputComponent={SmartInput}
                                            name={'phone'}
                                            value={this.state.phone}
                                            onChange={this.handleInputChangePhone.bind(this, 'phone')}
                                            className='mt-1 mb-4 phone'
                                            required
                                        />
                                        {this.state.phoneRequired == true && <div className="phone-error-msg">الرجاء ادخال قيمة صحيحة</div>}
                                    </div>
                                    <AvField type="number" name="section" label="* رقم المدرسة الإنتخابي" placeholder="ادخل رقم المدرسة الإنتخابي" value={this.state.section} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='pl-5 pr-5'>
                                    <div className="text-left">
                                        <button type="submit" className="btn form-save-btn mr-1 ml-1"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                        <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> الغاء</button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </div>
            </div>
        )
    }

}
export default schoolForm;