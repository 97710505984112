import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';


class Reports2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            limit: 10,
            dataCount: 0,
            openMenu: -1,
            openCheckedAction: false,
            checkedRow: [],
            sortColName: 'id',
            selectedSortType: [],
            data: [],
            loading: 1,
            departmentData: [],
            selectAtLeastOneFlag: 0,
            actionLoading: 0,
            openleftModal: false,
            sortType: '',
            allAdminData: [],
            searchTitle: '',
        }
    }

    componentWillMount() {
        this.getDepartmentCount();
        this.getDepartmentLimit();
    }

    getDepartmentLimit() {
        this.setState({ loading: 1 });
        var sortColName = (this.state.sortColName == 'customerCount' || this.state.sortColName == 'votedTotal' || this.state.sortColName == 'notVotedTotal') ? this.state.sortColName : 'a.' + this.state.sortColName
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllSchoolsAndCustomerCount',
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': sortColName,
                'getType': 'limit',
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    data: data,
                    loading: 0
                });

            }
        });
    }
    getDepartmentCount() {
        var sortColName = (this.state.sortColName == 'customerCount' || this.state.sortColName == 'votedTotal' || this.state.sortColName == 'notVotedTotal') ? this.state.sortColName : 'a.' + this.state.sortColName
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllSchoolsAndCustomerCount',
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': sortColName,
                'getType': 'count',
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    dataCount: data[0] != undefined ? data[0]['counter'] : [],
                });

            }
        });
    }
    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }
    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getDepartmentLimit();
        });
    }
    handleChangeLimit = (event) => {
        this.setState({ limit: event.target.value, activePage: 1 }, function () {

        });
    }
    sortCoulmn = (colNum) => {
        this.setState({ sortColName: colNum }, function () {
            if ((this.state.selectedSortType[colNum] == '' || this.state.selectedSortType[colNum] == undefined || this.state.selectedSortType[colNum] == null) && this.state.selectedSortType[colNum] != 0) {
                var sortVal = 0 // desc
            } else if (this.state.selectedSortType[colNum] == 1) {
                var sortVal = 0 // desc
            } else {
                var sortVal = 1 // asc
            }
            const newArray = this.state.selectedSortType;
            newArray[colNum] = sortVal;
            let sort = sortVal == 0 ? 'desc' : 'asc';
            this.setState({ selectedSortType: newArray, sortType: sort }, function () {
                this.getDepartmentLimit();
            });
        });
    }
    returnRowData = () => {
        var result = []
        if (this.state.loading == 0 && this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {
                result.push(
                    <Col md={12} key={index} id={rowData.id} className='row-data-view'>
                        <Row className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('table-body-row table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{index + 1}</div>
                            </Col>
                            <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3 ">{rowData['schoolName'] == '' ? '-' : rowData['schoolName']}</div>
                            </Col>
                            <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{rowData['customerCount'] == '' ? '-' : rowData['customerCount']}</div>
                            </Col>
                            <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{rowData['votedTotal'] == '' ? '-' : rowData['votedTotal']}</div>
                            </Col>
                            <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{rowData['notVotedTotal'] == '' ? '-' : rowData['notVotedTotal']}</div>
                            </Col>
                        </Row>
                    </Col>
                )
            })
        }
        return result
    }
    export = (event, type) => {
        event.preventDefault();
        var sortColName = (this.state.sortColName == 'customerCount' || this.state.sortColName == 'votedTotal' || this.state.sortColName == 'notVotedTotal') ? this.state.sortColName : 'a.' + this.state.sortColName
        if (type == 'pdf') {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getAllSchoolsAndCustomerCount',
                    'export': 1,
                    'sort': sortColName,
                    'sortType': this.state.sortType,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'pdf': 1
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }
                }
            });
        } else {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getAllSchoolsAndCustomerCount',
                    'export': 1,
                    'sort': sortColName,
                    'sortType': this.state.sortType,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }
                }
            });
        }
    }
    reportAlert = (event, text) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>{text}</p>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={onClose}> اغلاق </Button>
                    </div>

                );
            }
        });
    }
    render() {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            <Row>
                                <Col md={4}>
                                    <AvForm className="form-inline">
                                        <Col md={12}>
                                        </Col>
                                    </AvForm>
                                </Col>
                                <Col md={8} className='float-right text-right'>
                                    <div>
                                        {(this.props.allPermission == 1 || this.props.excelPermission == 1) &&
                                            <Button variant="contained" className='export-btn ml-2' style={{ fontSize: 14 }} onClick={(event) => this.export(event, 'excel')}> تصدير &nbsp;&nbsp;<i class="fas fa-file-download"></i></Button>
                                        }
                                        {(this.props.allPermission == 1 || this.props.pdfPermission == 1) &&
                                            <Button variant="contained" className='pdf-btn' style={{ fontSize: 14 }} onClick={(event) => this.export(event, 'pdf')}> Pdf &nbsp;&nbsp;<i class="far fa-file-pdf"></i></Button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            {this.state.loading == 1 &&
                                <div className="data-loading-spinner">Loading...</div>
                            }
                            {(this.state.loading == 0 && this.state.data.length <= 0) &&
                                <Row>
                                    <Col md={12} className='text-center no-data-available-view'>
                                        <img src='/media/electra/no-data-icon-68.png' />
                                        <p>لا يوجد بيانات لعرضها .&nbsp;.&nbsp; !</p>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={12} className='pl-0 text-right float-right'>
                                        <div className='d-inline-flex mb-2'>
                                            <div className='table-display-limit-text-1 mr-2 ml-2'> عرض</div>
                                            <AvForm className="form-inline table-display-limit-select">
                                                <AvField type="select" name="limit" value={this.state.limit} onChange={this.handleChangeLimit}>
                                                    <option value="10" >10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </AvField>
                                            </AvForm>
                                            <div className='table-display-limit-text-2 mr-2 ml-2'> مدخلات </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={12}>
                                        <Row className='table-header-row'>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    التسلسل
                                                </div>
                                            </Col>
                                            <Col md={3} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('name')}>مركز الاقتراع{this.state.sortColName == 'name' ? (this.state.selectedSortType['name'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={3} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('customerCount')}>عدد الناخبين{this.state.sortColName == 'customerCount' ? (this.state.selectedSortType['customerCount'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('votedTotal')}>عدد المصوتيين{this.state.sortColName == 'votedTotal' ? (this.state.selectedSortType['votedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('notVotedTotal')}>عدد الغير مصوتيين{this.state.sortColName == 'notVotedTotal' ? (this.state.selectedSortType['notVotedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} className='table-body-scroll table-body-row-view'>
                                        {this.returnRowData()}
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={6} className='pagination-style float-left text-left'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className="flaticon2-right-arrow"></i>}
                                            nextPageText={<i className="flaticon2-left-arrow"></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <div className='mt-2 float-right text-right'>
                                            عرض {this.state.activePage != 1 ? this.state.activePage + ((this.state.limit * (this.state.activePage - 1)) - this.state.activePage + 1) : this.state.activePage} الى {(this.state.activePage * this.state.limit) > this.state.dataCount ? this.state.dataCount : this.state.activePage * this.state.limit} من {this.state.dataCount} مدخلات
                                    </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Reports2;