import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class areasForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.departmentData['id'],
            name: this.props.departmentData['name'],
            parentId: this.props.parentId,
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    componentWillMount() {

    }
    handleValidSubmit(event) {
        var type = this.props.status == 'add' ? 'addDataToPost' : 'editDataInPost';
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': type,
                'title': this.state.name,
                'catId': 3,
                'lang': 1,
                'parent': this.state.parentId,
                'id': this.state.id,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.props.handleFormAction(this.props.status);
            }
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
        })
    }
    render() {
        return (
            <div>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'> نموذج {this.props.status == 'add' ? 'الإضافة' : 'التعديل'}
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn mr-5' onClick={this.props.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>

                    <div className='sidebar-body-view'>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <AvField name="name" label={this.state.parentId == 0 || this.state.parentId == undefined ? 'اسم المنطقة *' : 'اسم المعلم البارز *'} placeholder={this.state.parentId == 0 || this.state.parentId == undefined ? 'ادخل اسم المنطقة' : 'ادخل اسم المعلم البارز'} value={this.state.name} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                            <div className="text-left">
                                <button type="submit" className="btn form-save-btn mr-1 ml-1"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> الغاء</button>
                            </div>

                        </AvForm>
                    </div>
                </div>
            </div>
        )
    }

}
export default areasForm;