import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

class SubSettings extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            multiValue: (Object.keys(this.props.subSettingMultiValue).length > 0 ? this.props.subSettingMultiValue : []),
            reportActionList: (Object.keys(this.props.subSettingData).length > 0 ? this.props.subSettingData : []),
            allSubSettingData: [
                {
                    'id': '1',
                    'name': 'المدارس'
                },
                {
                    'id': '2',
                    'name': 'السائقيين'
                },
                {
                    'id': '3',
                    'name': 'المناطق والمعالم البارزة'
                },
            ]
        }
    }

    handleMultiChange = (option) => {
        this.setState(state => {
            return {
                multiValue: option
            };
        },()=>{
            this.props.handleGetTabData(2,this.state.reportActionList,this.state.multiValue)

        });
    }

    checkRow = (event, reportId) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const newArray = this.state.reportActionList
        var nameElement = name
        newArray[reportId + '_' + nameElement] = value;



        this.setState({ reportActionList: newArray },function(){
            this.props.handleGetTabData(2,this.state.reportActionList,this.state.multiValue)
        });

    }

    setReportActionRadio() {
        var reportActionRadioHTML = "";
        if (this.state.multiValue != undefined && this.state.multiValue != null && this.state.multiValue.length > 0) {
            reportActionRadioHTML = this.state.multiValue.map((multiValueData, index) => {
                return (
                    <Row key={index} className='text-left mt-1 p-2 pl-5 font-weight-500 main-color-1'>
                        <Col md={5}>
                            <Label>- {multiValueData.name}</Label>
                        </Col>
                        <Col md={7}>
                            <Row style={{ 'color': 'black' }}>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-1-' + multiValueData.id} checked={this.state.reportActionList[multiValueData.id + '_1']} name={'1'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-1-' + multiValueData.id}>&nbsp;</label>
                                        <span>كل شيئ</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-2-' + multiValueData.id} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={(this.state.reportActionList[multiValueData.id + '_2'] || this.state.reportActionList[multiValueData.id + '_1'])} name={'2'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-2-' + multiValueData.id}>&nbsp;</label>
                                        <span>عرض</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox p-0">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-3-' + multiValueData.id} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={(this.state.reportActionList[multiValueData.id + '_3'] || this.state.reportActionList[multiValueData.id + '_1'])} name={'3'} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-3-' + multiValueData.id}>&nbsp;</label>
                                        <span>تعديل / اضافة</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={'reportAction-4-' + multiValueData.id} name={'4'} disabled={this.state.reportActionList[multiValueData.id + '_1']} checked={(this.state.reportActionList[multiValueData.id + '_4'] || this.state.reportActionList[multiValueData.id + '_1'])} onClick={(event) => this.checkRow(event, multiValueData.id)} />
                                        <label className="custom-control-label" htmlFor={'reportAction-4-' + multiValueData.id}>&nbsp;</label>
                                        <span>حذف</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            })
        }
        return reportActionRadioHTML
    }

    render() {

        console.log('setting',this.props);


        return (
            <div>
                <div className='mt-n5 mr-3 ml-3' style={{ minHeight: 355, maxHeight: 355, backgroundColor: '#ECECEF' }}>
                    <Row className='mt-5'>
                        <Col md={12}>
                            <div>
                                <Label className='text-left mt-3 p-3 pl-5 font-weight-500 main-color-1'>صفحات الإعدادات : </Label>
                                <div className='searchable-list'>
                                    <Picky
                                        value={this.state.multiValue}
                                        options={this.state.allSubSettingData}
                                        onChange={this.handleMultiChange}
                                        open={false}
                                        valueKey="id"
                                        labelKey="name"
                                        multiple={true}
                                        includeSelectAll={true}
                                        includeFilter={true}
                                        dropdownHeight={200}
                                        selectAllText='اختر جميع الصفحات'
                                        placeholder='اختر'
                                        filterPlaceholder='اختر الصفحة'
                                    />
                                </div>
                            </div>

                            {this.state.multiValue != undefined && this.state.multiValue != null && this.state.multiValue.length > 0 &&
                                <div className='mt-3 pt-2' style={{ minHeight: 238, maxHeight: 238, 'overflowX': 'hidden', 'overflowY': 'auto' }}>
                                    <div>
                                        {this.setReportActionRadio()}
                                    </div>
                                </div>
                            }
                        </Col>

                    </Row>
                </div>
            </div>
        )
    }
}

export default SubSettings;