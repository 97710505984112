import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Redirect, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

class Reports8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            limit: 10,
            dataCount: 0,
            sortColNum: '',
            selectedSortType: [],
            data: [],
            loading: 1,
            sortType: '',
            allAdminData: [],
            selectReportType: '',
            parentDepartments: [],
        }
    }
    componentWillMount() {
        this.getAllAdminData();
    }
    getParentDepartments() {
        this.setState({
            loading: 0
        })
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getParentDepartments',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    parentDepartments: data,

                });

            }
        });
    }
    getAllAdminData() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getUsers',
                'getType': 'all',
                'adminType': -1,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    allAdminData: data
                }, function () {
                    this.getParentDepartments();
                });

            }
        });
    }
    getParentDepartmentsOptions(data, index) {
        return (<option key={index} value={data.id}>{data.name}</option>);
    }
    getReportLimit() {
        this.setState({ loading: 1 });
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllSubDepartmentForMembers',
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': this.state.sortColNum,
                'getType': '',
                'stationId': this.state.selectReportType,
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    data: data,
                    loading: 0,
                });

            }
        });
    }
    getReportCount() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllSubDepartmentForMembers',
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': this.state.sortColNum,
                'getType': 'count',
                'stationId': this.state.selectReportType,
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    dataCount: data[0]['counter'],
                });

            }
        });
    }
    handleChangeLimit = (event) => {
        this.setState({ limit: event.target.value, activePage: 1 }, function () {
            this.getReportLimit();
            this.getReportCount();
        });
    }
    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }

    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }
    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getReportLimit();
            this.getReportCount();
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            activePage: 1
        }, function () {
            this.getReportLimit();
            this.getReportCount();
        })
    }
    sortCoulmn = (colNum) => {
        this.setState({ sortColNum: colNum }, function () {
            if ((this.state.selectedSortType[colNum] == '' || this.state.selectedSortType[colNum] == undefined || this.state.selectedSortType[colNum] == null) && this.state.selectedSortType[colNum] != 0) {
                var sortVal = 0 // desc
                var sortType = 'desc'
            } else if (this.state.selectedSortType[colNum] == 1) {
                var sortVal = 0 // desc
                var sortType = 'desc'
            } else {
                var sortVal = 1 // asc
                var sortType = 'asc'
            }
            const newArray = this.state.selectedSortType;
            newArray[colNum] = sortVal;
            this.setState({ selectedSortType: newArray, sortType: sortType }, function () {
                this.getReportLimit();
                this.getReportCount();
            });
        });
    }
    export = (event, type) => {
        event.preventDefault();
        if (type == 'pdf') {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getAllSubDepartmentForMembers',
                    'export': 1,
                    'sort': this.state.sortColNum,
                    'sortType': this.state.sortType,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'stationId': this.state.selectReportType,
                    'pdf': 1,
                    'page': 1,
                    'getType': 'all',
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }

                }
            });
        } else {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getAllSubDepartmentForMembers',
                    'export': 1,
                    'sort': this.state.sortColNum,
                    'sortType': this.state.sortType,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'stationId': this.state.selectReportType,
                    'page': 1,
                    'getType': 'all',
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }
                }
            });
        }
    }
    reportAlert = (event, text) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>{text}</p>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={onClose}> اغلاق </Button>
                    </div>

                );
            }
        });
    }
    getAdminNameById = (id) => {
        for (const key in this.state.allAdminData) {
            if (this.state.allAdminData[key]['userId'] == id) {
                return (this.state.allAdminData[key]['fname'] + ' ' + this.state.allAdminData[key]['lname']);
            }
        }
    }
    render() {
        var mainCol = []
        var dataCol = []
        var actionCol = []
        if (this.state.loading == 0 && this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {
                mainCol.push(
                    <div key={index} className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        <div title={rowData['keyword_SEO'] == '' ? '-' : rowData['keyword_SEO'] + ' ' + rowData['desc_SEO'] + ' ' + rowData['countries'] + ' ' + rowData['location']} className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + "overflow-text-length ml-0 main-color-1 col-12 col-sm-12 col-md-12 col-lg-12 table-body-row overflow-text-length "} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['keyword_SEO'] == '' ? '-' : rowData['keyword_SEO'] + ' ' + rowData['desc_SEO'] + ' ' + rowData['countries'] + ' ' + rowData['location']}
                        </div>
                    </div >
                )
                var checkEvenRow = index % 2;
                dataCol.push(
                    <div className='row flex-row flex-nowrap' key={index} >
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['votersTotal'] == undefined || rowData['votersTotal'] == '' ? '-' : rowData['votersTotal']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['votedTotal'] == undefined || rowData['votedTotal'] == '' ? '-' : rowData['votedTotal']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['notVotedTotal'] == undefined || rowData['notVotedTotal'] == '' ? '-' : rowData['notVotedTotal']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['description'] == '' || rowData['description'] == undefined ? '-' : rowData['description']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {rowData['small_desc'] == '' ? '-' : rowData['small_desc']}
                        </div>
                        {/* <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {(rowData['adminType'] == '' || rowData['adminType'] == 0) ? '-' : this.getAdminNameById(rowData['adminType'])}
                        </div> */}
                    </div>
                )
                actionCol.push(
                    <div className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-6 col-md-12 col-lg-12 table-body-row overflow-text-length action-btn-view')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['nameConcat'] != '' && rowData['nameConcat'] != undefined && rowData['nameConcat'] != null && rowData['nameConcat'] != '   ' ? rowData['nameConcat'] : '-'}
                        </div>
                        {/* <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-6 col-sm-3 col-md-6 col-lg-6 table-body-row overflow-text-length action-btn-view')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} >
                            {rowData['centerName'] == '' ? '-' : rowData['centerName']}
                        </div> */}
                    </div>
                )
            })
        }
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            <Row>
                                <Col md={4}>
                                    <AvForm className="form-inline">
                                        <Col md={12}>
                                            <Row className='mt-2'>
                                                <Col md={12}>
                                                    <AvField type="select" name="selectReportType" value={this.state.selectReportType} onChange={this.handleInputChange.bind(this)} className='form-input-width'>
                                                        <option style={{
                                                            'display': 'none'
                                                        }} value="">اختر المركز الرئيسي</option>
                                                        {this.state.parentDepartments.map((data, index) => this.getParentDepartmentsOptions(data, index))}
                                                    </AvField>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </AvForm>
                                </Col>
                                <Col md={8} className='float-right text-right'>
                                    {(this.props.allPermission == 1 || this.props.excelPermission == 1) &&
                                        <Button variant="contained" className='export-btn ml-2' style={{ fontSize: 14 }} onClick={(event) => this.export(event, 'excel')}> تصدير &nbsp;&nbsp;<i class="fas fa-file-download"></i></Button>
                                    }
                                    {(this.props.allPermission == 1 || this.props.pdfPermission == 1) &&
                                        <Button variant="contained" className='pdf-btn' style={{ fontSize: 14 }} onClick={(event) => this.export(event, 'pdf')}> Pdf &nbsp;&nbsp;<i class="far fa-file-pdf"></i></Button>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            {this.state.loading == 1 &&
                                <div className="data-loading-spinner">Loading...</div>
                            }
                            {(this.state.loading == 0 && this.state.data.length <= 0) &&
                                <Row>
                                    <Col md={12} className='text-center no-data-available-view'>
                                        <img src='/media/electra/no-data-icon-68.png' />
                                        <p>لا يوجد بيانات لعرضها .&nbsp;.&nbsp; !</p>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={12} className='pl-0 text-right float-right'>
                                        <div className='d-inline-flex mb-2'>
                                            <div className='table-display-limit-text-1 mr-2 ml-2'> عرض</div>
                                            <AvForm className="form-inline table-display-limit-select">
                                                <AvField type="select" name="limit" value={this.state.limit} onChange={this.handleChangeLimit}>
                                                    <option value="10" >10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </AvField>
                                            </AvForm>
                                            <div className='table-display-limit-text-2 mr-2 ml-2'> مدخلات </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <div className='col-md-2 col-sm-2 col-2 col-lg-2'>
                                        <div className="row flex-row flex-nowrap table-header-row" >
                                            <div className="overflow-text-length ml-0">
                                                <div className='pointer-cursor' onClick={() => this.sortCoulmn('keyword_SEO')}>الاسم {this.state.sortColNum == 'keyword_SEO' ? (this.state.selectedSortType['keyword_SEO'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                            </div>
                                        </div>
                                        {mainCol}
                                    </div>
                                    <div className='col-md-8 col-sm-8 col-8 col-lg-8 pr-0' style={{ 'overflowX': 'auto', 'overflowY': 'hidden', 'width': '100%' }}>
                                        <div className="row flex-row flex-nowrap">
                                            <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('votersTotal')}>عدد الناخبين الكلي {this.state.sortColNum == 'votersTotal' ? (this.state.selectedSortType['votersTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('votedTotal')}>عدد المصوتيين {this.state.sortColNum == 'votedTotal' ? (this.state.selectedSortType['votedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('notVotedTotal')}>عدد الغير مصوتيين {this.state.sortColNum == 'notVotedTotal' ? (this.state.selectedSortType['notVotedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('description')}>الرقم الوطني {this.state.sortColNum == 'description' ? (this.state.selectedSortType['description'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('small_desc')}>رقم الهاتف {this.state.sortColNum == 'small_desc' ? (this.state.selectedSortType['small_desc'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            {/* <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('adminType')}>الشخص المسؤول {this.state.sortColNum == 'adminType' ? (this.state.selectedSortType['adminType'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div> */}
                                        </div>
                                        {dataCol}
                                    </div>
                                    <div className='col-md-2 col-sm-2 col-2 col-lg-2'>
                                        <div className="row flex-row flex-nowrap" >
                                            <div className="col-12 col-sm-6 col-md-12 col-lg-12 table-header-row overflow-text-length ml-0">
                                                <div className='pointer-cursor' onClick={() => this.sortCoulmn('nameConcat')}>المركز المباشر {this.state.sortColNum == 'nameConcat' ? (this.state.selectedSortType['nameConcat'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                            </div>
                                            {/* <div className="col-6 col-sm-3 col-md-6 col-lg-6 table-header-row overflow-text-length ml-0">
                                                <div className='pointer-cursor' onClick={() => this.sortCoulmn('centerName')}>المركز الرئيسي {this.state.sortColNum == 'centerName' ? (this.state.selectedSortType['centerName'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                            </div> */}
                                        </div>
                                        {actionCol}
                                    </div>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={6} className='pagination-style float-left text-left'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className="flaticon2-right-arrow"></i>}
                                            nextPageText={<i className="flaticon2-left-arrow"></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <div className='mt-2 float-right text-right'>
                                            عرض {this.state.activePage != 1 ? this.state.activePage + ((this.state.limit * (this.state.activePage - 1)) - this.state.activePage + 1) : this.state.activePage} الى {(this.state.activePage * this.state.limit) > this.state.dataCount ? this.state.dataCount : this.state.activePage * this.state.limit} من {this.state.dataCount} مدخلات
                                </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            </div >
        )
    }
}

export default Reports8;