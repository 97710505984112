import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import EditIcon from "@material-ui/icons/Edit";
import Report9form from "./Report9Form";

class Reports9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      limit: 10,
      dataCount: 0,
      sortColNum: "",
      selectedSortType: [],
      data: [],
      loading: 0,
      sortType: "",
      selectReportType: "",
      selectSubDepartemnt: "",
      parentDepartments: [],
      subDepartments: [],
      countVoted: 0,
      totalCount: 0,
      countNotVoted: 0,
      search: "",
      openleftModal: undefined,
    };
  }
  //   allPermission ١
  // viewPermission ٢
  // excelPermission ٣
  // pdfPermission ٤
  componentWillMount() {
    this.getReportLimit();
  }
  // getParentDepartments() {
  //     fetch(window.API_URL_DATA, {
  //         method: 'POST',
  //         body: JSON.stringify({
  //             'type': 'getParentDepartments',
  //             'userId': localStorage.getItem('USER_ID'),
  //             'token': localStorage.getItem('USER_TOKEN'),
  //         }),
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  //         },
  //     }).then(function (response) {
  //         return response.json();
  //     }).then(data => {
  //         if (typeof data !== 'Nothing found') {
  //             if (data == 'tokenExpired') {
  //                 localStorage.setItem('USER_ID', '');
  //                 window.location.href = "/logout";
  //             }
  //             this.setState({
  //                 parentDepartments: data
  //             });

  //         }
  //     });
  // }
  // getSubDepartmentHaveMembersByDepartmentId() {
  //     fetch(window.API_URL_DATA, {
  //         method: 'POST',
  //         body: JSON.stringify({
  //             'type': 'getSubDepartmentHaveMembersByDepartmentId',
  //             'id': this.state.selectReportType,
  //             'userId': localStorage.getItem('USER_ID'),
  //             'token': localStorage.getItem('USER_TOKEN'),
  //         }),
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  //         },
  //     }).then(function (response) {
  //         return response.json();
  //     }).then(data => {
  //         if (typeof data !== 'Nothing found') {
  //             if (data == 'tokenExpired') {
  //                 localStorage.setItem('USER_ID', '');
  //                 window.location.href = "/logout";
  //             }
  //             this.setState({
  //                 subDepartments: data
  //             });

  //         }
  //     });
  // }
  handleCloseSideBar = () => {
    this.setState({ openleftModal: false }, () => {
      this.getReportLimit();
    });
  };
  getReportLimit() {
    this.setState({ loading: 1 });
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllFinancialMembers",
        page: this.state.activePage,
        limit: this.state.limit,
        sort: this.state.sortColNum,
        getType: "",
        sortType: this.state.sortType,
        search: this.state.search,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          console.log(data, "datadatadatadatadatadata");

          this.setState({
            data: data["data"],
            loading: 0,
            // countVoted: data['count']['countVoted'],
            // totalCount: data['count']['countAllVoters'],
            // countNotVoted: data['count']['countNotVoted'],
            dataCount: data.count,
          });
        }
      });
  }
  // getReportCount() {
  //     fetch(window.API_URL_DATA, {
  //         method: 'POST',
  //         body: JSON.stringify({
  //             'type': 'getReportMembersCountBySubDepartmentId',
  //             'departmentId': this.state.selectSubDepartemnt,
  //             'page': this.state.activePage,
  //             'limit': this.state.limit,
  //             'sort': this.state.sortColNum,
  //             'getType': 'count',
  //             'sortType': this.state.sortType,
  //             search:this.state.search,
  //             'userId': localStorage.getItem('USER_ID'),
  //             'token': localStorage.getItem('USER_TOKEN'),
  //         }),
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  //         },
  //     }).then(function (response) {
  //         return response.json();
  //     }).then(data => {
  //         if (typeof data !== 'Nothing found') {
  //             if (data == 'tokenExpired') {
  //                 localStorage.setItem('USER_ID', '');
  //                 window.location.href = "/logout";
  //             }
  //             this.setState({
  //                 dataCount:  data['data'][0] != undefined ? data['data'][0]['counterData'] : [],
  //             });

  //         }
  //     });
  // }
  handleChangeLimit = (event) => {
    this.setState({ limit: event.target.value, activePage: 1 }, function() {
      this.getReportLimit();
    });
  };

  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };

  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };
  returnRowData = () => {
    var result = [];
    if (this.state.loading == 0 && this.state.data.length > 0) {
      this.state.data.map((rowData, index) => {
        console.log({ rowData });

        result.push(
          <Col
            md={12}
            key={index}
            id={rowData.userId}
            className="row-data-view"
          >
            <Row
              className={
                (this.state["activeIndex"] == index
                  ? "hover-scroll-table-row  "
                  : "") + "table-body-row table-body-row-border"
              }
              onMouseOver={this.addRowHover.bind(this, index)}
              onMouseOut={this.removeRowHover.bind(this, index)}
            >
              <Col
                md={2}
                className="d-inline-flex main-flyscreen-1 font-weight-500"
              >
                <div className="overflow-text-length ml-3">
                  {rowData["nameConcat"]}{" "}
                </div>
              </Col>
              {/* <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3" style={{ 'direction': 'ltr' }}>{rowData['small_desc'] == '' ? '-' : rowData['small_desc']}</div>
                            </Col> */}
              <Col
                md={2}
                className="d-inline-flex main-flyscreen-1 font-weight-500"
              >
                <div className="overflow-text-length ml-3">
                  {rowData["votersTotal"] == "" ? "-" : rowData["votersTotal"]}
                </div>
              </Col>
              <Col
                md={2}
                className="d-inline-flex main-flyscreen-1 font-weight-500"
              >
                <div className="overflow-text-length ml-3">
                  {rowData["votedTotal"] == "" ? "-" : rowData["votedTotal"]}
                </div>
              </Col>
              <Col
                md={2}
                className="d-inline-flex main-flyscreen-1 font-weight-500"
              >
                <div className="overflow-text-length ml-3">
                  {rowData["notVotedTotal"] == ""
                    ? "-"
                    : rowData["notVotedTotal"]}
                </div>
              </Col>
              <Col
                md={2}
                className="d-inline-flex main-flyscreen-1 font-weight-500"
              >
                <div className="overflow-text-length ml-3">
                  {rowData["centerName"] == "" ? "-" : rowData["centerName"]}
                </div>
              </Col>
              <Col
                md={1}
                className="d-inline-flex main-flyscreen-1 font-weight-500"
              >
                <div className="overflow-text-length ml-3 w-100 d-flex gap-2">
                  {rowData["cardsIncrease"] == ""
                    ? "-"
                    : rowData["cardsIncrease"]}
                </div>
              </Col>
              <Col
                md={1}
                className="d-inline-flex main-flyscreen-1 font-weight-500"
              >
                <div className="overflow-text-length ml-3 w-100 d-flex gap-2">
                  {rowData["amountTotal"] == "" ? "-" : rowData["amountTotal"]}
                  {console.log(this.state.permations[`${rowData.id}_1`], [
                    `${rowData.id}_1`,
                  ])}
                  {(this.state.permations[`${rowData.id}_1`] == true ||
                    this.state.permations[`${rowData.id}_3`] == true ||
                    true) && (
                    <span
                      onClick={(event) => {
                        this.openSidebarModal(event, "edit", rowData);
                      }}
                      type="button"
                    >
                      <EditIcon className="clickable-btn" />
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        );
      });
    }
    return result;
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, function() {
      this.getReportLimit();
    });
  };
  sortCoulmn = (colNum) => {
    this.setState({ sortColNum: colNum }, function() {
      if (
        (this.state.selectedSortType[colNum] == "" ||
          this.state.selectedSortType[colNum] == undefined ||
          this.state.selectedSortType[colNum] == null) &&
        this.state.selectedSortType[colNum] != 0
      ) {
        var sortVal = 0; // desc
        var sortType = "desc";
      } else if (this.state.selectedSortType[colNum] == 1) {
        var sortVal = 0; // desc
        var sortType = "desc";
      } else {
        var sortVal = 1; // asc
        var sortType = "asc";
      }
      const newArray = this.state.selectedSortType;
      newArray[colNum] = sortVal;
      this.setState(
        { selectedSortType: newArray, sortType: sortType },
        function() {
          this.getReportLimit();
        }
      );
    });
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        [name]: value,
        activePage: 1,
      },
      function() {
        if (name == "selectReportType") {
          // this.getSubDepartmentHaveMembersByDepartmentId();
        } else {
          this.getReportLimit();
          // this.getReportCount();
        }
      }
    );
  };
  componentWillMount() {
    this.getPermation();
  }
  getPermation() {
    var permations = {};
    let parentId = this.state.parent;

    let allPermation = [parentId] + "_1";
    let showPermation = [parentId] + "_2";
    let addPermation = [parentId] + "_3";
    let deletePermation = [parentId] + "_4";
    console.log("hello", localStorage.getItem("USER_LEVEL"));

    if (localStorage.getItem("USER_LEVEL") == 1) {
      permations =
        JSON.parse(localStorage.getItem("permations")) != undefined &&
        JSON.parse(localStorage.getItem("permations")) != null &&
        JSON.parse(localStorage.getItem("permations"))["financeData"] !=
          undefined &&
        JSON.parse(localStorage.getItem("permations"))["financeData"] != null
          ? JSON.parse(localStorage.getItem("permations"))["financeData"]
          : {};
      console.log(permations, "permations");

      // let parent = 0;
      // for (const key in permations) {
      //   parent = key.split("_")[0];
      //   let perm = key.split("_")[1];
      //   if (parent == this.state.parent) {
      //     let arr = JSON.parse(localStorage.getItem("parentPermations"));

      //     arr["_" + perm] = permations[key];
      //     localStorage.setItem("parentPermations", JSON.stringify(arr));
      //   }
      // }
    } else if (localStorage.getItem("USER_LEVEL") == 3) {
      permations = {
        [allPermation]: true,
        [showPermation]: true,
        [addPermation]: true,
        [deletePermation]: true,
      };
    } else {
      permations = {
        [allPermation]: 0,
        [showPermation]: 0,
        [addPermation]: 0,
        [deletePermation]: 0,
      };
    }
    this.setState(
      {
        permations: permations,
      },
      function() {
        this.getReportLimit();
      }
    );
  }
  export = (event, type) => {
    event.preventDefault();
    if (type == "pdf") {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: "getAllFinancialMembers",
          export: 1,
          sort: this.state.sortColNum,
          sortType: this.state.sortType,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          pdf: 1,
          page: 1,
          getType: "all",
          search: this.state.search,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            if (data["resNo"] == 0) {
              this.reportAlert(
                event,
                "تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي"
              );
            } else {
              this.reportAlert(event, "تعذر استلام طلبك !");
            }
          }
        });
    } else {
      fetch(window.API_URL_DATA, {
        method: "POST",
        body: JSON.stringify({
          type: "getAllFinancialMembers",
          export: 1,
          sort: this.state.sortColNum,
          sortType: this.state.sortType,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
          page: 1,
          getType: "all",
          search: this.state.search,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Request-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      })
        .then(function(response) {
          return response.json();
        })
        .then((data) => {
          if (typeof data !== "Nothing found") {
            if (data == "tokenExpired") {
              localStorage.setItem("USER_ID", "");
              window.location.href = "/logout";
            }
            if (data["resNo"] == 0) {
              this.reportAlert(
                event,
                "تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي"
              );
            } else {
              this.reportAlert(event, "تعذر استلام طلبك !");
            }
          }
        });
    }
  };
  openSidebarModal = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        departmentData: data,
        status: type,
        openleftModal: true,
      },
      function() {
        this.getReportLimit();
      }
    );
  };

  reportAlert = (event, text) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p>{text}</p>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={onClose}
            >
              {" "}
              اغلاق{" "}
            </Button>
          </div>
        );
      },
    });
  };
  getParentDepartmentsOptions(data, index) {
    return (
      <option key={index} value={data.id}>
        {data.name}
      </option>
    );
  }
  getSubDepartmentsOptions(data, index) {
    return (
      <option key={index} value={data.id}>
        {data.keyword_SEO} {data.desc_SEO} {data.countries} {data.location}{" "}
      </option>
    );
  }
  render() {
    return (
      <div>
        <Row>
          <Col md={12}>
            <div className="kt-portlet kt-portlet--height-fluid p-3">
              <Row>
                <Col md={4}>
                  <AvForm className="form-inline">
                    <Col md={12}>
                      <Row className="mt-2">
                        <Col md={12}>
                          {/* <AvField type="select" name="selectReportType" value={this.state.selectReportType} onChange={this.handleInputChange.bind(this)} className='form-input-width'>
                                                        <option style={{
                                                            'display': 'none'
                                                        }} value="">اختر المركز الرئيسي</option>
                                                        {this.state.parentDepartments.map((data, index) => this.getParentDepartmentsOptions(data, index))}
                                                    </AvField>
                                                    <AvField type="select" disabled={this.state.selectReportType == '' ? true : false} name="selectSubDepartemnt" value={this.state.selectSubDepartemnt} onChange={this.handleInputChange.bind(this)} className='form-input-width mt-2'>
                                                        <option style={{
                                                            'display': 'none'
                                                        }} value="">اختر اللجنة الفرعية</option>
                                                        {this.state.subDepartments.map((data, index) => this.getSubDepartmentsOptions(data, index))}
                                                    </AvField> */}
                          <AvField
                            type="text"
                            placeholder="البحث"
                            name="search"
                            value={this.state.search}
                            onChange={this.handleInputChange.bind(this)}
                            className="form-input-width mt-2"
                          ></AvField>
                        </Col>
                      </Row>
                    </Col>
                  </AvForm>
                </Col>
                <Col md={8} className="float-right text-right">
                  {(this.props.allPermission == 1 ||
                    this.props.excelPermission == 1) && (
                    <Button
                      variant="contained"
                      className="export-btn ml-2"
                      style={{ fontSize: 14 }}
                      onClick={(event) => this.export(event, "excel")}
                    >
                      {" "}
                      تصدير &nbsp;&nbsp;<i class="fas fa-file-download"></i>
                    </Button>
                  )}
                  {/* {(this.props.allPermission == 1 || this.props.pdfPermission == 1) &&
                                        <Button variant="contained" className='pdf-btn' style={{ fontSize: 14 }} onClick={(event) => this.export(event, 'pdf')}> Pdf &nbsp;&nbsp;<i class="far fa-file-pdf"></i></Button>
                                    } */}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="kt-portlet kt-portlet--height-fluid p-3">
              {this.state.loading == 1 && (
                <div className="data-loading-spinner">Loading...</div>
              )}

              {this.state.loading == 0 && this.state.data.length <= 0 && (
                <Row>
                  <Col md={12} className="text-center no-data-available-view">
                    <img src="/media/electra/no-data-icon-68.png" />
                    <p>لا يوجد بيانات .&nbsp;.&nbsp; !</p>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Row className="m-1">
                  <Col md={10} className="pl-0 text-right">
                    <div className="d-inline-flex mb-2">
                      <div className="table-display-limit-text-1 mr-2 ml-2">
                        {" "}
                        عرض
                      </div>
                      <AvForm className="form-inline table-display-limit-select">
                        <AvField
                          type="select"
                          name="limit"
                          value={this.state.limit}
                          onChange={this.handleChangeLimit}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </AvField>
                      </AvForm>
                      <div className="table-display-limit-text-2 mr-2 ml-2">
                        {" "}
                        مدخلات{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Row className="m-1">
                  <div className="col-md-3 col-sm-3 col-3 col-lg-3">
                    <div className="row flex-row flex-nowrap table-header-row">
                      <div className="overflow-text-length ml-3">
                        <div
                          className="pointer-cursor"
                          onClick={() => this.sortCoulmn("nameConcat")}
                        >
                          اسم صاحب الكشف{" "}
                          {this.state.sortColNum == "nameConcat" ? (
                            this.state.selectedSortType["nameConcat"] == 0 ? (
                              <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                            ) : (
                              <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                            )
                          ) : (
                            <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                          )}
                        </div>
                      </div>
                    </div>
                    {this.state.data.map((rowData, index) => (
                      <div
                        key={index}
                        className="row flex-row flex-nowrap table-body-row-border row-even-bg-color"
                      >
                        <div
                          className={
                            (this.state["activeIndex"] == index
                              ? "hover-scroll-table-row  "
                              : "") +
                            "col-12 col-sm-12 col-md-12 col-lg-12 table-body-row main-color-1 font-weight-500 overflow-text-length"
                          }
                          onMouseOver={this.addRowHover.bind(this, index)}
                          onMouseOut={this.removeRowHover.bind(this, index)}
                        >
                          {rowData["nameConcat"]}{" "}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div
                    className={"col-md-9 col-sm-9 col-9 col-lg-9 pr-0"}
                    style={{
                      overflowX: "auto",
                      overflowY: "hidden",
                      width: "100%",
                    }}
                  >
                    <div className="row flex-row flex-nowrap">
                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-3">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("votersTotal")}
                          >
                            عدد الناخبين{" "}
                            {this.state.sortColNum == "votersTotal" ? (
                              this.state.selectedSortType["votersTotal"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-3">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("votedTotal")}
                          >
                            عدد المصوتيين{" "}
                            {this.state.sortColNum == "votedTotal" ? (
                              this.state.selectedSortType["votedTotal"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-3">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("notVotedTotal")}
                          >
                            عدد الغير مصوتيين{" "}
                            {this.state.sortColNum == "notVotedTotal" ? (
                              this.state.selectedSortType["notVotedTotal"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-3">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("centerName")}
                          >
                            اسم الجمعية{" "}
                            {this.state.sortColNum == "centerName" ? (
                              this.state.selectedSortType["centerName"] == 0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-3">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("amountTotal")}
                          >
                            مجموع المبالغ{" "}
                            {this.state.sortColNum == "amountTotal" ? (
                              this.state.selectedSortType["amountTotal"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length">
                        <div className="overflow-text-length ml-3">
                          <div
                            className="pointer-cursor"
                            onClick={() => this.sortCoulmn("cardsIncrease")}
                          >
                            البطاقات المضافة{" "}
                            {this.state.sortColNum == "cardsIncrease" ? (
                              this.state.selectedSortType["cardsIncrease"] ==
                              0 ? (
                                <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i>
                              ) : (
                                <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>
                              )
                            ) : (
                              <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.data.map((rowData, index) => {
                      var checkEvenRow = index % 2;

                      return (
                        <div className="row flex-row flex-nowrap" key={index}>
                          <div
                            className={
                              (checkEvenRow == 0
                                ? " scroll-row-even-bg-color "
                                : " ") +
                              (this.state["activeIndex"] == index
                                ? "hover-scroll-table-row  "
                                : "") +
                              "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
                            }
                            onMouseOver={this.addRowHover.bind(this, index)}
                            onMouseOut={this.removeRowHover.bind(this, index)}
                          >
                            <div className="overflow-text-length ml-3">
                              {rowData["votersTotal"] == ""
                                ? "-"
                                : rowData["votersTotal"]}
                            </div>
                          </div>
                          <div
                            className={
                              (checkEvenRow == 0
                                ? " scroll-row-even-bg-color "
                                : " ") +
                              (this.state["activeIndex"] == index
                                ? "hover-scroll-table-row  "
                                : "") +
                              "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
                            }
                            onMouseOver={this.addRowHover.bind(this, index)}
                            onMouseOut={this.removeRowHover.bind(this, index)}
                          >
                            <div className="overflow-text-length ml-3">
                              {rowData["votedTotal"] == ""
                                ? "-"
                                : rowData["votedTotal"]}
                            </div>
                          </div>
                          <div
                            className={
                              (checkEvenRow == 0
                                ? " scroll-row-even-bg-color "
                                : " ") +
                              (this.state["activeIndex"] == index
                                ? "hover-scroll-table-row  "
                                : "") +
                              "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
                            }
                            onMouseOver={this.addRowHover.bind(this, index)}
                            onMouseOut={this.removeRowHover.bind(this, index)}
                          >
                            <div className="overflow-text-length ml-3">
                              {rowData["notVotedTotal"] == ""
                                ? "-"
                                : rowData["notVotedTotal"]}{" "}
                            </div>
                          </div>
                          <div
                            className={
                              (checkEvenRow == 0
                                ? " scroll-row-even-bg-color "
                                : " ") +
                              (this.state["activeIndex"] == index
                                ? "hover-scroll-table-row  "
                                : "") +
                              "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
                            }
                            onMouseOver={this.addRowHover.bind(this, index)}
                            onMouseOut={this.removeRowHover.bind(this, index)}
                          >
                            <div className="overflow-text-length ml-3">
                              {rowData["centerName"] == ""
                                ? "-"
                                : rowData["centerName"]}
                            </div>
                          </div>
                          <div
                            className={
                              (checkEvenRow == 0
                                ? " scroll-row-even-bg-color "
                                : " ") +
                              (this.state["activeIndex"] == index
                                ? "hover-scroll-table-row  "
                                : "") +
                              "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
                            }
                            onMouseOver={this.addRowHover.bind(this, index)}
                            onMouseOut={this.removeRowHover.bind(this, index)}
                          >
                            <div className="overflow-text-length ml-3 w-100 d-flex gap-2">
                              {rowData["amountTotal"] == ""
                                ? "-"
                                : rowData["amountTotal"]}
                              {console.log(
                                this.state.permations[`${rowData.id}_1`],
                                [`${rowData.id}_1`]
                              )}
                              {(this.state.permations[`${rowData.id}_1`] ==
                                true ||
                                this.state.permations[`${rowData.id}_3`] ==
                                  true ||
                                true) && (
                                <span
                                  onClick={(event) => {
                                    this.openSidebarModal(
                                      event,
                                      "edit",
                                      rowData
                                    );
                                  }}
                                  type="button"
                                >
                                  <EditIcon className="clickable-btn" />
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              (checkEvenRow == 0
                                ? " scroll-row-even-bg-color "
                                : " ") +
                              (this.state["activeIndex"] == index
                                ? "hover-scroll-table-row  "
                                : "") +
                              "col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border"
                            }
                            onMouseOver={this.addRowHover.bind(this, index)}
                            onMouseOut={this.removeRowHover.bind(this, index)}
                          >
                            <div className="overflow-text-length ml-3">
                              {rowData["cardsIncrease"] == ""
                                ? "-"
                                : rowData["cardsIncrease"]}{" "}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Row>
              )}

              {this.state.loading == 0 && this.state.data.length > 0 && (
                <Row className="m-1">
                  <Col md={6} className="pagination-style float-left text-left">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.limit}
                      totalItemsCount={this.state.dataCount}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      prevPageText={<i className="flaticon2-right-arrow"></i>}
                      nextPageText={<i className="flaticon2-left-arrow"></i>}
                      hideFirstLastPages
                      innerClass={
                        "pagination  pagination-rounded pagenation-view my-2"
                      }
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="mt-2 float-right text-right">
                      عرض{" "}
                      {this.state.activePage != 1
                        ? this.state.activePage +
                          (this.state.limit * (this.state.activePage - 1) -
                            this.state.activePage +
                            1)
                        : this.state.activePage}{" "}
                      الى{" "}
                      {this.state.activePage * this.state.limit >
                      this.state.dataCount
                        ? this.state.dataCount
                        : this.state.activePage * this.state.limit}{" "}
                      من {this.state.dataCount} مدخلات
                    </div>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
        </Row>
        <Drawer
          anchor={"left"}
          open={this.state.openleftModal}
          className="sidebar-modal-view"
        >
          {this.state.openleftModal == true && (
            <Report9form
              {...this.state.departmentData}
              handleCloseSideBar={this.handleCloseSideBar}
              permations={this.state.permations}
            />
          )}
        </Drawer>
      </div>
    );
  }
}

export default Reports9;
