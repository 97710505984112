import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Button } from "@material-ui/core";
import Pagination from "react-js-pagination";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import EditIcon from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

class Report9form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitDate: "",
      amount: 0,
      loading: false,
      errorMsg: "",
      activePage: 1,
      limit: 10,
      dataCount: 0,
      sortColNum: "",
      data: [],
      selectedId: "",
      selectedDate: "",
      selectedAmount: 0,
    };
  }
  componentWillMount() {
    this.getUserData();
  }
  handleDelete = async (id) => {
    try {
      this.setState({ loading: true, errorMsg: "" });
      const response = await fetch(`${window.API_URL_DATA}`, {
        method: "Post",
        body: JSON.stringify({
          type: "deleteFinancialMembersById",
          id: id,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
      });
      if (response.status == 200) {
        const res = await response.json();
        if (res.code == 200) {
          this.getUserData();
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleEdit = async () => {
    try {
      this.setState({ loading: true, errorMsg: "" });
      const response = await fetch(`${window.API_URL_DATA}`, {
        method: "Post",
        body: JSON.stringify({
          type: "editFinancialMembersById",
          id: this.state.selectedId,
          amount: this.state.selectedAmount,
          date: this.state.selectedDate,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
      });
      if (response.status == 200) {
        const res = await response.json();
        if (res.code == 200) {
          this.setState({
            selectedId: "",
            selectedAmount: 0,
            selectedDate: "",
          });
          this.getUserData();
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      this.setState({ loading: false });
    }
  };
  getUserData = async () => {
    try {
      this.setState({ loading: true, errorMsg: "" });
      const response = await fetch(`${window.API_URL_DATA}`, {
        method: "Post",
        body: JSON.stringify({
          type: "getFinancialMembersByMemberId",
          memberId: this.props.id,
          page: this.state.activePage,
          limit: this.state.limit,
          sort: "",
          getType: "all",
          sortType: "",
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
      });
      if (response.status == 200) {
        const res = await response.json();
        if (res.code == 200) {
          this.setState({
            data: res.data,
            dataCount: res.count,
          });
        }
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      this.setState({ loading: false });
    }
  };
  handleValidSubmit = async () => {
    try {
      this.setState({ loading: true, errorMsg: "" });
      const response = await fetch(`${window.API_URL_DATA}`, {
        method: "POST",
        body: JSON.stringify({
          type: "addFinancialMembersByMemberId",
          memberId: this.props.id,
          amount: this.state.amount,
          date: this.state.date,
          userId: localStorage.getItem("USER_ID"),
          token: localStorage.getItem("USER_TOKEN"),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (response.status == 200) {
        const res = await response.json();
        if (res.code == 200) {
          this.setState({ activePage: 1, data: [], dataCount: 0 }, () => {
            this.getUserData();
          });
        } else {
          this.setState({ errorMsg: res.msg });
        }
      }
    } catch (error) {
      console.log("error");
    } finally {
      this.setState({ loading: false });
    }
  };
  handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };
  addRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: index });
  };

  removeRowHover = (index, event) => {
    var stateVal = "activeIndex";
    this.setState({ [stateVal]: -1 });
  };
  reportAlert = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p>هل انت متاكد من انك تريد حذف هذا السجل</p>
            <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
              <Button
                variant="contained"
                className="delete-accept-action-btn"
                onClick={onClose}
              >
                {" "}
                اغلاق{" "}
              </Button>
              <Button
                variant="contained"
                className="delete-accept-action-btn"
                onClick={() => {
                  this.handleDelete(id);
                  onClose();
                }}
              >
                تاكيد
              </Button>
            </div>
          </div>
        );
      },
    });
  };
  returnRowData = () => {
    var result = [];
    if (this.state.loading == 0 && this.state.data.length > 0) {
      this.state.data.map((rowData, index) => {
        console.log({ rowData });
        result.push(
          <Col
            md={12}
            key={index}
            id={rowData.userId}
            className="row-data-view"
          >
            <AvForm>
              <Row
                className={
                  (this.state["activeIndex"] == index
                    ? "hover-scroll-table-row  "
                    : "") + "table-body-row table-body-row-border"
                }
                onMouseOver={this.addRowHover.bind(this, index)}
                onMouseOut={this.removeRowHover.bind(this, index)}
              >
                <Col
                  md={4}
                  className="d-inline-flex main-flyscreen-1 font-weight-500"
                >
                  {this.state.selectedId == rowData.id ? (
                    <AvField
                      type="number"
                      name="selectedAmount"
                      value={this.state.selectedAmount}
                      placeholder="المبلغ"
                      onChange={this.handleInputChange.bind(this)}
                      className="form-input-width mt-2"
                      required={true}
                      errorMessage="الرجاء ادخال قيمة صحيحة"
                    />
                  ) : (
                    <div className="overflow-text-length ml-3">
                      {rowData["amount"]}{" "}
                    </div>
                  )}
                </Col>
                <Col
                  md={4}
                  className="d-inline-flex main-flyscreen-1 font-weight-500"
                >
                  {this.state.selectedId == rowData.id ? (
                    <AvField
                      type="date"
                      name="selectedDate"
                      value={this.state.selectedDate}
                      placeholder="التاريخ"
                      onChange={this.handleInputChange.bind(this)}
                      className="form-input-width mt-2"
                      required={true}
                      errorMessage="الرجاء ادخال قيمة صحيحة"
                    />
                  ) : (
                    <div className="overflow-text-length ml-3">
                      {rowData["createdDate"] == ""
                        ? "-"
                        : rowData["createdDate"]}
                    </div>
                  )}
                </Col>
                <Col
                  md={2}
                  className="d-inline-flex main-flyscreen-1 font-weight-500"
                >
                  {(this.props.permations[`${this.props.id}_1`] == true ||
                    this.props.permations[`${this.props.id}_3`] == true||true) && (
                    <div className="overflow-text-length ml-3">
                      {this.state.selectedId == rowData.id ? (
                        <Button
                          variant="contained"
                          className="export-btn ml-2"
                          style={{ fontSize: 14 }}
                          onClick={(event) => this.handleEdit()}
                        >
                          تعديل
                        </Button>
                      ) : (
                        <span
                          type="button"
                          onClick={() => {
                            console.log("submitDate", rowData.createdDate);

                            this.setState({
                              selectedId: rowData.id,
                              selectedDate: rowData.createdDate.split(" ")[0],
                              selectedAmount: rowData.amount,
                            });
                          }}
                        >
                          <EditIcon className="clickable-btn" />
                        </span>
                      )}
                    </div>
                  )}
                </Col>
                {(this.props.permations[`${this.props.id}_1`] == true ||
                  this.props.permations[`${this.props.id}_4`] == true||true) && (
                  <Col
                    md={2}
                    className="d-inline-flex main-flyscreen-1 font-weight-500"
                  >
                    <div className="overflow-text-length ml-3">
                      <span
                        type="button"
                        onClick={() => {
                          this.reportAlert(rowData.id);
                        }}
                      >
                        <Delete className="clickable-btn" />
                      </span>
                    </div>
                  </Col>
                )}
              </Row>
            </AvForm>
          </Col>
        );
      });
    }
    return result;
  };
  render() {
    return (
      <div>
        <div className="test-modal-style">
          <div className="sidebar-header-view">
            <Row>
              <Col md={11}>
                <div className="p-0 main-color-1 font-weight-500 sidebar-header-title">
                  {this.props.nameConcat}
                </div>
              </Col>
              <Col md={1} className="pr-5">
                <i
                  className="p-0 flaticon2-delete sidebar-header-close-btn mr-5"
                  onClick={this.props.handleCloseSideBar}
                ></i>
              </Col>
            </Row>
          </div>

          <div className="sidebar-body-view">
            {(this.props.permations[`${this.props.id}_1`] == true ||
              this.props.permations[`${this.props.id}_3`] == true||true) && (
              <AvForm
                onValidSubmit={this.handleValidSubmit}
                ref={(c) => (this.form = c)}
              >
                <div className="row bottom-border">
                  <div className="col-12 col-lg-5 col-md-5">
                    <AvField
                      type="date"
                      name="submitDate"
                      value={this.state.submitDate}
                      label="التاريخ"
                      onChange={this.handleInputChange.bind(this)}
                      className="form-input-width mt-2"
                      required={true}
                      errorMessage="الرجاء ادخال قيمة صحيحة"
                    />
                  </div>
                  <div className="col-12 col-lg-5 col-md-5">
                    <AvField
                      type="number"
                      name="amount"
                      value={this.state.amount}
                      label="المبلغ"
                      onChange={this.handleInputChange.bind(this)}
                      className="form-input-width mt-2"
                      required={true}
                      errorMessage="الرجاء ادخال قيمة صحيحة"
                    />
                  </div>
                  <div className="col-12 col-lg-5 col-md-5">
                    <Button
                      variant="contained"
                      className="export-btn ml-2"
                      style={{ fontSize: 14 }}
                      type="submit"
                    >
                      اضافة
                    </Button>
                  </div>
                  {this.state.errorMsg != "" && (
                    <div className="col-12">
                      <p className="errorMsgNew">{this.state.errorMsg}</p>
                    </div>
                  )}
                </div>
              </AvForm>
            )}
            <Row>
              <Col md={12}>
                <div className="kt-portlet kt-portlet--height-fluid p-3">
                  {this.state.loading == true && (
                    <div className="data-loading-spinner">Loading...</div>
                  )}

                  {!this.state.loading && this.state.data.length <= 0 && (
                    <Row>
                      <Col
                        md={12}
                        className="text-center no-data-available-view"
                      >
                        <img src="/media/electra/no-data-icon-68.png" />
                        <p>لا يوجد بيانات .&nbsp;.&nbsp; !</p>
                      </Col>
                    </Row>
                  )}
                  {!this.state.loading && this.state.data.length > 0 && (
                    <Row className="m-1">
                      {/* <Col md={2} className='pl-0 mb-2'>
                                        <div className='color-code-view'>
                                            <Row>
                                                <Col md={12}> العدد الاجمالي للناخبين : {this.state.totalCount}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}> العدد الاجمالي للاصوات : {this.state.countVoted}</Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}> العدد الاجمالي للغير مصوتين : {this.state.countNotVoted}</Col>
                                            </Row>
                                        </div>
                                    </Col> */}
                      <Col md={10} className="pl-0 text-right">
                        <div className="d-inline-flex mb-2">
                          <div className="table-display-limit-text-1 mr-2 ml-2">
                            {" "}
                            عرض
                          </div>
                          <AvForm className="form-inline table-display-limit-select">
                            <AvField
                              type="select"
                              name="limit"
                              value={this.state.limit}
                              onChange={this.handleChangeLimit}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </AvField>
                          </AvForm>
                          <div className="table-display-limit-text-2 mr-2 ml-2">
                            {" "}
                            مدخلات{" "}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {!this.state.loading && this.state.data.length > 0 && (
                    <Row className="m-1">
                      <Col md={12}>
                        <Row className="table-header-row">
                          <Col md={4} className="d-inline-flex">
                            <div className="overflow-text-length ml-3">
                              <div>القيمة </div>
                            </div>
                          </Col>

                          <Col md={4} className="d-inline-flex">
                            <div className="overflow-text-length ml-3">
                              <div>التاريخ </div>
                            </div>
                          </Col>
                          <Col md={2} className="d-inline-flex">
                            <div className="overflow-text-length ml-3">
                              <div> تعديل </div>
                            </div>
                          </Col>
                          <Col md={2} className="d-inline-flex">
                            <div className="overflow-text-length ml-3">
                              <div> حذف </div>
                            </div>
                          </Col>
                          {/* 
                         
                          <Col md={2} className="d-inline-flex">
                            <div className="overflow-text-length ml-3">
                              <div>اسم الجمعية </div>
                            </div>
                          </Col>
                          <Col md={2} className="d-inline-flex">
                            <div className="overflow-text-length ml-3">
                              <div>مجموع المبالغ </div>
                            </div>
                          </Col> */}
                        </Row>
                      </Col>
                      <Col
                        md={12}
                        className="table-body-scroll table-body-row-view"
                      >
                        {this.returnRowData()}
                      </Col>
                    </Row>
                  )}

                  {!this.state.loading && this.state.data.length > 0 && (
                    <Row className="m-1">
                      <Col
                        md={6}
                        className="pagination-style float-left text-left"
                      >
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.limit}
                          totalItemsCount={this.state.dataCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                          prevPageText={
                            <i className="flaticon2-right-arrow"></i>
                          }
                          nextPageText={
                            <i className="flaticon2-left-arrow"></i>
                          }
                          hideFirstLastPages
                          innerClass={
                            "pagination  pagination-rounded pagenation-view my-2"
                          }
                          itemClass={"page-item"}
                          linkClass={"page-link"}
                        />
                      </Col>
                      <Col md={6}>
                        <div className="mt-2 float-right text-right">
                          عرض{" "}
                          {this.state.activePage != 1
                            ? this.state.activePage +
                              (this.state.limit * (this.state.activePage - 1) -
                                this.state.activePage +
                                1)
                            : this.state.activePage}{" "}
                          الى{" "}
                          {this.state.activePage * this.state.limit >
                          this.state.dataCount
                            ? this.state.dataCount
                            : this.state.activePage * this.state.limit}{" "}
                          من {this.state.dataCount} مدخلات
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}
export default Report9form;
