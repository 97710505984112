import React, { Component } from 'react';
import School from '../configuration/school/school';

class Reports4 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div>
                <School filter={1} allPermission={this.props.allPermission} viewPermission={this.props.viewPermission} excelPermission={this.props.excelPermission} pdfPermission={this.props.pdfPermission}/>
            </div>
        )
    }
}

export default Reports4;