import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Redirect, Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

class Reports7 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            limit: 10,
            dataCount: 0,
            sortColNum: '',
            selectedSortType: [],
            data: [],
            loading: 0,
            sortType: '',
            selectReportType: '',
            selectSubDepartemnt: '',
            parentDepartments: [],
            subDepartments: [],
            countVoted: '',
            parentId: '',
            breadCrumb: ''
        }
    }
    componentWillMount() {
        if (this.state.parentId == '') {
            this.getReportLimit();
            this.getReportCount();
        } else {
            this.getReportMembersLimit();
            this.getReportMembersCount();
        }
    }
    getReportLimit() {
        this.setState({ loading: 1 })
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllSubDepartmentCustomersCount',
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': this.state.sortColNum,
                'getType': '',
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                // console.log(data);

                this.setState({
                    data: data['data'],
                    notVotedTotal: data['counter']['notVotedTotal'],
                    votedTotal: data['counter']['votedTotal'],
                    votersTotal: data['counter']['votersTotal'],
                    loading: 0,
                });

            }
        });
    }
    getReportCount() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllSubDepartmentCustomersCount',
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': this.state.sortColNum,
                'getType': 'count',
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }


                this.setState({
                    dataCount: data['data'][0]['counter'],
                });

            }
        });
    }
    handleChangeLimit = (event) => {
        this.setState({ limit: event.target.value, activePage: 1 }, function () {
            if (this.state.parentId == '') {
                this.getReportLimit();
                this.getReportCount();
            } else {
                this.getReportMembersLimit();
                this.getReportMembersCount();
            }
        });
    }

    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }

    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }
    handleClickMember(event, id, breadCrumb) {
        this.setState({
            loading: 1,
            parentId: id,
            breadCrumb: breadCrumb
        }, function () {
            this.getReportMembersLimit();
            this.getReportMembersCount();
        })
    }
    getReportMembersLimit() {
        this.setState({ loading: 1 })
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getReportMembersCountBySubDepartmentId',
                'departmentId': this.state.parentId,
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': this.state.sortColNum,
                'getType': '',
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    data: data['data'],
                    loading: 0,
                });

            }
        });
    }
    getReportMembersCount() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getReportMembersCountBySubDepartmentId',
                'departmentId': this.state.parentId,
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': this.state.sortColNum,
                'getType': 'count',
                'sortType': this.state.sortType,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    dataCount: data['data'][0] != undefined ? data['data'][0]['counterData'] : [],
                });

            }
        });
    }
    returnRowData = () => {
        var result = []
        if (this.state.loading == 0 && this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {
                result.push(
                    <Col md={12} key={index} id={rowData.userId} className='row-data-view'>
                        <Row className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('table-body-row table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {this.state.parentId == '' &&
                                <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{index + 1}</div>
                                </Col>
                            }
                            {this.state.parentId == '' &&
                                <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3 pointer-cursor main-color-1" onClick={(event) => { this.handleClickMember(event, rowData['id'], rowData['keyword_SEO'] + ' ' + rowData['desc_SEO'] + ' ' + rowData['countries'] + ' ' + rowData['location']) }}>{rowData['keyword_SEO']} {rowData['desc_SEO']} {rowData['countries']} {rowData['location']}</div>
                                </Col>
                            }
                            {this.state.parentId == '' &&
                                <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{rowData['votersTotal'] == '' ? '-' : rowData['votersTotal']}</div>
                                </Col>
                            }
                            {this.state.parentId == '' &&
                                <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{rowData['votedTotal'] == '' ? '-' : rowData['votedTotal']}</div>
                                </Col>
                            }
                            {this.state.parentId == '' &&
                                <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{rowData['notVotedTotal'] == '' ? '-' : rowData['notVotedTotal']}</div>
                                </Col>
                            }
                            {this.state.parentId != '' &&
                                <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{rowData['nameConcat']}</div>
                                </Col>
                            }
                            {this.state.parentId != '' &&
                                <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3" style={{ 'direction': 'ltr' }}>{rowData['small_desc'] == '' ? '-' : rowData['small_desc']}</div>
                                </Col>
                            }
                            {this.state.parentId != '' &&
                                <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{rowData['votersTotal'] == '' ? '-' : rowData['votersTotal']}</div>
                                </Col>
                            }
                            {this.state.parentId != '' &&
                                <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{rowData['votedTotal'] == '' ? '-' : rowData['votedTotal']}</div>
                                </Col>
                            }
                            {this.state.parentId != '' &&
                                <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                    <div className="overflow-text-length ml-3">{rowData['notVotedTotal'] == '' ? '-' : rowData['notVotedTotal']}</div>
                                </Col>
                            }
                        </Row>
                    </Col>
                )
            })
        }
        return result
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            if (this.state.parentId == '') {
                this.getReportLimit();
                this.getReportCount();
            } else {
                this.getReportMembersLimit();
                this.getReportMembersCount();
            }
        });
    }
    sortCoulmn = (colNum) => {
        this.setState({ sortColNum: colNum }, function () {
            if ((this.state.selectedSortType[colNum] == '' || this.state.selectedSortType[colNum] == undefined || this.state.selectedSortType[colNum] == null) && this.state.selectedSortType[colNum] != 0) {
                var sortVal = 0 // desc
                var sortType = 'desc'
            } else if (this.state.selectedSortType[colNum] == 1) {
                var sortVal = 0 // desc
                var sortType = 'desc'
            } else {
                var sortVal = 1 // asc
                var sortType = 'asc'
            }
            const newArray = this.state.selectedSortType;
            newArray[colNum] = sortVal;
            this.setState({ selectedSortType: newArray, sortType: sortType }, function () {
                if (this.state.parentId == '') {
                    this.getReportLimit();
                    this.getReportCount();
                } else {
                    this.getReportMembersLimit();
                    this.getReportMembersCount();
                }
            });
        });
    }
    export = (event, type) => {
        event.preventDefault();
        if (type == 'pdf') {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getAllSubDepartmentCustomersCount',
                    'export': 1,
                    'sort': this.state.sortColNum,
                    'sortType': this.state.sortType,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'pdf': 1,
                    'page': 1,
                    'getType': 'all',
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }

                }
            });
        } else {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getAllSubDepartmentCustomersCount',
                    'export': 1,
                    'sort': this.state.sortColNum,
                    'sortType': this.state.sortType,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'page': 1,
                    'getType': 'all',
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }
                }
            });
        }
    }
    reportAlert = (event, text) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>{text}</p>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={onClose}> اغلاق </Button>
                    </div>

                );
            }
        });
    }
    render() {
        return (
            <div>
                {this.state.parentId != '' &&
                    <div className='breadCrumbDev'>
                        <Link to={"7"} className='mainPageBreadCrunb pt-2' style={{ marginRight: '28rem' }}>{this.state.breadCrumb}</Link>
                    </div>
                }
                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            <Row>
                                <Col md={12} className='float-right text-right'>
                                    {(this.props.allPermission == 1 || this.props.excelPermission == 1) &&
                                        <Button variant="contained" className='export-btn ml-2' style={{ fontSize: 14 }} onClick={(event) => this.export(event, 'excel')}> تصدير &nbsp;&nbsp;<i class="fas fa-file-download"></i></Button>
                                    }
                                    {(this.props.allPermission == 1 || this.props.pdfPermission == 1) &&
                                        <Button variant="contained" className='pdf-btn' style={{ fontSize: 14 }} onClick={(event) => this.export(event, 'pdf')}> Pdf &nbsp;&nbsp;<i class="far fa-file-pdf"></i></Button>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            {this.state.loading == 1 &&
                                <div className="data-loading-spinner">Loading...</div>
                            }

                            {(this.state.loading == 0 && this.state.data.length <= 0) &&
                                <Row>
                                    <Col md={12} className='text-center no-data-available-view'>
                                        <img src='/media/electra/no-data-icon-68.png' />
                                        <p>لا يوجد بيانات  .&nbsp;.&nbsp; !</p>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={3} className='pl-0 mb-2'>
                                        {this.state.parentId == '' &&
                                            <div className='color-code-view'>
                                                <Row>
                                                    <Col md={12}> العدد الاجمالي للناخبين : {this.state.votersTotal}</Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}> العدد الاجمالي للمسموح لهم الانتخاب : {this.state.votedTotal}</Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}> العدد الاجمالي للغير مسموح لهم الانتخاب : {this.state.notVotedTotal}</Col>
                                                </Row>
                                            </div>
                                        }
                                    </Col>
                                    <Col md={9} className='pl-0 text-right'>
                                        <div className='d-inline-flex mb-2'>
                                            <div className='table-display-limit-text-1 mr-2 ml-2'> عرض</div>
                                            <AvForm className="form-inline table-display-limit-select">
                                                <AvField type="select" name="limit" value={this.state.limit} onChange={this.handleChangeLimit}>
                                                    <option value="10" >10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </AvField>
                                            </AvForm>
                                            <div className='table-display-limit-text-2 mr-2 ml-2'> مدخلات </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={12}>
                                        <Row className='table-header-row'>
                                            {this.state.parentId == '' &&
                                                <Col md={2} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div>التسلسل</div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId == '' &&
                                                <Col md={3} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div className='pointer-cursor' onClick={() => this.sortCoulmn('keyword_SEO')}>اسم المركز / اللجنة {this.state.sortColNum == 'keyword_SEO' ? (this.state.selectedSortType['keyword_SEO'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId == '' &&
                                                <Col md={3} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div className='pointer-cursor' onClick={() => this.sortCoulmn('votersTotal')}>عدد الناخبين {this.state.sortColNum == 'votersTotal' ? (this.state.selectedSortType['votersTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId == '' &&
                                                <Col md={2} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div className='pointer-cursor' onClick={() => this.sortCoulmn('votedTotal')}>عدد المصوتيين {this.state.sortColNum == 'votedTotal' ? (this.state.selectedSortType['votedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId == '' &&
                                                <Col md={2} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div className='pointer-cursor' onClick={() => this.sortCoulmn('notVotedTotal')}>عدد الغير مصوتيين {this.state.sortColNum == 'notVotedTotal' ? (this.state.selectedSortType['notVotedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId != '' &&
                                                <Col md={3} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div>اسم صاحب الكشف </div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId != '' &&
                                                <Col md={3} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div>رقم الهاتف </div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId != '' &&
                                                <Col md={2} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div>عدد الناخبين </div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId != '' &&
                                                <Col md={2} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div>عدد المصوتيين </div>
                                                    </div>
                                                </Col>
                                            }
                                            {this.state.parentId != '' &&
                                                <Col md={2} className='d-inline-flex'>
                                                    <div className="overflow-text-length ml-3">
                                                        <div>عدد الغير مصوتيين </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                    <Col md={12} className='table-body-scroll table-body-row-view'>
                                        {this.returnRowData()}
                                    </Col>
                                </Row>
                            }

                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={6} className='pagination-style float-left text-left'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className="flaticon2-right-arrow"></i>}
                                            nextPageText={<i className="flaticon2-left-arrow"></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <div className='mt-2 float-right text-right'>
                                            عرض {this.state.activePage != 1 ? this.state.activePage + ((this.state.limit * (this.state.activePage - 1)) - this.state.activePage + 1) : this.state.activePage} الى {(this.state.activePage * this.state.limit) > this.state.dataCount ? this.state.dataCount : this.state.activePage * this.state.limit} من {this.state.dataCount} مدخلات
                                    </div>
                                    </Col>
                                </Row>
                            }
                        </div>


                    </Col>
                </Row>
            </div>
        )
    }
}

export default Reports7;