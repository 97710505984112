import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import '../../../../node_modules/react-phone-number-input/style.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import { toAbsoluteUrl } from "../../../_metronic";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: localStorage.getItem('USER_ID'),
            fnameOriginal: '',
            lnameOriginal: '',
            phoneOriginal: '',
            userEmailOriginal: '',
            idNumber: '',
            level: '',
            password: '',
            confirmPassword: '',
            checkConfirmPassword: false,
            phoneRequired: false,
            submitFaild: '',
            submitSuccess:''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentWillMount() {
        this.getUserInfo()
    }

    getUserInfo() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getUsers',
                'title': localStorage.getItem('USER_ID'),
                'page': 1,
                'limit': 1,
                'sort': '',
                'sortType': '',
                'getType': '',
                'adminType': '-1',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }


                this.setState({
                    fnameOriginal: data[0]['fname'],
                    fname: data[0]['fname'],
                    lnameOriginal: data[0]['lname'],
                    lname: data[0]['lname'],
                    phoneOriginal: data[0]['phone'],
                    phone: data[0]['phone'],
                    idNumber: data[0]['idNumber'],
                    userEmailOriginal: data[0]['userEmail'],
                    userEmail: data[0]['userEmail'],
                    level: data[0]['admin'],
                })

            }
        });
    }

    handleValidSubmit(event) {
        this.setState({ checkConfirmPassword: false, phoneRequired: false, submitFaild: '',submitSuccess:'' })

        var passwordMatch = 1
        var phoneHaveValue = 0

        if (this.state.password != '' && (this.state.password != this.state.confirmPassword)) {
            passwordMatch = 0
        }

        if (this.state.phone != '' && this.state.phone != undefined && this.state.phone != undefined) {
            if (this.state.phone.toString().length == 13) {
                if (this.state.phone.startsWith("+9627")) {
                    phoneHaveValue = 1
                }

            }

        }
        
        if (passwordMatch == 1 && phoneHaveValue == 1) {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'editUser',
                    'fname': this.state.fname,
                    'lname': this.state.lname,
                    'emailAdress': this.state.userEmail,
                    'phone': this.state.phone,
                    'level': this.state.level,
                    'password': this.state.password,
                    'idNumber': this.state.idNumber,
                    'userId': this.state.userId,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 2 || data['resNo'] == 1) {
                        this.setState({ submitFaild: data['resMsg'] })
                    } else {

                        var changeName = 0
                        if (this.state.fname != this.state.fnameOriginal || this.state.lname != this.state.lnameOriginal) {
                            changeName = 1
                        }

                        if (changeName == 1) {
                            localStorage.setItem('USER_FULL_NAME', this.state.fname + ' ' + this.state.lname);
                            window.location.reload();
                        }else{
                            this.setState({ submitSuccess: 'تم تعديل معلومات الحساب' })
                            this.getUserInfo()
                        }
 
                    }
                }
            });
        } else {
            if (passwordMatch == 0) {
                this.setState({ checkConfirmPassword: true })
            }

            if (phoneHaveValue == 0) {
                this.setState({ phoneRequired: true })
            }

        }


    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            checkConfirmPassword: false,
            phoneRequired: false,
            submitFaild: '',
            submitSuccess :''
        })
    }

    handleInputChangePhone(event, fieldName) {
        var name = fieldName
        var value = event;

        this.setState(prevState => ({
            phone: name,
            phoneRequired: false,
            submitFaild: '',
            submitSuccess:''
        }
        ))
    }


    render() {

        return (
            <div>

                <Row>
                    <Col md={5}>
                        <div className='profile-main-date-view mb-4' style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/electra/bg-3.jpg")})`
                        }}>
                            <div>
                                <img className='profile-main-date-img-view' alt="Pic" src={"/media/users/default.jpg"} />
                            </div>

                            <div className='mt-3 main-color-1 profile-main-date-name-view'>
                                {localStorage.getItem('USER_FULL_NAME')}
                            </div>

                            <div className='profile-main-date-field-view'>
                                <div className='profile-main-date-label-view'><i className='far fa-id-card ml-2'></i>الرقم الوطني</div>
                                <div className='profile-main-date-value-view'>{this.state.idNumber == '' ? '-' : this.state.idNumber}</div>
                            </div>

                            <div className='profile-main-date-field-view'>
                                <div className='profile-main-date-label-view'><i className='fas fa-phone ml-2'></i>رقم الهاتف</div>
                                <div className='profile-main-date-value-view' style={{'direction': 'ltr'}}>{this.state.phoneOriginal == '' ? '-' : this.state.phoneOriginal}</div>
                            </div>

                            <div className='profile-main-date-field-view'>
                                <div className='profile-main-date-label-view'><i className='fas fa-envelope ml-2'></i>البريد الإلكتروني</div>
                                <div className='profile-main-date-value-view'>{this.state.userEmailOriginal == '' ? '-' : this.state.userEmailOriginal}</div>
                            </div>

                            <div className='profile-main-date-field-view'>
                                <div className='profile-main-date-label-view'><i className='fas fa-sitemap ml-2'></i>تصنيف المستخدم</div>
                                <div className='profile-main-date-value-view'>{this.state.level == 1 ? 'مسؤول' : (this.state.level == 2?'موظف':'مسؤول رئيسي')}</div>
                            </div>

                        </div>
                    </Col>
                    <Col md={7}>
                        <div className='profile-main-date-view text-left mb-4'>
                            <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)} style={{ 'width': '80%', 'marginRight': '10%' }}>
                                <label className='profile-form-main-label'><i className='fas fa-user-edit ml-2'></i>تعديل معلومات الحساب</label>
                                <AvField name="fname" label='الاسم الأول *' placeholder='أدخل الاسم الأول' value={this.state.fname} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                <AvField name="lname" label='اسم العائلة *' placeholder='أدخل اسم العائلة' value={this.state.lname} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                <div className={this.state.phoneRequired == true ? 'custom-form-phone-view phoneErrorStyle' : 'custom-form-phone-view'}>
                                    <label>الهاتف *</label>
                                    <PhoneInput
                                        maxLength="16"
                                        defaultCountry="JO"
                                        international
                                        placeholder='أدخل رقم الهاتف'
                                        name={'phone'}
                                        value={this.state.phone}
                                        onChange={this.handleInputChangePhone.bind(this, 'phone')}
                                        className='mt-1 mb-4 phone'
                                        required
                                    />
                                    {this.state.phoneRequired == true && <div className="phone-error-msg">الرجاء ادخال قيمة صحيحة</div>}
                                </div>

                                <AvField name="userEmail" autoComplete="new-password" type="email" label='البريد الإلكتروني *' placeholder='أدخل البريد الإلكتروني' value={this.state.userEmail} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                <AvField name="password" autoComplete="new-password" type="password" label={this.props.status == 'add' ? 'كلمة المرور *' : 'كلمة المرور'} placeholder='أدخل كلمة المرور' value={this.state.password} required={this.props.status == 'add' ? true : false} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                <AvField name="confirmPassword" autoComplete="new-password" type="password" label={this.state.password != '' ? 'تأكيد كلمة المرور *' : 'تأكيد كلمة المرور'} placeholder='تأكيد كلمة المرور' value={this.state.confirmPassword} required={this.state.password != '' ? true : false} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                {this.state.checkConfirmPassword == true &&
                                    <div className='login-error-msg mb-3'>كلمة السر غير متطابقة</div>
                                }
                                {this.state.submitFaild != '' &&
                                    <div className='login-error-msg mb-3'>{this.state.submitFaild}</div>
                                }
                                {this.state.submitSuccess != '' &&
                                    <div className='form-success-msg mb-3'>{this.state.submitSuccess}</div>
                                }

                                <div className="text-left">
                                    <button type="submit" className="btn form-save-btn mr-1 ml-1"><i className='flaticon2-check-mark form-save-btn-icon'></i> تعديل </button>
                                </div>
                            </AvForm>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default Profile;