import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Input } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class SmsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sms: this.props.sms,
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    handleValidSubmit(event) {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'editSmsData',
                'smsData': this.state.sms,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
            }
        });
        this.props.handleCloseSideBar()
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value,
        })
    }
    render() {
        return (
            <div>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'> نموذج {this.props.status == 'add' ? 'الإضافة' : 'التعديل'}
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn mr-5' onClick={this.props.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>

                    <div className='sidebar-body-view'>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <Row>
                                <Col md={12} className='pl-5 pr-5'>
                                    <AvField name="sms" type="textarea" label='نص الرسالة *' placeholder='ادخل نص الرسالة' value={this.state.sms} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='pl-5 pr-5'>
                                    <div className="text-left">
                                        <button type="submit" className="btn form-save-btn mr-1 ml-1"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                        <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> الغاء</button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </div>
            </div>
        )
    }

}
export default SmsForm;