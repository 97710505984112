import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Button, Drawer } from "@material-ui/core";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import Pagination from "react-js-pagination";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import SubSettings from "./subSettings";
import Reports from "./reports";
import Departments from "./departments";
import ImportCustomerForm from "./importCustomerForm";
import SmsForm from "./smsForm";
import Customers from "./customers";
import Users from "./users";
import Dashboard from "./dashboard";
import ResutReport from "./resutReport";
import Finance from "./Finance";
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      usersData: [],
      loading: 1,
      activeUser: 0,
      userActionArray: [],
      dataaArry: [][0],
      allDepartments: [],
      openImportModal: false,
      openleftModal: false,
      reportData: {},
      subSettingData: [],
      departementData: [],
      customersData: [],
      usersListData: [],
      dashboardData: [],
      reportMultiValue: [],
      subSettingMultiValue: [],
      departementDataMultiValue: [],
      financeData: [],
      financeDataMultiValue: [],
      customersDataMultiValue: [],
      usersListDataMultiValue: [false, false, false, false],
      dashboardDataMultiValue: [],
      usersMultiValue: [],
      resultReportData: [],
      resultReportDataMultiValue: [],
      sms: "",
      multiUserActionArray: [],
      parentDepartments: [],
      selectedDepart: [],
    };
  }
  handleChangeSelectedDeps = (selectedDepart) => {
    this.setState({ selectedDepart });
  };
  openSidebarModal = (event, type, data) => {
    event.preventDefault();

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState(
      {
        openleftModal: true,
      },
      function() {}
    );
  };
  getParentDepartments() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getParentDepartments",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            parentDepartments: data,
          });
        }
      });
  }
  // getSubDepartmentHaveMembersByDepartmentId() {
  //     fetch(window.API_URL_DATA, {
  //         method: 'POST',
  //         body: JSON.stringify({
  //             'type': 'getSubDepartmentHaveMembersByDepartmentId',
  //             'id': this.state.selectReportType,
  //             'userId': localStorage.getItem('USER_ID'),
  //             'token': localStorage.getItem('USER_TOKEN'),
  //         }),
  //         headers: {
  //             'Accept': 'application/json',
  //             'Content-Type': 'application/json',
  //             'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  //         },
  //     }).then(function (response) {
  //         return response.json();
  //     }).then(data => {
  //         if (typeof data !== 'Nothing found') {
  //             if (data == 'tokenExpired') {
  //                 localStorage.setItem('USER_ID', '');
  //                 window.location.href = "/logout";
  //             }
  //             this.setState({
  //                 subDepartments: data
  //             });

  //         }
  //     });
  // }
  getSms() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getSmsData",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState({
            sms: data["description"],
          });
        }
      });
  }
  saveData = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            {/* <p>هل تريد تطبيق هذه الصلاحيات على مستخدمين اخرين ؟</p> */}
            <p>هل تريد حفظ هذه الصلاحيات ؟</p>
            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              لا{" "}
            </Button>
            <Button
              variant="contained"
              className="delete-accept-action-btn"
              onClick={() => {
                this.saveUserPermission(event);
              }}
            >
              {" "}
              نعم{" "}
            </Button>
          </div>
        );
      },
    });
  };
  handleMultiChange = (option) => {
    this.setState((state) => {
      return {
        usersMultiValue: option,
      };
    });
  };
  handleClickOtherUsers = (event) => {
    event.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p> قم باختيار المستخدمين لتطبيق هذه الصلاحيات عليهم </p>
            <Row>
              <Col className="m-2">
                <div className="searchable-list" style={{ width: "92%" }}>
                  <Picky
                    value={this.state.usersMultiValue}
                    options={this.state.multiUserActionArray}
                    onChange={this.handleMultiChange}
                    open={false}
                    valueKey="id"
                    labelKey="name"
                    multiple={true}
                    includeSelectAll={true}
                    includeFilter={true}
                    dropdownHeight={200}
                    selectAllText="اختر جميع المستخدمين"
                    placeholder="اختر"
                    filterPlaceholder="اختر المستخدم"
                  />
                </div>
              </Col>
            </Row>
            <Button
              variant="contained"
              className="delete-accept-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              حفظ{" "}
            </Button>{" "}
            {/* {heare call save data function} */}
          </div>
        );
      },
    });
  };
  handleClickOnlySave = (event) => {
    event.preventDefault();
    {
      /* {heare call save data api} */
    }
    this.saveUserPermission();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui alert-box-view">
            <p style={{ color: "#024502" }}>
              {" "}
              <i
                className="far fa-check-circle ml-1"
                style={{ fontSize: "15px" }}
              ></i>{" "}
              تم الحفظ بنجاح{" "}
            </p>
            <Button
              variant="contained"
              className="delete-reject-action-btn mr-2"
              onClick={onClose}
            >
              {" "}
              إغلاق{" "}
            </Button>
          </div>
        );
      },
    });
  };

  saveUserPermission = () => {
    // get all tabs data

    // console.log('Report Data : ');
    // console.log(this.state.reportData);

    // console.log('SubSetting Data : ');
    // console.log(this.state.subSettingData);

    // console.log('Departement Data : ');
    // console.log(this.state.departementData);

    // console.log('Customers Data : ');
    // console.log(this.state.customersData);

    // console.log('usersList Data : ');
    // console.log(this.state.usersListData);

    // console.log('dashboard Data : ');
    // console.log(this.state.dashboardData);

    // CALL API ...

    let permissionArray = {};
    permissionArray["reportData"] = Object.entries(this.state.reportData);
    console.log(
      Object.entries(this.state.reportData),
      "Object.entries(this.state.reportData)"
    );

    permissionArray["subSettingData"] = Object.entries(
      this.state.subSettingData
    );
    permissionArray["departementData"] = Object.entries(
      this.state.departementData
    );
    permissionArray["customersData"] = Object.entries(this.state.customersData);
    permissionArray["usersListData"] = Object.entries(this.state.usersListData);
    permissionArray["dashboardData"] = Object.entries(this.state.dashboardData);
    permissionArray["resultReportData"] = Object.entries(
      this.state.resultReportData
    );
    permissionArray["financeData"] = Object.entries(
      this.state.financeData
    );
    permissionArray["reportMultiValue"] = this.state.reportMultiValue;
    permissionArray["subSettingMultiValue"] = this.state.subSettingMultiValue;
    permissionArray[
      "departementDataMultiValue"
    ] = this.state.departementDataMultiValue;
    permissionArray[
      "customersDataMultiValue"
    ] = this.state.customersDataMultiValue;
    permissionArray[
      "usersListDataMultiValue"
    ] = this.state.usersListDataMultiValue;
    permissionArray[
      "dashboardDataMultiValue"
    ] = this.state.dashboardDataMultiValue;
    permissionArray[
      "resultReportDataMultiValue"
    ] = this.state.resultReportDataMultiValue;
    permissionArray["financeDataMultiValue"] = this.state.financeDataMultiValue;
   
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "editUserPermationsData",
        id: this.state.userActionArray[0],
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
        permations: Object.entries(permissionArray),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }

          // console.log('Result : ');
          // console.log(data);

          if (data["resNo"] == 0) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="custom-ui alert-box-view">
                    <p style={{ color: "#024502" }}>
                      {" "}
                      <i
                        className="far fa-check-circle ml-1"
                        style={{ fontSize: "15px" }}
                      ></i>{" "}
                      تم الحفظ بنجاح{" "}
                    </p>
                    <Button
                      variant="contained"
                      className="delete-reject-action-btn mr-2"
                      onClick={onClose}
                    >
                      {" "}
                      إغلاق{" "}
                    </Button>
                  </div>
                );
              },
            });
          }
        }
      });
  };
  handleCloseSideBar = () => {
    this.setState({ openleftModal: false }, function() {
      this.getSms();
    });
  };
  handleFormAction = (status) => {
    this.setState({ openleftModal: false }, function() {
      this.getSms();
    });
  };
  openImportModal = (event, type, id) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState(
      {
        openImportModal: true,
      },
      function() {}
    );
  };
  handleImportAction = (status) => {
    this.setState({ openImportModal: false, activePage: 1 });
  };
  handleCloseImportSideBar = () => {
    this.setState({ openImportModal: false });
  };
  addActive = (index, event) => {
    var stateVal = "activeTab";
    this.setState({ [stateVal]: index }, function() {});
  };
  componentWillMount() {
    // console.log(JSON.parse(localStorage.getItem('permations')));

    this.getAdminUsers();
    this.getAllDepartments();
    this.getSms();
  }
  getAdminUsers() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getUsers",
        getType: "all",
        limit: this.state.limit,
        adminType: 1,
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          let multiUserActionArray = [...this.state.multiUserActionArray];
          for (const key in data) {
            multiUserActionArray.push({
              id: data[key]["userId"],
              name: data[key]["fname"] + " " + data[key]["lname"],
            });
          }

          let userActionArray = [];
          userActionArray[0] = data[0]["userId"];

          this.setState(
            {
              usersData: data,
              loading: 0,
              multiUserActionArray: multiUserActionArray,
              userActionArray: userActionArray,
            },
            function() {
              this.getUserPermation(userActionArray[0]);
            }
          );
        }
      });
  }
  getAllDepartments() {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getAllDepartment",
        getAll: "1",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          let allDepartments = [...this.state.allDepartments];
          for (const key in data) {
            if (data[key]["parent"] == "0") {
              allDepartments.push({
                id: data[key]["id"],
                name: data[key]["name"],
              });
            } else {
              // allDepartments.push({
              //     'id': data[key]['id'],
              //     'name': data[key]['keyword_SEO'] + ' ' + data[key]['desc_SEO'] + ' ' + data[key]['countries'] + ' ' + data[key]['location']
              // });
            }
          }
          this.setState({
            allDepartments: allDepartments,
          });
        }
      });
  }
  setUserActive(index, data) {
    let userActionArray = [...this.state.userActionArray];
    userActionArray[0] = data.userId;
    this.setState(
      {
        activeUser: index,
        userActionArray: userActionArray,
      },
      function() {
        this.getUserPermation(userActionArray[0]);
      }
    );
  }
  getUserPermation(userId) {
    fetch(window.API_URL_DATA, {
      method: "POST",
      body: JSON.stringify({
        type: "getUsers",
        title: userId,
        page: 1,
        limit: 1,
        sort: "",
        sortType: "",
        getType: "",
        adminType: "-1",
        userId: localStorage.getItem("USER_ID"),
        token: localStorage.getItem("USER_TOKEN"),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Request-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
    })
      .then(function(response) {
        return response.json();
      })
      .then((data) => {
        if (typeof data !== "Nothing found") {
          if (data == "tokenExpired") {
            localStorage.setItem("USER_ID", "");
            window.location.href = "/logout";
          }
          this.setState(
            {
              reportData:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["reportData"] != undefined
                  ? data[0]["permations"]["reportData"]
                  : {},
              subSettingData:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["subSettingData"] != undefined
                  ? data[0]["permations"]["subSettingData"]
                  : [],
              departementData:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["departementData"] != undefined
                  ? data[0]["permations"]["departementData"]
                  : [],
                  financeData:
                  data[0]["permations"] != undefined &&
                  data[0]["permations"]["financeData"] != undefined
                    ? data[0]["permations"]["financeData"]
                    : [],
              customersData:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["customersData"] != undefined
                  ? data[0]["permations"]["customersData"]
                  : [],
              usersListData:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["usersListData"] != undefined
                  ? data[0]["permations"]["usersListData"]
                  : [],
              dashboardData:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["dashboardData"] != undefined
                  ? data[0]["permations"]["dashboardData"]
                  : [],
              reportMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["reportMultiValue"] != undefined
                  ? data[0]["permations"]["reportMultiValue"]
                  : [],
              subSettingMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["subSettingMultiValue"] != undefined
                  ? data[0]["permations"]["subSettingMultiValue"]
                  : [],
              departementDataMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["departementDataMultiValue"] != undefined
                  ? data[0]["permations"]["departementDataMultiValue"]
                  : [],
                  financeDataMultiValue:
                  data[0]["permations"] != undefined &&
                  data[0]["permations"]["financeDataMultiValue"] != undefined
                    ? data[0]["permations"]["financeDataMultiValue"]
                    : [],
              customersDataMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["customersDataMultiValue"] != undefined
                  ? data[0]["permations"]["customersDataMultiValue"]
                  : [],
              usersListDataMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["usersListDataMultiValue"] != undefined
                  ? data[0]["permations"]["usersListDataMultiValue"]
                  : [],
              dashboardDataMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["dashboardDataMultiValue"] != undefined
                  ? data[0]["permations"]["dashboardDataMultiValue"]
                  : [],
              usersMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["usersMultiValue"] != undefined
                  ? data[0]["permations"]["usersMultiValue"]
                  : [],
              resultReportData:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["resultReportData"] != undefined
                  ? data[0]["permations"]["resultReportData"]
                  : [],
              resultReportDataMultiValue:
                data[0]["permations"] != undefined &&
                data[0]["permations"]["resultReportDataMultiValue"] != undefined
                  ? data[0]["permations"]["resultReportDataMultiValue"]
                  : [],
              activeTab: this.state.activeTab == 1 ? 2 : 1,
            },
            function() {
              this.handleGetTabData(
                this.state.activeTab,
                this.state.activeTab == 1
                  ? this.state.reportData
                  : this.state.subSettingData,
                this.state.activeTab == 1
                  ? this.state.reportMultiValue
                  : this.state.subSettingMultiValue
              );
            }
          );
        }
      });
  }
  getUserDiv(data, index) {
    return (
      <div
        onClick={() => this.setUserActive(index, data)}
        key={index}
        className={
          (this.state.activeUser == index ? "userActive " : "") +
          " user-hover pointer-cursor mb-2 main-color-1 font-weight-500 pt-2 pb-2"
        }
        style={{ fontSize: 18 }}
      >
        {data.fname} {data.lname}
      </div>
    );
  }

  handleGetTabData = (tab, data, multiValue) => {
    console.log('BARAA',tab);
    
    if (Object.keys(data).length > 0) {
      if (tab == 1) {
        this.setState({ reportData: data, reportMultiValue: multiValue });
      } else if (tab == 2) {
        this.setState({
          subSettingData: data,
          subSettingMultiValue: multiValue,
        });
      } else if (tab == 3) {
        console.log({
          departementData: data,
          departementDataMultiValue: multiValue,
        });
        
        this.setState({
          departementData: data,
          departementDataMultiValue: multiValue,
        });
      } else if (tab == 4) {
        this.setState({
          customersData: data,
          customersDataMultiValue: multiValue,
        });
      } else if (tab == 5) {
        this.setState({
          usersListData: data,
          usersListDataMultiValue: multiValue,
        });
      } else if (tab == 6) {
        this.setState({
          dashboardData: data,
          dashboardDataMultiValue: multiValue,
        });
      } else if (tab == 7) {
        this.setState({
          resultReportData: data,
          resultReportDataMultiValue: multiValue,
        });
      } else if (tab == 8) {
        this.setState({
          financeData: data,
          financeDataMultiValue: multiValue,
        });
      }
    }
  };

  render() {
    return (
      <div>
        <Row>
          <Col md={12}>
            <Row>
              <div className="kt-portlet kt-portlet--height-fluid p-3">
                <Row>
                  <Col md={4}>
                    <h3>الإعدادات</h3>
                  </Col>
                  <Col md={8} className="float-right text-right">
                    <Button
                      variant="contained"
                      className="send-sms-btn ml-2"
                      style={{ fontSize: 14 }}
                      onClick={(event) => this.openSidebarModal(event, "edit")}
                    >
                      {" "}
                      اعدادات الرسالة النصية&nbsp;&nbsp;
                      <i class="flaticon-chat"></i>
                    </Button>
                    {/* <Button variant="contained" className='main-action-btn ml-2' style={{ fontSize: 14 }} onClick={(event) => this.openImportModal(event, 'add')}> استيراد ملف الناخبين &nbsp;&nbsp;<i className='far fa-file-excel pl-1'></i></Button> */}
                  </Col>
                </Row>
              </div>
            </Row>
            <Row>
              <div className="kt-portlet kt-portlet--height-fluid p-3">
                <h3>الصلاحيات</h3>
                {this.state.loading == 1 && (
                  <div className="data-loading-spinner">Loading...</div>
                )}

                {this.state.loading == 0 && this.state.usersData.length <= 0 && (
                  <Row>
                    <Col md={12} className="text-center no-data-available-view">
                      <img src="/media/electra/no-data-icon-68.png" />
                      <p>لا يوجد بيانات .&nbsp;.&nbsp; !</p>
                    </Col>
                  </Row>
                )}
                {this.state.loading == 0 && this.state.usersData.length > 0 && (
                  <Row style={{ minHeight: 476, maxHeight: 476 }}>
                    <Col md={9}>
                      <AvForm>
                        <div className="sidebar-body-view nav-fill pb-0">
                          <ul class="nav nav-tabs">
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 1 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 1)}
                            >
                              <a class="nav-link">
                                التقارير{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 2 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 2)}
                            >
                              <a class="nav-link">
                                الإعدادات{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 3 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 3)}
                            >
                              <a class="nav-link">
                                المراكز الإنتخابية{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 4 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 4)}
                            >
                              <a class="nav-link">
                                الناخبين{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 5 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 5)}
                            >
                              <a class="nav-link">
                                المستخدمين{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 6 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 6)}
                            >
                              <a class="nav-link">
                                الإحصائيات{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 7 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 7)}
                            >
                              <a class="nav-link">
                                التقرير الشامل{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                            <li
                              className={
                                "nav-item" +
                                (this.state["activeTab"] == 8 ? " active " : "")
                              }
                              onClick={this.addActive.bind(this, 8)}
                            >
                              <a class="nav-link">
                                المالية{" "}
                                <span className="badge ml-3 mr-3"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        {this.state.activeTab == 1 && (
                          <Reports
                            handleGetTabData={this.handleGetTabData}
                            reportData={this.state.reportData}
                            reportMultiValue={this.state.reportMultiValue}
                          />
                        )}
                        {this.state.activeTab == 2 && (
                          <SubSettings
                            handleGetTabData={this.handleGetTabData}
                            subSettingData={this.state.subSettingData}
                            subSettingMultiValue={
                              this.state.subSettingMultiValue
                            }
                          />
                        )}
                        {this.state.activeTab == 3 && (
                          <Departments
                            allDepartments={this.state.allDepartments}
                            handleGetTabData={this.handleGetTabData}
                            departementData={this.state.departementData}
                            departementDataMultiValue={
                              this.state.departementDataMultiValue
                            }
                          />
                        )}
                        {this.state.activeTab == 4 && (
                          <Customers
                            handleGetTabData={this.handleGetTabData}
                            customersData={this.state.customersData}
                            customersDataMultiValue={
                              this.state.customersDataMultiValue
                            }
                          />
                        )}
                        {this.state.activeTab == 5 && (
                          <Users
                            handleGetTabData={this.handleGetTabData}
                            usersListData={this.state.usersListData}
                            usersListDataMultiValue={
                              this.state.usersListDataMultiValue
                            }
                          />
                        )}
                        {this.state.activeTab == 6 && (
                          <Dashboard
                            handleGetTabData={this.handleGetTabData}
                            dashboardData={this.state.dashboardData}
                            dashboardDataMultiValue={
                              this.state.dashboardDataMultiValue
                            }
                          />
                        )}
                        {this.state.activeTab == 7 && (
                          <ResutReport
                            handleGetTabData={this.handleGetTabData}
                            resultReportData={this.state.resultReportData}
                            resultReportDataMultiValue={
                              this.state.resultReportDataMultiValue
                            }
                          />
                        )}
                        {this.state.activeTab == 8 && (
                          <Finance
                            allDepartments={this.state.allDepartments}
                            handleGetTabData={this.handleGetTabData}
                            financeData={this.state.financeData}
                            financeDataMultiValue={
                              this.state.financeDataMultiValue
                            }
                          />
                        )}
                        <div className="mt-2 ml-3">
                          <button
                            type="submit"
                            className="btn form-save-btn mr-1 ml-1"
                            onClick={(event) => this.saveData(event)}
                          >
                            <i className="flaticon2-check-mark form-save-btn-icon"></i>{" "}
                            حفظ{" "}
                          </button>
                        </div>
                      </AvForm>
                    </Col>
                    <Col md={3}>
                      <Row className="mb-5 mt-n5 pt-2 text-center">
                        <Col>
                          <h4>المستخدمين</h4>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col
                          className="mt-5 pt-5 text-center overflow-auto pr-5 pl-5"
                          style={{ minHeight: 397, maxHeight: 397 }}
                        >
                          {this.state.usersData.map((data, index) =>
                            this.getUserDiv(data, index)
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </div>
            </Row>
          </Col>
        </Row>
        <Drawer
          anchor={"left"}
          open={this.state.openImportModal}
          className="sidebar-modal-view"
        >
          <ImportCustomerForm
            ref="importCustomerForm"
            handleCloseImportSideBar={this.handleCloseImportSideBar}
            handleImportAction={this.handleImportAction}
          />
        </Drawer>
        <Drawer
          anchor={"left"}
          open={this.state.openleftModal}
          className="sidebar-modal-view"
        >
          {this.state.openleftModal == true && (
            <SmsForm
              ref="smsForm"
              sms={this.state.sms}
              handleCloseSideBar={this.handleCloseSideBar}
              handleFormAction={this.handleFormAction}
            />
          )}
        </Drawer>
      </div>
    );
  }
}
export default Settings;
