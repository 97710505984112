import React, { Component } from 'react';
import { Button, Drawer } from "@material-ui/core";
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Chart from "react-apexcharts";
import Customers from '../customers/customers';

class DashboardAreaCustomers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {},
            series: [],
            data: [],
            selectedRegion: [],
            openRightModal: false,
            regionId: ''
        }
    }
    componentWillMount() {
        this.getAllAreaAndCustomerCount();
    }

    openChartDetails = (regionIndex) => {
        var regionData = this.state.data[regionIndex]

        if (regionData != '' && regionData != undefined && regionData != null) {
            var regionId = this.state.data[regionIndex]['regionId']
            this.setState({ selectedRegion: regionData, openRightModal: true, regionId: regionId })
        }
    }

    handleCloseSideBar = () => {
        this.setState({ openRightModal: false, selectedRegion: [],regionId:'' })
    }

    getAllAreaAndCustomerCount() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllAreaAndCustomerCount',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }

                // console.log('data');
                // console.log(data);

                let series = [];
                let labels = [];
                for (const key in data) {
                    series.push(data[key]['customerCount']);
                    labels.push(data[key]['regionName']);
                }
                const scope = this
                this.setState({
                    options: {
                        chart: {
                            id: "basic-bar",
                            events: {
                                click: function (event, chartContext, config) {
                                    scope.openChartDetails(config.dataPointIndex)
                                },
                            }
                        },
                        xaxis: {
                            categories: labels,

                        },
                        yaxis: {
                            opposite: true,

                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                distributed: true,
                            }
                        },
                        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7', '#2b908f'],

                        title: {
                            text: 'عدد الناخبين حسب المنطقة',
                            align: 'center',
                            floating: true
                        },
                        tooltip: {
                            theme: 'dark',
                            x: {
                                show: true
                            },
                            y: {
                                title: {
                                    formatter: function () {
                                        return ''
                                    }
                                }
                            }
                        },
                    },

                    series: [
                        {
                            name: "series-1",
                            data: series
                        }
                    ],
                    data: data
                });

            }
        });
    }
    render() {
        // console.log(this.state.series);

        return (
            <div>
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"

                />

                <Drawer anchor={'left'} open={this.state.openRightModal} className='sidebar-modal-view'>
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'>عدد الناخبين حسب منطقة ({this.state.selectedRegion['regionName']})
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn  mr-5' onClick={this.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>
                    <Customers dashboardFilter={1} regionId={this.state.regionId} />
                </Drawer>
            </div>
        )
    }
}
export default DashboardAreaCustomers;