import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Progress, Tooltip } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class ImportCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progressUploadFile: 0,
            displayErrorFileMsg: false,
            memberId: this.props.memberId,
            excelRow: 6,
            errorCount: 0,
            data: [],//errors
            succCount: 0,
            successData: [],
            loading: 'sd',
            toolTipArray: [],
            checkedRow: [],
            checkedDataFlag: 0,
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    handleValidSubmit(event) {
        var data = new FormData();
        var importCustomersData = document.querySelector('input[name=importCustomers]').files[0];
        // console.log(importCustomersData);
        this.setState({
            loading: 1
        })
        if (importCustomersData != undefined && importCustomersData != null && importCustomersData != '') {
            data.append("data", importCustomersData);
            data.append("name", 'data');
            data.append("type", 'importCustomers');
            data.append("memberId", this.state.memberId);
            data.append("countrow", this.state.excelRow);
            data.append("userId", localStorage.getItem('USER_ID'));
            data.append("token", localStorage.getItem('USER_TOKEN'));
            data.append("checkedDataFlag", this.state.checkedDataFlag);
            fetch(window.API_URL_DATA, {
                // mode: 'no-cors',
                method: "POST",
                body: data,
                headers: {
                    Accept: 'application/json',
                },
            }).then(function (response) {
                if (!response.ok) {
                }
                return response.json();
            }).then(data => {
                if (data !== "Nothing found") {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.props.handleImportAction();
                    } else {
                        this.setState({
                            errorCount: data['errorCount'],
                            data: data['errors'],
                            succCount: data['succCount'],
                            successData: data['success'],
                            loading: 0,
                            checkedDataFlag: data['checkedDataFlag'] == undefined ? 0 : data['checkedDataFlag'],
                        })
                    }
                }
            });
        } else {
            this.setState({ displayErrorFileMsg: true, progressUploadFile: 0 });
        }
    }

    handleFileChange = (event) => {
        var fileReader = new FileReader();
        const scope = this
        if (event.target.files[0].type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")) {
            this.setState({ displayErrorFileMsg: false });
            this.setState({ progressUploadFile: 10 });
            fileReader.readAsBinaryString(event.target.files[0]);
            fileReader.onprogress = function (data) {
                if (data.lengthComputable) {
                    var progress = parseInt(((data.loaded / data.total) * 100), 10);
                    scope.setState({ progressUploadFile: progress });
                }
            }
        } else {
            this.setState({ displayErrorFileMsg: true, progressUploadFile: 0 });
        }
        this.setState({
            importCustomers: event.target.files[0]['name']
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        this.setState({
            [name]: value,
        })
    }
    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }

    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }
    getTooltibOver = (e, index) => {
        let tempArr = [...this.state.toolTipArray];
        tempArr[index] = true;
        this.setState({
            toolTipArray: tempArr
        })
    }
    getTooltibOut = (e, index) => {
        let tempArr = [...this.state.toolTipArray];
        tempArr[index] = false;
        this.setState({
            toolTipArray: tempArr
        })
    }
    getErrorMsg = (errorMsg, index) => {
        if (errorMsg.length >= 47) {
            return (
                <div>
                    {errorMsg.substring(0, 14)} ...
                    <a style={{ fontSize: 12, color: '#000' }} id={'toolTip' + index} className='pointer-cursor' onMouseOver={(e) => this.getTooltibOver(e, index)} onMouseOut={(e) => this.getTooltibOut(e, index)}>
                        عرض المزيد
                    </a>
                    {(errorMsg != undefined && errorMsg != '' && errorMsg != null) &&
                        <Tooltip
                            className="ml-3"
                            style={{ zIndex: 2000 }}
                            placement="right"
                            isOpen={this.state.toolTipArray[index] == undefined ? false : this.state.toolTipArray[index]}
                            target={'toolTip' + index}
                            autohide={false}
                        >
                            {<span style={{ zIndex: 100 }} className='report-tooltip-span'>
                                <p>{errorMsg}</p>
                            </span>}
                        </Tooltip>
                    }
                </div>
            );
        } else {
            return errorMsg;
        }
    }

    checkRow = (actionType, rowId) => {
        if (actionType == 'all') {
            var selectedArray = this.state.checkedRow;
            if (document.getElementById("customCheck-0").checked == true) {
                if (this.state.data.length > 0) {
                    this.state.data.map((rowData, index) => {
                        if (this.state.checkedRow.indexOf(rowData['lineNo']) <= -1) {
                            selectedArray.push(rowData['lineNo']);
                        }
                    })
                }
                if (this.state.successData.length > 0) {
                    this.state.successData.map((rowData, index) => {
                        if (this.state.checkedRow.indexOf(rowData['lineNo']) <= -1) {
                            selectedArray.push(rowData['lineNo']);
                        }
                    })
                }
            } else {
                if (this.state.data.length > 0) {
                    this.state.data.map((rowData, index) => {
                        if (this.state.checkedRow.indexOf(rowData['lineNo']) > -1) {
                            var index = selectedArray.indexOf(rowData['lineNo']);
                            selectedArray.splice(index, 1);
                        }
                    })
                }
                if (this.state.successData.length > 0) {
                    this.state.successData.map((rowData, index) => {
                        if (this.state.checkedRow.indexOf(rowData['lineNo']) > -1) {
                            var index = selectedArray.indexOf(rowData['lineNo']);
                            selectedArray.splice(index, 1);
                        }
                    })
                }
            }
            this.setState({ checkedRow: selectedArray });
        } else {
            if (this.state.checkedRow.indexOf(rowId) > -1) {
                var selectedArray = this.state.checkedRow;
                var index = selectedArray.indexOf(rowId);
                selectedArray.splice(index, 1);
            } else {
                var selectedArray = this.state.checkedRow;
                selectedArray.push(rowId);
            }
            this.setState({ checkedRow: selectedArray });
        }
    }

    saveCheckedData = () => {
        let checkedData = [];
        const scop = this;
        if (scop.state.data.length > 0) {
            scop.state.data.map((rowData, index) => {
                if (scop.state.checkedRow.indexOf(rowData['lineNo']) > -1) {
                    checkedData.push(rowData['data']);
                }
            })
        }
        if (scop.state.successData.length > 0) {
            scop.state.successData.map((rowData, index) => {
                if (scop.state.checkedRow.indexOf(rowData['lineNo']) > -1) {
                    checkedData.push(rowData['data']);
                }
            })
        }
        scop.setState({
            errorCount: 0,
            data: [],//errors
            succCount: 0,
            successData: [],
            loading: 1,
        })
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'checkData': checkedData,
                'name': 'data',
                'type': 'importCustomers',
                'memberId': scop.state.memberId,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'checkedDataFlag': scop.state.checkedDataFlag,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                if (data['resNo'] == 0) {
                    this.props.handleImportAction();
                } else {
                    this.setState({
                        errorCount: data['errorCount'],
                        data: data['errors'],
                        succCount: data['succCount'],
                        successData: data['success'],
                        loading: 0,
                        checkedDataFlag: data['checkedDataFlag'] == undefined ? 0 : data['checkedDataFlag'],
                    })
                }
            }
        });
    }
    render() {

        var mainCol = []
        var dataCol = []
        var actionCol = []

        if (this.state.loading == 0 && this.state.data != undefined && this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {
                let errorMsg = rowData['errMsg'];
                let errors = rowData['data'];
                let lineNo = rowData['lineNo'];
                mainCol.push(
                    <div key={index} className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        {this.state.checkedDataFlag == 1 &&
                            <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-2 col-sm-2 col-md-2 col-lg-2 table-body-row main-color-1 font-weight-500 overflow-text-length pt-2 pb-0')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"rowCheck-" + lineNo} onClick={() => this.checkRow('byId', lineNo)} readOnly checked={this.state.checkedRow.indexOf(lineNo) > -1 ? true : false} />
                                    <label className="custom-control-label" htmlFor={"rowCheck-" + lineNo}>&nbsp;</label>
                                </div>
                            </div>
                        }
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-3 col-sm-3 col-md-3 col-lg-3 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {lineNo}
                        </div>

                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + (this.state.checkedDataFlag == 1 ? ('col-7 col-sm-7 col-md-7 col-lg-7 table-body-row main-color-1 font-weight-500 overflow-text-length') : ('col-9 col-sm-9 col-md-9 col-lg-9 table-body-row main-color-1 font-weight-500 overflow-text-length'))} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[5] == undefined || errors[5] == '' ? '-' : errors[5]}
                        </div>
                    </div>
                )

                var checkEvenRow = index % 2;
                dataCol.push(
                    <div className='row flex-row flex-nowrap' key={index} >
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[1] == undefined || errors[1] == '' ? '-' : errors[1]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[2] == undefined || errors[2] == '' ? '-' : errors[2]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[3] == undefined || errors[3] == '' ? '-' : errors[3]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[4] == undefined || errors[4] == '' ? '-' : errors[4]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[6] == undefined || errors[6] == '' ? '-' : errors[6]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[7] == undefined || errors[7] == '' ? '-' : errors[7]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[8] == undefined || errors[8] == '' ? '-' : errors[8]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[9] == undefined || errors[9] == '' ? '-' : errors[9]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[10] == undefined || errors[10] == '' ? '-' : errors[10]}
                        </div>
                    </div>
                )
                actionCol.push(
                    <div className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('login-error-msg col-12 col-sm-4 col-md-12 col-lg-12 table-body-row text-right action-btn-view')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} >
                            {this.getErrorMsg(errorMsg, index)}
                        </div>
                    </div>
                )

            })
        }
        if (this.state.loading == 0 && this.state.successData != undefined && this.state.successData.length > 0) {
            this.state.successData.map((rowData, key) => {
                let index = key + ((this.state.data != null && this.state.data.length > 0) ? this.state.data.length : 0);
                let errors = rowData['data'];
                let lineNo = rowData['lineNo'];
                mainCol.push(
                    <div key={index} className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        {this.state.checkedDataFlag == 1 &&
                            <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-2 col-sm-2 col-md-2 col-lg-2 table-body-row main-color-1 font-weight-500 overflow-text-length pt-2 pb-0')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"rowCheck-" + lineNo} onClick={() => this.checkRow('byId', lineNo)} readOnly checked={this.state.checkedRow.indexOf(lineNo) > -1 ? true : false} />
                                    <label className="custom-control-label" htmlFor={"rowCheck-" + lineNo}>&nbsp;</label>
                                </div>
                            </div>
                        }
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-3 col-sm-3 col-md-3 col-lg-3 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {lineNo}
                        </div>

                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-7 col-sm-7 col-md-7 col-lg-7 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[5] == undefined || errors[5] == '' ? '-' : errors[5]}
                        </div>
                    </div>
                )

                var checkEvenRow = index % 2;
                dataCol.push(
                    <div className='row flex-row flex-nowrap' key={index} >
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[1] == undefined || errors[1] == '' ? '-' : errors[1]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[2] == undefined || errors[2] == '' ? '-' : errors[2]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[3] == undefined || errors[3] == '' ? '-' : errors[3]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {errors[4] == undefined || errors[4] == '' ? '-' : errors[4]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[6] == undefined || errors[6] == '' ? '-' : errors[6]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[7] == undefined || errors[7] == '' ? '-' : errors[7]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[8] == undefined || errors[8] == '' ? '-' : errors[8]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[9] == undefined || errors[9] == '' ? '-' : errors[9]}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-4 col-lg-4 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {errors[10] == undefined || errors[10] == '' ? '-' : errors[10]}
                        </div>
                    </div>
                )
                actionCol.push(
                    <div className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        {this.state.checkedDataFlag == 0 ?
                            <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('success-msg col-12 col-sm-4 col-md-12 col-lg-12 table-body-row text-right action-btn-view')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} >
                                تم الاستيراد بنجاح
                            </div>
                            :
                            <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('success-msg col-12 col-sm-4 col-md-12 col-lg-12 table-body-row text-right action-btn-view')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} >
                                جميع البيانات صحيحة
                            </div>
                        }
                    </div>
                )

            })
        }
        return (
            <div style={{ zIndex: 130 }}>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'>استيراد
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn mr-5' onClick={this.props.handleCloseImportSideBar}></i>
                            </Col>
                        </Row>
                    </div>


                    <div className='sidebar-body-view'>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            {((this.state.errorCount <= 0 && this.state.succCount <= 0) && this.state.checkedDataFlag != 1) &&
                                <div className='mb-3'>
                                    <label>قائمة الناخبين</label>
                                    <span className={'fileInput-span col-md-12 mb-3'}>
                                        <i className="fas fa-file-upload"></i> <span className='pointer-cursor task-action-form-fields-font'>تحميل</span>
                                        <input type="file" className='fileInput-width fileInput-opacity' onChange={(e) => this.handleFileChange(e)} name='importCustomers'></input>
                                    </span>
                                    {(this.state.progressUploadFile != undefined && this.state.progressUploadFile > 0) &&
                                        <Progress striped color="success" value={this.state.progressUploadFile} className='mb-1 mt-1'>{this.state.progressUploadFile} %</Progress>
                                    }
                                    {(this.state.displayErrorFileMsg == true) &&
                                        <div className='invalid-feedback d-block mb-1 mt-n3'>قم بتحميل ملف excel</div>
                                    }
                                    <AvField name="excelRow" type='number' label='رقم سطر بداية القراءة من الملف' placeholder='ادخل رقم سطر بداية القراءة من الملف' value={this.state.excelRow} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                </div>
                            }
                            {(this.state.loading == 1) &&
                                <div className="data-loading-spinner">Loading...</div>
                            }
                            {(this.state.loading == 0 && this.state.data != undefined && this.state.data.length <= 0 && this.state.successData != undefined && this.state.successData.length <= 0) &&
                                <Row>
                                    <Col md={12} className='text-center no-data-available-view'>
                                        <img src='/media/electra/no-data-icon-68.png' />
                                        <p>لا يوجد بيانات  .&nbsp;.&nbsp; !</p>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && (this.state.errorCount > 0 || this.state.succCount > 0)) &&
                                <div>
                                    {this.state.checkedDataFlag == 0 &&
                                        <div className='success-error pr-2 mb-1'>تم استيراد  <span style={{ color: '#1118f7' }}>{this.state.succCount == undefined || this.state.succCount == '' ? 0 : this.state.succCount}</span> بنجاح</div>
                                    }
                                    {this.state.checkedDataFlag == 0 &&
                                        <div className='login-error-msg pr-2 mb-5' style={{ fontSize: 19 }}>يوجد خطأ في البيانات التالية وعددها : {this.state.errorCount}</div>
                                    }
                                    {this.state.checkedDataFlag == 1 &&
                                        <div className='pr-2 mb-5 main-color-1' style={{ fontSize: 19 }}>يرجى اختيار الناخبين الذين تريد تخزينهم :</div>
                                    }
                                    <Row className='m-1 mr-4 ml-4 mb-4 overflow-auto' style={{ maxHeight: 463 }}>
                                        <div className='col-md-3 col-sm-3 col-3 col-lg-3'>

                                            <div className="row flex-row flex-nowrap table-header-row" >
                                                {this.state.checkedDataFlag == 1 &&
                                                    <div className=" overflow-text-length d-inline-flex col-2 p-0">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customCheck-0" onClick={() => this.checkRow('all', '')} />
                                                            <label className="custom-control-label" htmlFor="customCheck-0">&nbsp;</label>
                                                        </div>
                                                    </div>
                                                }
                                                <div className=" overflow-text-length d-inline-flex col-3 p-0">
                                                    <div>رقم السطر</div>
                                                </div>
                                                <div className=" overflow-text-length col-7">
                                                    <div>الرقم الوطني</div>
                                                </div>
                                            </div>
                                            {mainCol}
                                        </div>
                                        <div className='col-md-7 col-sm-7 col-7 col-lg-7 pr-0' style={{ 'overflowX': 'auto', 'overflowY': 'hidden', 'width': '100%' }}>
                                            <div className="row flex-row flex-nowrap">
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>الاسم</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>الاب</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>الجد</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>العائلة</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>رقم الهاتف</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>العنوان</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>العمل</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>Facebook</div>
                                                <div className='col-12 col-sm-4 col-md-4 col-lg-4 table-header-row overflow-text-length'>Whatsapp</div>
                                            </div>
                                            {dataCol}
                                        </div>
                                        <div className='col-md-2 col-sm-2 col-2 col-lg-2'>
                                            <div className="row flex-row flex-nowrap" >
                                                <div className="col-12 col-sm-4 col-md-12 col-lg-12 table-header-row overflow-text-length text-right pl-4">الخطأ</div>
                                            </div>
                                            {actionCol}
                                        </div>
                                    </Row>
                                </div>
                            }

                            {this.state.loading != 1 &&
                                <div className="text-left">
                                    {((this.state.errorCount <= 0 && this.state.succCount <= 0) && this.state.checkedDataFlag != 1) &&
                                        <button type="submit" className="btn form-save-btn mr-1 ml-1 mr-2"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                    }
                                    {(this.state.checkedDataFlag == 1 && ((this.state.data != undefined && this.state.data.length > 0) || (this.state.successData != undefined && this.state.successData.length > 0))) &&
                                        <button type="button" className="btn form-save-btn mr-1 ml-1 mr-2" onClick={(e) => this.saveCheckedData(e)}><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                    }
                                    <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseImportSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> إلغاء</button>
                                </div>
                            }
                        </AvForm>


                    </div>


                </div>
            </div>
        )
    }
}
export default ImportCustomer;