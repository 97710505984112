import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
// import SmartInput from 'smart-input-react';
import '../../../../node_modules/react-phone-number-input/style.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';

class SubDepartmentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.subDepartmentData['id'],
            phone: this.props.subDepartmentData['small_desc'],
            admin: this.props.status == 'add' ? localStorage.getItem('USER_ID') : this.props.subDepartmentData['adminType'],
            allAdminData: this.props.allAdminData,
            parent: this.props.parent,
            lastChildFlag: this.props.subDepartmentData['featured'] == undefined || this.props.subDepartmentData['featured'] == null ? 0 : this.props.subDepartmentData['featured'],
            name: this.props.subDepartmentData['keyword_SEO'],
            sname: this.props.subDepartmentData['desc_SEO'],
            tname: this.props.subDepartmentData['countries'],
            lname: this.props.subDepartmentData['location'],
            nationalId: this.props.subDepartmentData['description'],

            address: this.props.subDepartmentData['address'],
            job: this.props.subDepartmentData['job'],
            facebook: this.props.status == 'add' ? '' : this.props.subDepartmentData['fb'],
            whatsapp: this.props.status == 'add' ? '' : this.props.subDepartmentData['whatsapp'],
            area: this.props.subDepartmentData['areaId'],
            subArea: this.props.subDepartmentData['subAreaId'],

            allArea: this.props.allArea,
            allSpecificArea: [],

            phoneRequired: false,
            submitFaild: '',
            childCount: this.props.childCount,
            errorName: '',
            errorType: '',
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }
    componentWillMount() {
        if (this.state.area != undefined && this.state.area != null) {
            this.getSpecificArea();
        }
    }
    handleValidSubmit(event) {
        this.setState({ phoneRequired: false, submitFaild: '' })
        var phoneHaveValue = 0
        var type = this.props.status == 'add' ? 'addDataToPost' : 'editDataInPost';
        if (this.state.phone != '' && this.state.phone != undefined && this.state.phone != undefined) {
            if (this.state.phone.toString().length == 13) {
                if (this.state.phone.startsWith("+9627")) {
                    phoneHaveValue = 1
                }

            }

        } else if (this.state.lastChildFlag == 0 && (this.state.phone == '' || this.state.phone == undefined)) {
            phoneHaveValue = 1
        }
        if (phoneHaveValue == 1) {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': type,
                    'seoKeyword': this.state.name,
                    'catId': 2,
                    'smallDesc': this.state.phone,
                    'lang': 1,
                    'adminId': this.state.admin,
                    'parent': this.state.parent,
                    'id': this.state.id,
                    'featured': this.state.lastChildFlag,
                    'seoDesc': this.state.sname,
                    'countries': this.state.tname,
                    'googleMap': this.state.lname,
                    'desc': this.state.nationalId,

                    'address': this.state.address,
                    'job': this.state.job,
                    'fb': this.state.facebook,
                    'whatsapp': this.state.whatsapp,
                    'areaId': this.state.area,
                    'subAreaId': this.state.subArea,

                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['error'] != undefined && data['error'] == 1) {
                        this.setState({
                            errorName: data['errorMSG'],
                            errorType: data['errorType'],
                        })
                    } else {
                        this.props.handleFormAction(this.props.status);
                    }
                }
            });
        } else {
            if (phoneHaveValue == 0) {
                this.setState({ phoneRequired: true })
            }

        }
    }
    getCustomerData(nationalId) {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCustomers',
                'title': nationalId,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                if (data != '' && data != undefined) {
                    this.setState({
                        name: data[0]['name'],
                        phone: data[0]['phoneNumber'],
                        sname: data[0]['secondName'],
                        tname: data[0]['thirdName'],
                        lname: data[0]['fourthName']
                    })
                }
            }
        });
    }
    getAllAdminOptions = (data, index) => {
        return (<option value={data['userId']} key={index} >{data['fname']} {data['lname']}</option>)
    }
    handleInputChangePhone(event, fieldName) {
        var name = fieldName
        var value = event;
        this.setState(prevState => ({
            phone: name,
            phoneRequired: false,
            submitFaild: ''
        }
        ))
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            phoneRequired: false,
            submitFaild: ''
        }, function () {
            if (name == 'nationalId' && value.toString().length == 10) {
                this.getCustomerData(value);
            } else if (name == 'area') {
                this.getSpecificArea();
            }
        })
    }
    validateIdNumber = (value, ctx, input, cb) => {
        if (value != '' && value.toString().length < 10 && this.state.lastChildFlag == 1) {
            return false
        } else {
            return true
        }
    }
    validateIdNumberLengh = (event, ctx, input, cb) => {
        const target = event.target;
        const value = target.value;
        if (value.toString().length == 10) {
            event.preventDefault();
        }
    }
    setUpOptionsList(data, type, index) {
        if (type == 'allArea') {
            return (<option value={data.id} key={'area-' + index}>{data.name}</option>)
        } else if (type == 'allSpecificArea') {
            return (<option value={data.id} key={'specificArea-' + index}>{data.name} </option>)
        }
    }
    getSpecificArea() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getSpecificArea',
                'areaId': this.state.area,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    allSpecificArea: data
                });
            }
        });
    }
    render() {
        return (
            <div>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'> نموذج {this.props.status == 'add' ? 'الإضافة' : 'التعديل'}
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn mr-5' onClick={this.props.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>

                    <div className='sidebar-body-view'>
                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <Row>
                                <Col md={6} className='pl-5 pr-5'>
                                    <label className={this.props.status == 'edit' && this.state.lastChildFlag == 0 && this.state.childCount > 0 ? 'mb-0' : ''}>نوع القسم *</label><br />
                                    {this.props.status == 'edit' && this.state.lastChildFlag == 0 && this.state.childCount > 0 &&
                                        <span style={{ font: 12, color: '#fd397a' }}>* ملاحظة : هذا القسم غير فارغ ,لا يمكنك تغيير نوعه حالياُ </span>
                                    }
                                    <AvField type="select" name="lastChildFlag"
                                        disabled={this.props.status == 'edit' && this.state.lastChildFlag == 0 && this.state.childCount > 0 ? true : false}
                                        value={this.state.lastChildFlag} required onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر نوع القسم</option>
                                        <option value={0}> مركز / لجنة </option>
                                        <option value={1}> صاحب كشف </option>
                                    </AvField>
                                    <AvField name="name" className={this.state.errorName != '' ? 'phoneErrorStyle' : ''} label='الاسم الأول / اسم المركز اواللجنة *' placeholder='ادخل الاسم الأول / اسم المركز اواللجنة' value={this.state.name} required onChange={this.handleInputChange} errorMessage={"الرجاء ادخال قيمة صحيحة"} />
                                    {(this.state.errorName != '' && this.state.errorType == 1) && <div className="phone-error-msg mt-n3">{this.state.errorName}</div>}
                                    <AvField name="sname" label={this.state.lastChildFlag == 0 ? 'الاسم الثاني' : 'الاسم الثاني *'} placeholder=' ادخل الاسم الثاني ' value={this.state.sname} required={this.state.lastChildFlag == 0 ? false : true} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <div className={this.state.phoneRequired == true ? 'custom-form-phone-view phoneErrorStyle' : 'custom-form-phone-view'}>
                                        <label>{this.state.lastChildFlag == 0 ? 'رقم هاتف المركز / اللجنة ' : 'رقم هاتف صاحب الكشف *'}</label>
                                        <PhoneInput
                                            maxLength="16"
                                            defaultCountry="JO"
                                            international
                                            placeholder={this.state.lastChildFlag == 0 ? 'ادخل رقم هاتف المركز / اللجنة ' : ' ادخل رقم هاتف صاحب الكشف'}
                                            // inputComponent={SmartInput}
                                            name={'phone'}
                                            value={this.state.phone}
                                            onChange={this.handleInputChangePhone.bind(this, 'phone')}
                                            className='mt-1 mb-4 phone'
                                            required
                                        />
                                        {this.state.phoneRequired == true && <div className="phone-error-msg">الرجاء ادخال قيمة صحيحة</div>}
                                    </div>

                                    <AvField name="address" label={this.state.lastChildFlag == 0 ? ' العنوان' : 'العنوان '} placeholder=' ادخل العنوان ' value={this.state.address} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />

                                    <AvField name="facebook" label={this.state.lastChildFlag == 0 ? 'Facebook' : 'Facebook '} placeholder='أدخل Facebook' value={this.state.facebook} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    {/* <AvField type="select" name="facebook" label={this.state.lastChildFlag == 0 ? 'Facebook' : 'Facebook *'} value={this.state.facebook} required={this.state.lastChildFlag == 0 ? false : true} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة">
                                        <option value={1} key={1}>{'نعم'} </option>
                                        <option value={2} key={2}>{'لا'} </option>
                                    </AvField> */}

                                    <AvField type="select" name="area" label={this.state.lastChildFlag == 0 ? 'المنطقة' : 'المنطقة '} value={this.state.area} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر المنطقة</option>
                                        {this.state.allArea.map((data, input) => this.setUpOptionsList(data, 'allArea', input))}
                                    </AvField>
                                </Col>

                                <Col md={6} className='pl-5 pr-5'>
                                    <AvField name="nationalId" onKeyPress={this.validateIdNumberLengh.bind(this)} validate={{ async: this.validateIdNumber }} type='number' label={this.state.lastChildFlag == 0 ? 'الرقم الوطني' : 'الرقم الوطني'} placeholder=' ادخل الرقم الوطني ' value={this.state.nationalId} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة"  />
                                    {(this.state.errorName != '' && this.state.errorType == 2) && <div className="phone-error-msg mt-n3">{this.state.errorName}</div>}
                                    <AvField name="tname" label={this.state.lastChildFlag == 0 ? 'الاسم الثالث' : 'الاسم الثالث *'} placeholder=' ادخل الاسم الثالث ' value={this.state.tname} required={this.state.lastChildFlag == 0 ? false : true} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField name="lname" label={this.state.lastChildFlag == 0 ? 'الاسم الاخير' : 'الاسم الاخير *'} placeholder=' ادخل الاسم الاخير ' value={this.state.lname} required={this.state.lastChildFlag == 0 ? false : true} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField type="select" name="admin" label={this.state.lastChildFlag == 0 ? 'الشخص المسؤول' : "الشخص المسؤول *"} value={this.state.admin} required={this.state.lastChildFlag == 0 ? false : true} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        {this.state.allAdminData.map((data, index) => this.getAllAdminOptions(data, index))}
                                    </AvField>

                                    <AvField name="job" label={this.state.lastChildFlag == 0 ? ' العمل' : 'العمل '} placeholder=' ادخل العمل ' value={this.state.job} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />

                                    <AvField name="whatsapp" label={this.state.lastChildFlag == 0 ? 'Whatsapp' : 'Whatsapp '} placeholder='أدخل Whatsapp' value={this.state.whatsapp} onChange={this.handleInputChange} r errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    {/* <AvField type="select" name="whatsapp" label={this.state.lastChildFlag == 0 ? 'Whatsapp' : 'Whatsapp *'} value={this.state.whatsapp} required={this.state.lastChildFlag == 0 ? false : true} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة">
                                        <option value={1} key={1}>{'نعم'} </option>
                                        <option value={2} key={2}>{'لا'} </option>
                                    </AvField> */}

                                    <AvField type="select" name="subArea" label={this.state.lastChildFlag == 0 ? 'معلم بارز' : 'معلم بارز '} value={this.state.subArea} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" disabled={this.state.area == undefined || this.state.area == null ? true : false}>
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر معلم بارز</option>
                                        {this.state.allSpecificArea.map((data, input) => this.setUpOptionsList(data, 'allSpecificArea', input))}
                                    </AvField>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='pl-5 pr-5'>
                                    <div className="text-left">
                                        <button type="submit" className="btn form-save-btn mr-1 ml-1"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                        <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> الغاء</button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </div>
            </div >
        )
    }

}
export default SubDepartmentForm;