import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import PhoneInput from 'react-phone-number-input';
import '../../../../node_modules/react-phone-number-input/style.css';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckbox, AvCheckboxGroup, AvRadioGroup, AvRadio } from 'availity-reactstrap-validation';
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

var today = new Date();
var date = today.getFullYear() - 18 + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

class CustomersForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.customerData['id'],
            idNumber: this.props.customerData['idNumber'],
            name: this.props.customerData['name'] == undefined ? '' : this.props.customerData['name'],
            phoneNumber: this.props.customerData['phoneNumber'],
            phoneNumber2: this.props.customerData['phoneNumber2'],
            address: this.props.customerData['address'],
            secondName: this.props.customerData['secondName'],
            thirdName: this.props.customerData['thirdName'],
            fourthName: this.props.customerData['fourthName'],
            schoolName: this.props.customerData['schoolName'],
            schoolId: this.props.customerData['schoolId'],
            regionId: this.props.customerData['regionId'],
            specificThingId: this.props.customerData['specificThingId'],
            memberId: this.props.customerData['memberId'] == undefined ? this.props.memberId : this.props.customerData['memberId'],
            driverId: this.props.customerData['driverId'],
            centerId: this.props.customerData['stationId'] == undefined ? this.props.stationId : this.props.customerData['stationId'],
            gender: this.props.customerData['gender'],
            birthDate: this.props.customerData['birthDate'] == '0000-00-00' ? '' : this.props.customerData['birthDate'],
            idNumberNotExist: false,
            idNumberMatch: (this.props.status == 'edit' ? true : false),
            allMembers: [],
            allDepartement: this.props.allDepartement,
            allArea: this.props.allArea,
            allSpecificArea: [],
            phoneRequired: false,
            phoneRequired2: false,
            submitFaild: '',
            allDriver: [],
            allMemberList: [],
            multiValue: [],
            memberIdRequired: false,
            departementFlag: this.props.departementFlag,
            work: this.props.customerData['work'],
            facebook: this.props.customerData['facebook'],
            whatsapp: this.props.customerData['whatsapp'],
            addAsCantVoet: false,
            canVoteError: false,
            errorCustomerData: [],
            errorMemberName: ''
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({ addAsCantVoet: false })
        if (this.state.memberId != '' && this.state.memberId != undefined && this.state.memberId != null) {
            // this.getDepartementByMemberId()
        }
        if (this.state.regionId != '' && this.state.regionId != undefined && this.state.regionId != null) {
            this.getSpecificArea()
        }
        if (this.state.centerId != '' && this.state.centerId != undefined && this.state.centerId != null) {
            this.getMembersByDepId()
            let multiValue = {}
            multiValue['id'] = this.state.memberId
            multiValue['name'] = this.props.customerData['memberName']
            this.setState({ multiValue: multiValue })
        }
        this.getAllDriver()

    }

    getAllDriver() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getPointOfMovement',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    allDriver: data
                });
            }
        });
    }

    getSpecificArea() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getSpecificArea',
                'areaId': this.state.regionId,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    allSpecificArea: data
                });
            }
        });
    }

    getDepartementByMemberId() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getBreadcrumb',
                'id': this.state.memberId,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    allDepartement: data
                });
            }
        });
    }

    handleValidSubmit(event) {
        this.setState({ phoneRequired: false, phoneRequired2: false, submitFaild: '', memberIdRequired: false })
        var canSave = 1

        if (this.state.phoneNumber != '' && this.state.phoneNumber != undefined && this.state.phoneNumber != undefined) {
            if (this.state.phoneNumber.startsWith("+962")) {
            } else {
                this.setState({ phoneRequired: true })
                canSave = 0
            }
        }

        if (this.state.phoneNumber2 != '' && this.state.phoneNumber2 != undefined && this.state.phoneNumber2 != undefined) {
            if (this.state.phoneNumber2.startsWith("+962")) {
            } else {
                this.setState({ phoneRequired2: true })
                canSave = 0
            }
        }

        if (this.state.memberId == '' || this.state.memberId == undefined || this.state.memberId == null) {
            this.setState({ memberIdRequired: true })
            canSave = 0
        }



        if (canSave == 1) {
            if (this.state.addAsCantVoet == true) {
                fetch(window.API_URL_DATA, {
                    method: 'POST',
                    body: JSON.stringify({
                        'type': 'addCustomer',
                        'idNumber': this.state.idNumber,
                        'name': this.state.name,
                        'secondName': this.state.secondName,
                        'thirdName': this.state.thirdName,
                        'fourthName': this.state.fourthName,
                        'address': this.state.address,
                        'phoneNumber': this.state.phoneNumber,
                        'phoneNumber2': this.state.phoneNumber2,
                        'phoneNumber2': this.state.phoneNumber2,
                        'schoolId': this.state.schoolId,
                        'specificThingId': this.state.specificThingId,
                        'regionId': this.state.regionId,
                        'memberId': this.state.memberId,
                        'centerId': this.state.centerId,
                        'driverId': this.state.driverId,
                        'userId': localStorage.getItem('USER_ID'),
                        'token': localStorage.getItem('USER_TOKEN'),
                        'work': this.state.work,
                        'facebook': this.state.facebook,
                        'whatsapp': this.state.whatsapp,
                        'gender': this.state.gender,
                        'birthDate': this.state.birthDate,
                        'canvote': 0
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                    },
                }).then(function (response) {
                    return response.json();
                }).then(data => {
                    if (typeof data !== 'Nothing found') {
                        if (data == 'tokenExpired') {
                            localStorage.setItem('USER_ID', '');
                            window.location.href = "/logout";
                        }
                        // console.log(data);
                        if (data['resNo'] == 0) {
                            this.props.handleFormAction(this.props.status);
                        } else {
                            this.setState({ submitFaild: data['resMsg'] })
                        }
                    }
                });
            } else {
                fetch(window.API_URL_DATA, {
                    method: 'POST',
                    body: JSON.stringify({
                        'type': 'addCustomer',
                        'idNumber': this.state.idNumber,
                        'name': this.state.name,
                        'secondName': this.state.secondName,
                        'thirdName': this.state.thirdName,
                        'fourthName': this.state.fourthName,
                        'address': this.state.address,
                        'phoneNumber': this.state.phoneNumber,
                        'phoneNumber2': this.state.phoneNumber2,
                        'phoneNumber2': this.state.phoneNumber2,
                        'schoolId': this.state.schoolId,
                        'specificThingId': this.state.specificThingId,
                        'regionId': this.state.regionId,
                        'memberId': this.state.memberId,
                        'centerId': this.state.centerId,
                        'driverId': this.state.driverId,
                        'userId': localStorage.getItem('USER_ID'),
                        'token': localStorage.getItem('USER_TOKEN'),
                        'work': this.state.work,
                        'facebook': this.state.facebook,
                        'whatsapp': this.state.whatsapp,
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                    },
                }).then(function (response) {
                    return response.json();
                }).then(data => {
                    if (typeof data !== 'Nothing found') {
                        if (data == 'tokenExpired') {
                            localStorage.setItem('USER_ID', '');
                            window.location.href = "/logout";
                        }
                        // console.log(data);
                        if (data['resNo'] == 0) {
                            this.props.handleFormAction(this.props.status);
                        } else {
                            this.setState({ submitFaild: data['resMsg'] })
                        }
                    }
                });
            }
        }
    }


    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            submitFaild: ''
        }, function () {
            if (name == 'idNumber') {
                this.setState({ idNumberNotExist: false }, function () {
                    this.getCustomerDataByidNumber()
                })
            } else if (name == 'memberId') {
                // this.getDepartementByMemberId()
            } else if (name == 'regionId') {
                this.getSpecificArea()
            } else if (name == 'centerId') {
                this.getMembersByDepId()
                this.setState({ multiValue: [], memberId: '', memberIdRequired: false })
            }
        })


    }

    handleInputChangePhone(event, fieldName) {

        var value = fieldName
        var name = event;


        this.setState(prevState => ({
            [name]: value,
            phoneRequired: false,
            submitFaild: ''
        }
        ))
    }

    validateIdNumber = (value, ctx, input, cb) => {
        if (value.toString().length < 10) {
            return false
        } else {
            return true
        }
    }
    validateIdNumberLengh = (event, ctx, input, cb) => {
        const target = event.target;
        const value = target.value;
        if (value.toString().length == 10) {
            event.preventDefault();
        }
    }
    getCustomerDataByidNumber() {
        if (this.state.idNumberMatch != true && this.state.idNumber != undefined && this.state.idNumber.toString().length == 10) {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getCustomers',
                    'getType': 'limit',
                    'title': this.state.idNumber,
                    'page': 1,
                    'limit': 1,
                    'sortType': '',
                    'sort': '',
                    'schoolId': '',
                    'specificThingId': '',
                    'regionId': '',
                    'memberId': '',
                    'driverId': '',
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'checkCustomerFormFlag': 1,
                    'canVote': -1,
                    'checkAll': 1
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }

                    if (data.length > 0) {
                        if (data[0]['canVote'] != 3) {
                            this.setState({
                                canVoteError: true,
                                errorCustomerData: data[0],
                                idNumberNotExist: false,
                                idNumberMatch: true,
                                centerName: data[0]['centerName'],
                                stationName: data[0]['stationName'],
                            }, function () {
                                if (data[0]['memberId'] != '' && data[0]['memberId'] != undefined && data[0]['memberId'] != null) {
                                    this.getMemberById(data[0]['memberId']);

                                }
                            })
                        } else {
                            this.setState({
                                idNumberNotExist: false,
                                idNumberMatch: true,
                                id: data[0]['id'],
                                idNumber: data[0]['idNumber'],
                                name: data[0]['name'],
                                phoneNumber: data[0]['phoneNumber'],
                                phoneNumber2: data[0]['phoneNumber2'],
                                address: data[0]['address'],
                                secondName: data[0]['secondName'],
                                thirdName: data[0]['thirdName'],
                                fourthName: data[0]['fourthName'],
                                schoolName: data[0]['schoolName'],
                                regionId: data[0]['regionId'],
                                specificThingId: data[0]['specificThingId'],
                                memberId: this.state.departementFlag == true ? this.state.memberId : data[0]['memberId'],
                                driverId: data[0]['driverId'],
                                centerId: this.state.departementFlag == true ? this.state.centerId : data[0]['centerId'],
                                gender: data[0]['gender'],
                                birthDate: data[0]['birthDate'] == '0000-00-00' ? '' : data[0]['birthDate'],
                                work: data[0]['work'],
                                facebook: data[0]['facebook'],
                                whatsapp: data[0]['whatsapp'],
                                canVoteError: false,
                            }, function () {
                                // if (data[0]['memberId'] != '' && data[0]['memberId'] != undefined && data[0]['memberId'] != null) {
                                //     this.getDepartementByMemberId();
                                // }

                                if (data[0]['centerId'] != '' && data[0]['centerId'] != undefined && data[0]['centerId'] != null) {
                                    this.getMembersByDepId();
                                }

                                if (data[0]['regionId'] != '' && data[0]['regionId'] != undefined && data[0]['regionId'] != null) {
                                    this.getSpecificArea();
                                }
                            })
                        }
                    } else {
                        this.setState({ idNumberNotExist: true })
                    }
                }
            });
        }
    }

    getMembersByDepId() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllOwnerListByDepartmentId',
                'id': this.state.centerId,
                'getType': 'all',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                let allMemberList = []
                data.map((memberData, index) => {
                    allMemberList.push({
                        'id': memberData['id'],
                        'name': memberData['keyword_SEO'] + ' ' + memberData['desc_SEO'] + ' ' + memberData['countries'] + ' ' + memberData['location']
                    });
                })
                this.setState({
                    allMembers: data,
                    allMemberList: allMemberList
                });
            }
        });
    }
    getMemberById(id) {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getPostById',
                'id': id,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                if (data != undefined) {
                    this.setState({
                        errorMemberName: data['keyword_SEO'] + ' ' + data['desc_SEO'] + ' ' + data['countries'] + ' ' + data['location'],
                    });
                }
            }
        });
    }


    setUpOptionsList(data, type, index) {
        if (type == 'allMembers') {
            return (<option value={data.id} key={index}>{data.keyword_SEO} {data.desc_SEO} {data.countries} {data.location} </option>)
        } else if (type == 'allSchools') {
            return (<option value={data.id} key={'school-' + index}>{data.name}</option>)
        } else if (type == 'allArea') {
            return (<option value={data.id} key={'area-' + index}>{data.name}</option>)
        } else if (type == 'allDepartement') {
            return (<option value={data.id} key={'dep-' + index}>{data.name}</option>)
        } else if (type == 'allSpecificArea') {
            return (<option value={data.id} key={'specificArea-' + index}>{data.name} </option>)
        } else if (type == 'allDriver') {
            return (<option value={data.id} key={'driver-' + index}>{data.name} </option>)
        }
    }

    handleMultiChange = (option) => {
        this.setState(state => {
            return {
                multiValue: option,
                memberId: option['id']
            };
        }, function () {
        });
    }
    addAsCantVoetFunction = (e) => {
        this.setState({ addAsCantVoet: true, idNumberNotExist: false })
    }

    render() {

        // console.log(this.state.multiValue);
        return (
            <div>
                <div className="test-modal-style">
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'>{this.props.status == 'add' ? 'إضافة' : 'تعديل'} ناخب
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn  mr-5' onClick={this.props.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>

                    <div className='sidebar-body-view'>

                        <AvForm onValidSubmit={this.handleValidSubmit} ref={c => (this.form = c)}>
                            <Row>
                                <Col md={6} className='pl-5 pr-5'>
                                    <AvField readOnly={(this.state.idNumberMatch == true) ? true : false} disabled={(this.state.idNumberMatch == true) ? true : false} onKeyPress={this.validateIdNumberLengh.bind(this)} validate={{ async: this.validateIdNumber }} name="idNumber" disabled={this.props.status == 'add' ? false : true} type="number" label='الرقم الوطني *' placeholder='أدخل الرقم الوطني' value={this.state.idNumber} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    {this.state.idNumberNotExist == true &&
                                        <div className='login-error-msg mb-3'>الرقم الوطني غير موجود ضمن هذه الدائرة الإنتخابية , هل تريد اضافته ؟
                                            <span className='yes-btn mr-2 ml-4' onClick={(e) => this.addAsCantVoetFunction(e)}>
                                                <i className='flaticon2-check-mark form-save-btn-icon'></i>&nbsp;
                                                نعم
                                            </span>
                                            <span className='no-btn mr-2 ml-2' onClick={this.props.handleCloseSideBar}>
                                                <i className='flaticon2-delete form-cancel-btn-icon'></i>&nbsp;
                                                لا
                                            </span>
                                        </div>
                                    }
                                    {this.state.canVoteError == true &&
                                        <div className='login-error-msg mb-3'>هذا الرقم الوطني مستخدم سابقا باسم {this.state.errorCustomerData['name']} {this.state.errorCustomerData['secondName']} {this.state.errorCustomerData['thirdName']} {this.state.errorCustomerData['fourthName']}
                                            {(this.state.errorMemberName != undefined && this.state.errorMemberName != '') &&
                                                <span>&nbsp;
                                                التابع لصاحب الكشف
                                                    &nbsp;{this.state.errorMemberName}&nbsp;
                                                    الموجود ضمن لجنة
                                                    &nbsp;{this.state.centerName}&nbsp;
                                                    التابعة للمركز
                                                    &nbsp;{this.state.stationName}&nbsp;
                                                </span>
                                            }
                                        </div>
                                    }
                                    <AvField disabled={this.state.addAsCantVoet == false ? true : false} name="name" label='الاسم الأول *' placeholder='أدخل الاسم الأول' value={this.state.name} required onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField disabled={this.state.addAsCantVoet == false ? true : false} name="secondName" label={this.state.name.length >= 10 ? 'اسم العائلة ' : 'اسم الأب *'} placeholder='أدخل اسم الأب' value={this.state.secondName} required={this.state.name.length >= 10 ? false : true} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField disabled={this.state.addAsCantVoet == false ? true : false} name="thirdName" label={this.state.name.length >= 10 ? 'اسم العائلة ' : 'اسم الجد *'} placeholder='أدخل اسم الجد' value={this.state.thirdName} required={this.state.name.length >= 10 ? false : true} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField disabled={this.state.addAsCantVoet == false ? true : false} name="fourthName" label={this.state.name.length >= 10 ? 'اسم العائلة ' : 'اسم العائلة *'} placeholder='أدخل اسم العائلة' value={this.state.fourthName} required={this.state.name.length >= 10 ? false : true} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField disabled={this.state.addAsCantVoet == false ? true : false} name="schoolName" label='اسم المدرسة' placeholder='أدخل اسم المدرسة' value={this.state.schoolName} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <div className={this.state.phoneRequired == true ? 'custom-form-phone-view phoneErrorStyle' : 'custom-form-phone-view'}>
                                        <label>رقم الهاتف (1) </label>
                                        <PhoneInput
                                            maxLength="16"
                                            defaultCountry="JO"
                                            international
                                            placeholder='أدخل رقم الهاتف'
                                            name={'phoneNumber'}
                                            value={this.state.phoneNumber}
                                            onChange={this.handleInputChangePhone.bind(this, 'phoneNumber')}
                                            className='mt-1 mb-4 phone'
                                        // required
                                        />
                                        {this.state.phoneRequired == true && <div className="phone-error-msg">الرجاء ادخال قيمة صحيحة</div>}
                                    </div>
                                    <div className={this.state.phoneRequired2 == true ? 'custom-form-phone-view phoneErrorStyle' : 'custom-form-phone-view'}>
                                        <label>رقم الهاتف (2) </label>
                                        <PhoneInput
                                            maxLength="16"
                                            defaultCountry="JO"
                                            international
                                            placeholder='أدخل رقم الهاتف'
                                            name={'phoneNumber2'}
                                            value={this.state.phoneNumber2}
                                            onChange={this.handleInputChangePhone.bind(this, 'phoneNumber2')}
                                            className='mt-1 mb-4 phone'
                                            required
                                        />
                                        {this.state.phoneRequired2 == true && <div className="phone-error-msg">الرجاء ادخال قيمة صحيحة</div>}
                                    </div>
                                    <AvField max={date} name="birthDate" type='date' label='تاريخ الميلاد ' placeholder='أدخل تاريخ الميلاد' value={this.state.birthDate} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField type="select" name="gender" label=' الجنس' value={this.state.gender} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر الجنس</option>
                                        <option value='1'>ذكر</option>
                                        <option value='2'>أنثى</option>
                                    </AvField>
                                </Col>
                                <Col md={6} className='pl-5 pr-5'>

                                    <AvField name="address" label='العنوان' placeholder='أدخل العنوان' value={this.state.address} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField type="select" name="driverId" label='نقطة النقل' value={this.state.driverId} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر نقطة النقل</option> allDriver
                                        {this.state.allDriver.map((data, input) => this.setUpOptionsList(data, 'allDriver', input))}
                                    </AvField>
                                    {this.state.departementFlag != true &&
                                        <AvField type="select" name="centerId" label='المركز *' value={this.state.centerId} required onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                            <option style={{
                                                'display': 'none'
                                            }} value="" selected="selected">اختر المركز</option>
                                            {this.state.allDepartement.map((data, input) => this.setUpOptionsList(data, 'allDepartement', input))}
                                        </AvField>
                                    }
                                    {this.state.departementFlag != true &&
                                        <div className={this.state.memberIdRequired == true ? 'mb-3 memberInputRequired' : 'mb-3'}>
                                            <label>صاحب الكشف التابع له * </label>
                                            <div className='member-searchable-list'>
                                                <Picky
                                                    value={this.state.multiValue}
                                                    options={this.state.allMemberList}
                                                    onChange={this.handleMultiChange}
                                                    open={false}
                                                    valueKey="id"
                                                    labelKey="name"
                                                    multiple={false}
                                                    includeSelectAll={true}
                                                    includeFilter={true}
                                                    dropdownHeight={200}
                                                    placeholder='صاحب الكشف'
                                                    filterPlaceholder='اختر صاحب الكشف'
                                                />
                                            </div>
                                            {this.state.memberIdRequired == true && <div className="memberId-error-msg">الرجاء ادخال قيمة صحيحة</div>}
                                        </div>
                                    }
                                    {/* <AvField type="select" name="memberId" label='صاحب الكشف التابع له *' value={this.state.memberId} required onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر صاحب الكشف</option>
                                        {this.state.allMembers.map((data, input) => this.setUpOptionsList(data, 'allMembers', input))}
                                    </AvField> */}

                                    <AvField type="select" name="regionId" label='المنطقة' value={this.state.regionId} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر المنطقة</option>
                                        {this.state.allArea.map((data, input) => this.setUpOptionsList(data, 'allArea', input))}
                                    </AvField>
                                    <AvField type="select" name="specificThingId" label='معلم بارز' value={this.state.specificThingId} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر معلم بارز</option>
                                        {this.state.allSpecificArea.map((data, input) => this.setUpOptionsList(data, 'allSpecificArea', input))}
                                    </AvField>
                                    <AvField name="work" label='العمل' placeholder='أدخل العمل' value={this.state.work} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField name="facebook" label='Facebook' placeholder='أدخل Facebook' value={this.state.facebook} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    <AvField name="whatsapp" label='Whatsapp' placeholder='أدخل Whatsapp' value={this.state.whatsapp} onChange={this.handleInputChange} errorMessage="الرجاء ادخال قيمة صحيحة" />
                                    {/* <AvField type="select" name="facebook" label='Facebook' value={this.state.facebook} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر </option>
                                        <option value='1'>نعم</option>
                                        <option value='0'>لا</option>
                                    </AvField> */}
                                    {/* <AvField type="select" name="whatsapp" label='Whatsapp' value={this.state.whatsapp} onChange={this.handleInputChange} className="p-1 pl-3" errorMessage="الرجاء ادخال قيمة صحيحة" >
                                        <option style={{
                                            'display': 'none'
                                        }} value="" selected="selected">اختر </option>
                                        <option value='1'>نعم</option>
                                        <option value='0'>لا</option>
                                    </AvField> */}
                                </Col>
                            </Row>
                            <Row>
                                <Col className='pl-5 pr-5'>
                                    {this.state.submitFaild != '' &&
                                        <div className='login-error-msg mb-3'>{this.state.submitFaild}</div>
                                    }
                                    <div className="text-left">
                                        {this.state.idNumberNotExist != true &&
                                            <button type="submit" className="btn form-save-btn mr-1 ml-1"><i className='flaticon2-check-mark form-save-btn-icon'></i> حفظ </button>
                                        }
                                        <button type="button" className="btn btn-danger waves-effect waves-light m-l-10" onClick={this.props.handleCloseSideBar}><i className='flaticon2-delete form-cancel-btn-icon'></i> إلغاء</button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>

                    </div>
                </div>
            </div>
        )
    }
}
export default CustomersForm;