import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import Departments from "../departments/departments"
import SubDepartment from "../subDepartment/subDepartment";
import Users from "../users/users"
import Customers from "../customers/customers"
import CommingSoon from "../configuration/commingSoon";
import Areas from "../configuration/areas/areas";
import School from "../configuration/school/school";
import Driver from "../configuration/driver/driver";
import Profile from "../users/profile";
import Settings from "../settings/settings";
import Reports from "../reports/mainReports";
import TreeReport from "../treeReport/treeReport";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Route path="/departments" component={Departments} />
        <Route path="/subDepartment/:parent?" component={SubDepartment} />
        <Route path="/users" component={Users} />
        <Route path="/customers/:filter?" component={Customers} />
        <Route path="/commingSoon" component={CommingSoon} />
        <Route path="/areas/:parent?" component={Areas} />
        <Route path="/school" component={School} />
        <Route path="/driver" component={Driver} />
        <Route path="/profile" component={Profile} />
        <Route path="/settings" component={localStorage.getItem('USER_LEVEL')==3 ?Settings:Dashboard} />
        <Route path="/reports/:reportId?" component={Reports} />
        <Route path="/treeReport" component={TreeReport} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
