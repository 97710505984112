import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ColorCode from '../general/colorCode'
import DepartmentsForm from './departmentsForm';
import { Redirect, Link } from 'react-router-dom';

class Departments extends Component {
    constructor(props) {
        super(props);
        this.state = {

            activePage: 1,
            limit: 10,
            dataCount: 0,
            openMenu: -1,
            openCheckedAction: false,
            checkedRow: [],
            sortColName: 'id',
            selectedSortType: [],
            data: [],
            loading: 1,
            departmentData: [],
            selectAtLeastOneFlag: 0,
            actionLoading: 0,
            openleftModal: false,
            sortType: '',
            allAdminData: [],
            childNumber: '',
            permations: {},
        };

    }
    componentWillMount() {
        this.getPermation();
    }
    getPermation() {
        var permations = {};
        if (localStorage.getItem('USER_LEVEL') == 1) {
            permations = {
                2: 0, // add & edit
                3: 0, // delete
                4: 0, // send sms
                5: 0, // print cards
            };
        } else if (localStorage.getItem('USER_LEVEL') == 3) {
            permations = {
                2: true, // add & edit
                3: true, // delete
                4: true, // send sms
                5: true, // print cards
            };
        } else {
            permations = {
                2: 0, // add & edit
                3: 0, // delete
                4: 0, // send sms
                5: 0, // print cards
            };
        }
        this.setState({
            permations: permations
        }, function () {
            this.getAllAdminData();
        })

    }
    getAllAdminData() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getUsers',
                'getType': 'all',
                'adminType': -1,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    allAdminData: data
                }, function () {
                    this.getDepartmentCount();
                    this.getDepartmentLimit();
                });

            }
        });
    }
    getDepartmentLimit() {
        this.setState({ loading: 1 })
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDataByCatIdAndLimit',
                'catId': 2,
                'title': this.state.searchTitle,
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sort': this.state.sortColName,
                'sortType': this.state.sortType,
                'getType': 'limit',
                'parentId': 0,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    data: data,
                    loading: 0
                });

            }
        });
    }
    getDepartmentCount() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDataByCatIdAndLimit',
                'catId': 2,
                'title': this.state.searchTitle,
                'page': this.state.page,
                'limit': this.state.limit,
                'sort': this.state.sortColName,
                'sortType': this.state.sortType,
                'getType': 'count',
                'parentId': 0,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    dataCount: data[0]['counter'],
                });

            }
        });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        })
    }

    handleRefresh = () => {
        this.setState({ activePage: 1, searchTitle: '' }, function () {
            this.getDepartmentCount();
            this.getDepartmentLimit();
        });
    }
    handleSearchChange = (event) => {
        this.setState({ searchTitle: event.target.value, activePage: 1 }, function () {
            this.getDepartmentCount();
            this.getDepartmentLimit();
        });
    }
    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }

    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getDepartmentLimit();
        });
    }

    openActionMenu = (event, id) => {
        event.preventDefault();
        if (this.state.openMenu == id) {
            this.setState({ openMenu: -1 })
        } else {
            this.setState({ openMenu: id })
        }
    }

    handleChangeLimit = (event) => {
        this.setState({ limit: event.target.value, activePage: 1 }, function () {
            this.getDepartmentLimit();
        });
    }
    sortCoulmn = (colNum) => {
        this.setState({ sortColName: colNum }, function () {
            if ((this.state.selectedSortType[colNum] == '' || this.state.selectedSortType[colNum] == undefined || this.state.selectedSortType[colNum] == null) && this.state.selectedSortType[colNum] != 0) {
                var sortVal = 0 // desc
            } else if (this.state.selectedSortType[colNum] == 1) {
                var sortVal = 0 // desc
            } else {
                var sortVal = 1 // asc
            }
            const newArray = this.state.selectedSortType;
            newArray[colNum] = sortVal;
            let sort = sortVal == 0 ? 'desc' : 'asc';
            this.setState({ selectedSortType: newArray, sortType: sort }, function () {
                this.getDepartmentLimit();
            });
        });
    }
    getChildDepartmentCountOnDelete(event, id) {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDataByCatIdAndLimit',
                'catId': 2,
                'title': this.state.searchTitle,
                'page': this.state.page,
                'limit': this.state.limit,
                'sort': this.state.sortColName,
                'sortType': this.state.sortType,
                'getType': 'count',
                'parentId': id,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    childNumber: data[0]['counter'],
                }, function () {
                    this.deleteRow(event, id);
                });

            }
        });
    }
    getChildDepartmentCount(rowAction) {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDataByCatIdAndLimit',
                'catId': 2,
                'title': this.state.searchTitle,
                'page': this.state.page,
                'limit': this.state.limit,
                'sort': this.state.sortColName,
                'sortType': this.state.sortType,
                'getType': 'count',
                'parentId': this.state.checkedRow,
                'allParents': 'all',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                if (data != null && data[0]['counter'] > 0) {
                    this.setState({
                        selectAtLeastOneFlag: 2
                    })
                } else {
                    this.rowAction(rowAction);
                }

            }
        });

    }
    deleteRow(event, id) {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                if (this.state.childNumber > 0) {
                    return (
                        <div className='custom-ui alert-box-view'>
                            <h5 style={{ color: '#fd397a' }} className='mb-4'>* ملاحظة : هذا المركز غير فارغ ,لا يمكنك حذفه حالياُ </h5>
                            <Button variant="contained" className='delete-reject-action-btn mr-2' onClick={onClose}> الغاء </Button>

                        </div>
                    );
                } else {
                    return (
                        <div className='custom-ui alert-box-view'>
                            <h5>هل انت متأكد ؟</h5>
                            <p>سيتم حذف جميع معلومات هذا المركز ؟</p>
                            <Button variant="contained" className='delete-reject-action-btn mr-2' onClick={onClose}> الغاء </Button>
                            <Button variant="contained" className='delete-accept-action-btn' onClick={() => { this.handleClickDelete(event, id); onClose(); }}> حذف </Button>
                        </div>
                    );
                }
            }
        });
    }

    handleClickDelete(event, id) {
        event.preventDefault();
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'deleteDataInPost',
                'id': id,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (data !== "Nothing found") {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                // var deletedElement = document.getElementById(id);
                // deletedElement.style.display = 'none'
                var newCount = parseInt(this.state.dataCount) - 1
                this.setState({ dataCount: newCount });

                if (newCount <= this.state.limit || this.state.activePage == 1) {
                    this.setState({ activePage: 1 });
                    this.getDepartmentLimit();
                }
                if (newCount % this.state.limit == 0) {
                    var activePage = parseInt(this.state.activePage) - 1
                    this.setState({ activePage: activePage });
                    this.getDepartmentLimit();
                }

            }
        })
    }
    checkRow = (actionType, rowId) => {
        if (actionType == 'all') {
            var selectedArray = this.state.checkedRow;
            if (document.getElementById("customCheck-0").checked == true) {
                if (this.state.data.length > 0) {
                    this.state.data.map((rowData, index) => {
                        if (this.state.checkedRow.indexOf(rowData.id) <= -1) {
                            selectedArray.push(rowData.id);
                        }
                    })
                }
            } else {
                if (this.state.data.length > 0) {
                    this.state.data.map((rowData, index) => {
                        if (this.state.checkedRow.indexOf(rowData.id) > -1) {
                            var index = selectedArray.indexOf(rowData.id);
                            selectedArray.splice(index, 1);
                        }
                    })
                }
            }
            this.setState({ checkedRow: selectedArray });
        } else {
            if (this.state.checkedRow.indexOf(rowId) > -1) {
                var selectedArray = this.state.checkedRow;
                var index = selectedArray.indexOf(rowId);
                selectedArray.splice(index, 1);
            } else {
                var selectedArray = this.state.checkedRow;
                selectedArray.push(rowId);
            }
            this.setState({ checkedRow: selectedArray });
        }
    }
    openCheckedActionMenu = () => {
        this.setState({ openCheckedAction: !this.state.openCheckedAction, selectAtLeastOneFlag: 0 })
    }


    toggle = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    openSidebarModal = (event, type, data) => {
        event.preventDefault();

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({
            departmentData: data,
            status: type,
            openleftModal: true
        }, function () {

        })
    }
    rowAction = (actionType) => {
        this.setState({
            selectAtLeastOneFlag: 0,
            actionLoading: 1
        })
        if (this.state.checkedRow.length > 0) {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'deleteDataInPost',
                    'id': this.state.checkedRow.join(),
                    'deleteType': 2,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {

                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    document.getElementById("customCheck-0").checked = false
                    this.setState({
                        actionLoading: 0,
                        checkedRow: [],
                        openCheckedAction: false
                    }, function () {
                        if (actionType == 'deleteSelected') {
                            this.setState({
                                activePage: 1,
                            }, function () {
                                this.getDepartmentCount();
                                this.getDepartmentLimit();
                            })
                        }
                    });
                }
            });
        } else {
            this.setState({
                selectAtLeastOneFlag: 1,
                actionLoading: 0
            })
        }
    }
    getAdminNameById = (id) => {
        for (const key in this.state.allAdminData) {
            if (this.state.allAdminData[key]['userId'] == id) {
                return (this.state.allAdminData[key]['fname'] + ' ' + this.state.allAdminData[key]['lname']);
            }
        }
    }
    handleCloseSideBar = () => {
        this.setState({ openleftModal: false })
    }
    handleFormAction = (status) => {
        this.setState({ openleftModal: false, departmentData: [] }, function () {
            if (status == 'edit') {
                this.getDepartmentLimit();
            } else {
                this.setState({ dataCount: parseInt(this.state.dataCount) + 1 }, function () {
                    this.getDepartmentLimit();
                })
            }
        })
    }
    sendSms = (event) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>هل تريد ارسال رسالة نصية لغير المنتخبين ؟</p>
                        <Button variant="contained" className='delete-reject-action-btn mr-2' onClick={onClose}> إلغاء </Button>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={() => { this.handleClickSendSms(event); }}> نعم </Button>
                    </div>

                );
            }
        });
    }

    handleClickSendSms = (event) => {
        event.preventDefault();
        // here call api that send sms

        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'sendMsgToCustomers',
                'customersId': 0,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui alert-box-view'>
                                <p style={{ 'color': '#024502' }}> <i className='far fa-check-circle ml-1' style={{ 'fontSize': '15px' }}></i> تم الارسال بنجاح </p>
                                <Button variant="contained" className='delete-reject-action-btn mr-2' onClick={onClose}> إغلاق </Button>
                            </div>

                        );
                    }
                });


            }
        });


    }

    printCards = (event) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>هل تريد طباعة بطاقات الناخبين ؟</p>
                        <p style={{ 'font-size': '11px', 'color': '#943232' }}>سيتم طباعة بطاقات الناخبين المسموح لهم بالانتخاب فقط</p>
                        <Button variant="contained" className='delete-reject-action-btn mr-2' onClick={onClose}> إلغاء </Button>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={() => { this.handleClickPrintCards(event); onClose() }}> نعم </Button>
                    </div>

                );
            }
        });
    }
    handleClickPrintCards = (event) => {
        event.preventDefault();
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getPostByCatAndCustomersForPrintCard',
                'id': 0,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'pdf': 1,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                if (data['resNo'] == 0) {
                    this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                } else {
                    this.reportAlert(event, 'تعذر استلام طلبك !');
                }

            }
        });

    }
    reportAlert = (event, text) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>{text}</p>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={onClose}> اغلاق </Button>
                    </div>

                );
            }
        });
    }




    render() {
        if (localStorage.getItem("USER_LEVEL") == 5) {
            return (
              <div>
                <p className="report-no-permission main-color-1">
                  <i className="far fa-times-circle"></i> لا تمتلك صلاحية لاستعراض هذا
                  التقرير
                </p>
              </div>
            );
          }
        var mainCol = []
        var dataCol = []
        var actionCol = []
        if (this.state.loading == 0 && this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {
                mainCol.push(
                    <div key={index} className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        {/* {this.state.permations[3] == true &&
                            <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-2 col-sm-2 col-md-2 col-lg-2 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                                {this.props.dashboardCounts != 1 &&
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={"rowCheck-" + rowData['id']} onClick={() => this.checkRow('byId', rowData['id'])} readOnly checked={this.state.checkedRow.indexOf(rowData['id']) > -1 ? true : false} />
                                        <label className="custom-control-label" htmlFor={"rowCheck-" + rowData['id']}>&nbsp;</label>
                                    </div>
                                }
                            </div>
                        } */}
                        <div title={rowData['name'] == '' ? '-' : rowData['name']} className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-12 col-md-12 col-lg-12 table-body-row main-color-1 font-weight-500 overflow-text-length')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['name'] == '' ? '-' : (this.props.dashboardCounts != 1 ? <Link to={"/subDepartment/" + rowData['id']} > {rowData['name']}</Link> : rowData['name'])}
                        </div>
                    </div>
                )
                var checkEvenRow = index % 2;
                dataCol.push(
                    <div className='row flex-row flex-nowrap' key={index} >
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['totalDepartment'] == undefined || rowData['totalDepartment'] == '' || rowData['totalDepartment'] == 0 ? '-' : rowData['totalDepartment']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['votersTotal'] == undefined || rowData['votersTotal'] == '' || rowData['votersTotal'] == 0 ? '-' : rowData['votersTotal']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['votedTotal'] == undefined || rowData['votedTotal'] == '' || rowData['votedTotal'] == 0 ? '-' : rowData['votedTotal']}
                        </div>
                        <div className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {rowData['notVotedTotal'] == undefined || rowData['notVotedTotal'] == '' || rowData['notVotedTotal'] == 0 ? '-' : rowData['notVotedTotal']}
                        </div>
                        <div title={(rowData['adminType'] == '' || rowData['adminType'] == 0) ? '-' : this.getAdminNameById(rowData['adminType'])} className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            {(rowData['adminType'] == '' || rowData['adminType'] == 0) ? '-' : this.getAdminNameById(rowData['adminType'])}                        </div>
                        <div title={rowData['small_desc'] == '' ? '-' : rowData['small_desc']} className={(checkEvenRow == 0 ? ' scroll-row-even-bg-color ' : ' ') + (this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-3 col-md-3 col-lg-3 table-body-row overflow-text-length table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} style={{ 'direction': 'ltr' }}>
                            {rowData['small_desc'] == '' ? '-' : rowData['small_desc']}
                        </div>

                    </div>
                )
                actionCol.push(
                    <div className="row flex-row flex-nowrap table-body-row-border row-even-bg-color">
                        <div className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('col-12 col-sm-4 col-md-12 col-lg-12 table-body-row overflow-text-length text-right action-btn-view')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} >
                            {this.state.permations[3] == true &&
                                <i className='flaticon-delete' title='حذف' onClick={(event) => this.getChildDepartmentCountOnDelete(event, rowData['id'])}></i>
                            }
                            {this.state.permations[2] == true &&
                                <i className='flaticon-edit-1' title='تعديل' onClick={(event) => this.openSidebarModal(event, 'edit', rowData)}></i>
                            }
                            <Link to={"/subDepartment/" + rowData['id']} ><i className='flaticon-map' title='ادارة'></i></Link>
                        </div>
                    </div>
                )
            })
        }
        return (
            <div>
                <Row>
                    <Col md={12}>
                        {this.props.dashboardCounts != 1 &&
                            <div className='kt-portlet kt-portlet--height-fluid p-3'>
                                <Row>
                                    <Col md={4}>
                                        <AvForm className="form-inline">
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <Button variant="contained" className='refresh-action-btn' onClick={this.handleRefresh} title='تحديث'>
                                                            <i className='flaticon-refresh reset-filter-btn'  ></i>
                                                        </Button>
                                                    </Col>
                                                    <Col md={10}>
                                                        <AvInput type="text" name="searchTitle" placeholder='بحث' value={this.state.searchTitle} onChange={this.handleSearchChange} className='form-input-width' />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </AvForm>
                                    </Col>
                                    <Col md={8} className='float-right text-right'>
                                        {this.state.permations[5] == true &&
                                            <Button variant="contained" className='print-cards-btn' style={{ fontSize: 14 }} onClick={(event) => this.printCards(event)}> طباعة البطاقات&nbsp;&nbsp;<i class="far fa-address-card"></i></Button>
                                        }
                                        {this.state.permations[4] == true &&
                                            <Button variant="contained" className='send-sms-btn ml-2' style={{ fontSize: 14 }} onClick={(event) => this.sendSms(event)}> ارسال رسالة&nbsp;&nbsp;<i class="flaticon-chat"></i></Button>
                                        }
                                        {this.state.permations[2] == true &&
                                            <Button variant="contained" className='main-action-btn' style={{ fontSize: 14 }} onClick={(event) => this.openSidebarModal(event, 'add', '')}> اضافة مركز رئيسي&nbsp;&nbsp;<i className='flaticon2-plus-1 pl-1'></i></Button>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            {this.state.loading == 1 &&
                                <div className="data-loading-spinner">Loading...</div>
                            }
                            {(this.state.loading == 0 && this.state.data.length <= 0) &&
                                <Row>
                                    <Col md={12} className='text-center no-data-available-view'>
                                        <img src='/media/electra/no-data-icon-68.png' />
                                        <p>لا يوجد بيانات لعرضها .&nbsp;.&nbsp; !</p>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={12} className='pl-0 text-right float-right'>
                                        <div className='d-inline-flex mb-2'>
                                            <div className='table-display-limit-text-1 mr-2 ml-2'> عرض</div>
                                            <AvForm className="form-inline table-display-limit-select">
                                                <AvField type="select" name="limit" value={this.state.limit} onChange={this.handleChangeLimit}>
                                                    <option value="10" >10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </AvField>
                                            </AvForm>
                                            <div className='table-display-limit-text-2 mr-2 ml-2'> مدخلات </div>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <div className='col-md-3 col-sm-3 col-3 col-lg-3'>
                                        <div className="row flex-row flex-nowrap table-header-row" >
                                            {/* <div className=" overflow-text-length d-inline-flex">
                                                {this.state.permations[3] == true && this.props.dashboardCounts != 1 &&
                                                    < div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck-0" onClick={() => this.checkRow('all', '')} />
                                                        <label className="custom-control-label" htmlFor="customCheck-0">&nbsp;</label>
                                                    </div>
                                                }
                                                {this.state.permations[3] == true && this.props.dashboardCounts != 1 &&

                                                    < button type="button" className="btn btn-sm  dropdown-toggle waves-effect arrow-none p-0" data-toggle="dropdown" aria-expanded="true" onClick={(event) => this.openCheckedActionMenu()}>
                                                    </button>
                                                }
                                                {this.state.permations[3] == true && this.props.dashboardCounts != 1 &&

                                                    < div className={(this.state.openCheckedAction === true ? 'show ' : '') + "  mt-4 dropdown-menu customer-dropdown-menu"} x-placement="bottom-start" style={{ maxHeight: "300px", overflowY: "auto" }}>
                                                        <label className='pl-1 pr-1 pt-1'>الإجراء {this.state.actionLoading == 1 && <i className='kt-spinner kt-spinner--md kt-spinner--light ml-2 action-loading-style'></i>}</label>
                                                        <a className="dropdown-item dropdown-item-active-style" href="#/" onClick={() => { this.rowAction('deleteSelected'); }}>حذف</a>
                                                        {this.state.selectAtLeastOneFlag == 1 &&
                                                            <div className="dropdown-item error-selected-row-null">اختر مركز واحد على الأقل</div>
                                                        }
                                                        {this.state.selectAtLeastOneFlag == 2 &&
                                                            <div className="dropdown-item error-selected-row-null">لا يمكن الحذف حاليا , بعض الاقسام المختارة غير فارغة</div>
                                                        }
                                                    </div>
                                                }
                                            </div> */}
                                            <div className="overflow-text-length ml-0">
                                                <div className='pointer-cursor' onClick={() => this.sortCoulmn('name')}>اسم المركز الرئيسي {this.state.sortColName == 'name' ? (this.state.selectedSortType['name'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                            </div>
                                        </div>
                                        {mainCol}
                                    </div>
                                    <div className={this.props.dashboardCounts != 1 ? 'col-md-7 col-sm-7 col-7 col-lg-7 pr-0' : 'col-md-9 col-sm-9 col-9 col-lg-9 pr-0'} style={{ 'overflowX': 'auto', 'overflowY': 'hidden', 'width': '100%' }}>
                                        <div className="row flex-row flex-nowrap">
                                            <div className='col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('totalDepartment')}>عدد المراكز / اللجان {this.state.sortColName == 'totalDepartment' ? (this.state.selectedSortType['totalDepartment'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('votersTotal')}>عدد الناخبين الكلي {this.state.sortColName == 'votersTotal' ? (this.state.selectedSortType['votersTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('votedTotal')}>عدد المصوتيين {this.state.sortColName == 'votedTotal' ? (this.state.selectedSortType['votedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('notVotedTotal')}>عدد الغير مصوتيين {this.state.sortColName == 'notVotedTotal' ? (this.state.selectedSortType['notVotedTotal'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('adminType')}>الشخص المسؤول {this.state.sortColName == 'adminType' ? (this.state.selectedSortType['adminType'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-3 col-md-3 col-lg-3 table-header-row overflow-text-length'>
                                                <div className="overflow-text-length ml-0">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('small_desc')}>رقم الهاتف {this.state.sortColName == 'small_desc' ? (this.state.selectedSortType['small_desc'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </div>
                                        </div>
                                        {dataCol}
                                    </div>
                                    {this.props.dashboardCounts != 1 &&
                                        <div className='col-md-2 col-sm-2 col-2 col-lg-2'>
                                            <div className="row flex-row flex-nowrap" >
                                                <div className="col-12 col-sm-4 col-md-12 col-lg-12 table-header-row overflow-text-length text-right pl-4">الإجراء</div>
                                            </div>
                                            {actionCol}
                                        </div>
                                    }
                                </Row>
                            }
                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={6} className='pagination-style float-left text-left'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className="flaticon2-right-arrow"></i>}
                                            nextPageText={<i className="flaticon2-left-arrow"></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <div className='mt-2 float-right text-right'>
                                            عرض {this.state.activePage != 1 ? this.state.activePage + ((this.state.limit * (this.state.activePage - 1)) - this.state.activePage + 1) : this.state.activePage} الى {(this.state.activePage * this.state.limit) > this.state.dataCount ? this.state.dataCount : this.state.activePage * this.state.limit} من {this.state.dataCount} مدخلات
                                    </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col >
                </Row >

                <Drawer anchor={'left'} open={this.state.openleftModal} className='sidebar-modal-view'>
                    {this.state.openleftModal == true &&
                        <DepartmentsForm ref="departmentsForm" status={this.state.status} allAdminData={this.state.allAdminData} departmentData={this.state.departmentData} handleCloseSideBar={this.handleCloseSideBar} handleFormAction={this.handleFormAction} />
                    }
                </Drawer>

            </div >
        )
    }
}
export default Departments;