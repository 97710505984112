/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { connect, ReactReduxContext } from "react-redux";
import { Row, Col } from 'reactstrap';
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadExcelFileFinished: localStorage.getItem('uploadExcelFileFinished'),
      uploadExcelFileTotal: localStorage.getItem('uploadExcelFileTotal'),
      filesData: [],
    }
  }
  componentWillMount() {

    let scop = this;
    setInterval(function () {
      scop.getImportCustomersStatus()
    }, 3000);
  }

  getImportCustomersStatus() {
    fetch(window.API_URL_DATA, {
      method: 'POST',
      body: JSON.stringify({
        'type': 'getCompleteFiles',
        'userId': localStorage.getItem('USER_ID'),
        'token': localStorage.getItem('USER_TOKEN'),
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
      },
    }).then(function (response) {
      return response.json();
    }).then(data => {
      if (typeof data !== 'Nothing found') {
        if (data == 'tokenExpired') {
          localStorage.setItem('USER_ID', '');
          window.location.href = "/logout";
        }
        this.setState({
          uploadExcelFileFinished: 100,
          uploadExcelFileTotal: 100,
          filesData: data,
        })
      }
    });
  }

  addRowHover = (index, event) => {
    var stateVal = 'activeIndex'
    this.setState({ [stateVal]: index })
  }

  removeRowHover = (index, event) => {
    var stateVal = 'activeIndex'
    this.setState({ [stateVal]: -1 })
  }
  downloadFile(event, file) {
    event.preventDefault();
    window.open(window.HTTP_ROOT_PATH + "data.php?type=downloadFile" +
      "&userId=" + localStorage.getItem('USER_ID') + "&token=" + localStorage.getItem('USER_TOKEN') + '&id=' + file.id);

  }
  getFiles = (data, index) => {
    return (
      <div key={index} className={(this.state['activeIndex'] == index ? 'download-files-class  ' : '') + (index == 0 ? ' mt-2 ' : ' mt-1 ') + ' kt-notification__item-time pointer-cursor'} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)} onClick={(e) => this.downloadFile(e, data)}>
        <i class="far fa-arrow-alt-circle-down"></i> &nbsp;&nbsp;
        {data.fileName}
      </div >
    );
  }
  render() {
    const { user, showHi, showAvatar, showBadge } = this.props;
    return (
      <Dropdown className={((this.state.filesData != null && this.state.filesData.length > 0) ? "user-profile-div " : "") + " kt-header__topbar-item kt-header__topbar-item--user"} drop="down" alignRight >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          {showHi && (
            <span className="kt-header__topbar-username "> {/*kt-hidden-mobile*/}
              {localStorage.getItem('USER_FULL_NAME')}
            </span>
          )}
          {(this.state.filesData != null && this.state.filesData.length > 0) ?
            <div className='CircularProgressbarWithChildren'>
              <CircularProgressbarWithChildren value={(this.state.uploadExcelFileFinished / this.state.uploadExcelFileTotal) * 100}>
                <img alt="Pic" className='user-profile-img' src={"/media/users/default.jpg"} />
                <div className='user-profile-parsantage'><i class="fas fa-cloud-download-alt"></i></div>
              </CircularProgressbarWithChildren>
            </div>
            :
            <img alt="Pic" src={"/media/users/default.jpg"} />
          }
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x header-user-menu-view" >
            <div className="kt-user-card__name">{localStorage.getItem('USER_FULL_NAME')}</div>
          </div>
          <div className="kt-notification">
            <a className="kt-notification__item header-account-menue-dir" href="/profile" >
              <div className="kt-notification__item-icon">
                <i className="flaticon2-calendar-3 main-color-1" />
              </div>
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  حسابي
                </div>
                <div className="kt-notification__item-time">
                  معلومات الحساب
                </div>
              </div>
            </a>
            {localStorage.getItem('USER_LEVEL') == 3 &&
              <a className="kt-notification__item header-account-menue-dir" href="/settings">
                <div className="kt-notification__item-icon">
                  <i className="flaticon-cogwheel-2 main-color-1" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    الصلاحيات والاعدادات
                </div>
                  <div className="kt-notification__item-time">
                    صلاحيات المستخدمين واعدادات الموقع
                </div>
                </div>
              </a>
            }
            {(this.state.filesData != null && this.state.filesData.length > 0) &&
              <div className="header-account-menue-dir mr-4 mt-2">
                <div>
                  <div className="kt-notification__item-title kt-font-bold" style={{ color: '#00589a' }}>
                    <i class="fas fa-file-download" style={{ fontSize: 19 }}></i>&nbsp;&nbsp;
                    الملفات الجاهزة للتحميل
                  </div>
                  {(this.state.filesData != null && this.state.filesData.length > 0) && this.state.filesData.map((index, data) => this.getFiles(index, data))}
                </div>
              </div>
            }

            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold log-out-btn-header"
              >
                تسجيل الخروج
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserProfile);
