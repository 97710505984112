import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { Button, Drawer } from "@material-ui/core";
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Customers from '../customers/customers';

class DashboardDepartement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: {
            },
            showChart: 0,
            data: [],
            depId: '',
            selectedDep: []
        };
    }
    componentWillMount() {
        // this.getCountDepartmentMembers();
        this.getDepartmentLimit()
    }

    getDepartmentLimit() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDataByCatIdAndLimit',
                'catId': 2,
                'title': '',
                'page': '',
                'limit': '',
                'sort': 'id',
                'sortType': '',
                'getType': 'all',
                'parentId': 0,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'dataType': 2,
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                // console.log('data');
                // console.log(data);

                let series = [];
                let labels = [];
                for (const key in data) {
                    series.push(parseInt(data[key]['votersTotal']));
                    labels.push(data[key]['name']);
                }

                const scope = this

                this.setState({
                    data: data,
                    series: series,
                    options: {
                        chart: {
                            width: 380,
                            type: 'pie',
                            events: {
                                dataPointSelection: function (event, chartContext, config) {
                                    scope.openChartDetails(config.dataPointIndex)
                                }
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        labels: labels,
                        colors: ['#546E7A', '#2b908f', '#f9a3a4', '#90ee7e',
                            '#f48024', '#69d2e7', '#d4526e', '#13d8aa', '#33b2df', '#A5978B'
                        ],
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }],
                        fill: {
                            type: 'gradient',
                        },
                        legend: {
                            position: 'bottom',
                            horizontalAlign: 'center',
                        },
                    },
                    showChart: 1
                })
                 

            }
        });
    }

    openChartDetails = (depIndex) => {
        var depData = this.state.data[depIndex]
        
        if (depData != '' && depData != undefined && depData != null) {
            // var depId = this.state.data[depIndex]['departmentId']
            var depId = this.state.data[depIndex]['id']
            this.setState({ selectedDep: depData, openRightModal: true, depId: depId })
        }
    }

    handleCloseSideBar = () => {
        this.setState({ openRightModal: false, selectedDep: [], depId: '' })
    }

    getCountDepartmentMembers() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCountDepartmentMembers',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }

                // console.log('data');
                // console.log(data);

                let series = [];
                let labels = [];
                for (const key in data) {
                    series.push(parseInt(data[key]['counterCustomers']));
                    labels.push(data[key]['nameDepartment']);
                }

                const scope = this

                this.setState({
                    data: data,
                    series: series,
                    options: {
                        chart: {
                            width: 380,
                            type: 'pie',
                            events: {
                                dataPointSelection: function (event, chartContext, config) {
                                    scope.openChartDetails(config.dataPointIndex)
                                }
                            }
                        },
                        dataLabels: {
                            enabled: false
                        },
                        labels: labels,
                        colors: ['#546E7A', '#2b908f', '#f9a3a4', '#90ee7e',
                            '#f48024', '#69d2e7', '#d4526e', '#13d8aa', '#33b2df', '#A5978B'
                        ],
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }],
                        fill: {
                            type: 'gradient',
                        },
                        legend: {
                            position: 'bottom',
                            horizontalAlign: 'center',
                        },
                    },
                    showChart: 1
                })


            }
        });
    }
    render() {
        return (
            <div id="chart">
                <div className='dasboard-dep-customer-num-chart-title'>عدد الناخبين حسب المراكز الإنتخابية</div>
                {this.state.showChart == 1 &&
                    <Chart options={this.state.options} series={this.state.series} type="pie" />
                }


                <Drawer anchor={'left'} open={this.state.openRightModal} className='sidebar-modal-view'>
                    <div className='sidebar-header-view'>
                        <Row>
                            <Col md={11}>
                                <div className='p-0 main-color-1 font-weight-500 sidebar-header-title'>عدد الناخبين حسب المركز الانتخابي ({this.state.selectedDep['name']})
                                </div>
                            </Col>
                            <Col md={1} className='pr-5'>
                                <i className='p-0 flaticon2-delete sidebar-header-close-btn  mr-5' onClick={this.handleCloseSideBar}></i>
                            </Col>
                        </Row>
                    </div>
                    <Customers dashboardFilter={1} stationId={this.state.depId}/>
                </Drawer>
            </div>
        )
    }
}
export default DashboardDepartement;