import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import { Button, Drawer } from "@material-ui/core";
import { confirmAlert } from 'react-confirm-alert';

class Reports6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 1,
            limit: 10,
            dataCount: 0,
            openMenu: -1,
            openCheckedAction: false,
            checkedRow: [],
            showMoreData: [],
            showSubMoreData: [],
            openRightModal: false,
            sortColNum: '',
            selectedSortType: [],
            data: [],
            loading: 1,
            customerData: [],
            selectAtLeastOneFlag: 0,
            actionLoading: 0,
            colorTypes: [],
            adminType: -1,
            sortType: '',
            allMembers: [],
            allSchools: [],
            allArea: [],
            allDepartement: [],
            openImportModal: false,
            allSpecificArea: [],
            voted: 0,
            confirmSendSms: 0
        };
    }

    componentWillMount() {
        this.getMembers();
    }

    getMembers() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getAllMembers',
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    allMembers: data
                }, function () {
                    this.getCustomerCount();
                    this.getCustomerLimit();
                });
            }
        });
    }

    getCustomerCount() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCustomers',
                'title': this.state.searchTitle,
                'getType': 'count',
                'schoolId': this.state.schoolId,
                'specificThingId': this.state.specificThingId,
                'regionId': this.state.regionId,
                'memberId': this.state.memberId,
                'driverId': '',
                'centerId': this.state.centerId,
                'voted': this.state.voted,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'canVote': '-1'
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {

            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    dataCount: data[0]['counter']
                });
            }
        });
    }

    getCustomerLimit() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getCustomers',
                'getType': 'limit',
                'title': this.state.searchTitle,
                'page': this.state.activePage,
                'limit': this.state.limit,
                'sortType': this.state.sortType,
                'sort': this.state.sortColNum,
                'schoolId': this.state.schoolId,
                'specificThingId': this.state.specificThingId,
                'regionId': this.state.regionId,
                'memberId': this.state.memberId,
                'driverId': '',
                'centerId': this.state.centerId,
                'voted': this.state.voted,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
                'canVote': '-1'
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    data: data,
                    loading: 0
                });
            }
        });
    }

    handleRefresh = () => {
        this.setState({ activePage: 1, searchTitle: '', centerId: '', memberId: '', regionId: '', specificThingId: '', schoolId: '', voted: 0 }, function () {
            this.getCustomerCount();
            this.getCustomerLimit();
        });
    }

    handleChangeLimit = (event) => {
        this.setState({ limit: event.target.value, activePage: 1 }, function () {
            this.getCustomerLimit();
        });
    }

    addRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: index })
    }

    removeRowHover = (index, event) => {
        var stateVal = 'activeIndex'
        this.setState({ [stateVal]: -1 })
    }

    handlePageChange = (pageNumber) => {
        this.setState({ activePage: pageNumber }, function () {
            this.getCustomerLimit();
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value,
            activePage: 1
        }, function () {
            this.getCustomerCount();
            this.getCustomerLimit();
        })
    }

    setUpOptionsList(data, type, index) {
        if (type == 'allMembers') {
            return (<option value={data.id} key={index}>{data.keyword_SEO} {data.desc_SEO} {data.countries} {data.location} </option>)
        }
    }

    returnRowData = () => {
        var result = []
        if (this.state.loading == 0 && this.state.data.length > 0) {
            this.state.data.map((rowData, index) => {

                result.push(

                    <Col md={12} key={index} id={rowData.userId} className='row-data-view'>
                        <Row className={(this.state['activeIndex'] == index ? 'hover-scroll-table-row  ' : '') + ('table-body-row table-body-row-border')} onMouseOver={this.addRowHover.bind(this, index)} onMouseOut={this.removeRowHover.bind(this, index)}>
                            <Col md={1} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{index + 1}</div>
                            </Col>
                            <Col md={3} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{rowData['name']} {rowData['secondName']} {rowData['thirdName']} {rowData['fourthName']}</div>
                            </Col>
                            <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{rowData['idNumber'] == '' ? '-' : rowData['idNumber']}</div>
                            </Col>
                            <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{rowData['schoolName'] == '' ? '-' : rowData['schoolName']}</div>
                            </Col>
                            <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3">{rowData['memberName'] == '' ? '-' : rowData['memberName']}</div>
                            </Col>
                            <Col md={2} className='d-inline-flex main-flyscreen-1 font-weight-500' >
                                <div className="overflow-text-length ml-3" style={{ 'direction': 'ltr' }}>{rowData['phoneNumber'] == '' ? '-' : rowData['phoneNumber']}</div>
                            </Col>
                        </Row>
                    </Col>
                )
            })
        }
        return result
    }

    exportCustomers = (event, type) => {
        event.preventDefault();
        var searchTitle = this.state.searchTitle
        if (searchTitle == undefined || searchTitle == '' || searchTitle == null) {
            searchTitle = ''
        }

        var sortColNum = this.state.sortColNum
        if (sortColNum == undefined || sortColNum == '' || sortColNum == null) {
            sortColNum = ''
        }

        var sortType = this.state.sortType
        if (sortType == undefined || sortType == '' || sortType == null) {
            sortType = ''
        }

        var schoolId = this.state.schoolId
        if (schoolId == undefined || schoolId == '' || schoolId == null) {
            schoolId = ''
        }

        var specificThingId = this.state.specificThingId
        if (specificThingId == undefined || specificThingId == '' || specificThingId == null) {
            specificThingId = ''
        }

        var regionId = this.state.regionId
        if (regionId == undefined || regionId == '' || regionId == null) {
            regionId = ''
        }

        var memberId = this.state.memberId
        if (memberId == undefined || memberId == '' || memberId == null) {
            memberId = ''
        }

        var centerId = this.state.centerId
        if (centerId == undefined || centerId == '' || centerId == null) {
            centerId = ''
        }

        var voted = this.state.voted
        if (voted == undefined || voted == '' || voted == null) {
            voted = ''
        }

        if (type == 'pdf') {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getCustomers',
                    'title': searchTitle,
                    'export': 1,
                    'sort': sortColNum,
                    'sortType': sortType,
                    'schoolId': schoolId,
                    'specificThingId': specificThingId,
                    'regionId': regionId,
                    'memberId': memberId,
                    'driverId': '',
                    'centerId': centerId,
                    'voted': voted,
                    'spicificCustomers': 1,
                    'fromLimit': -1,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'pdf': 1,
                    'canVote': -1,
                    'getType': '',
                    'fromReport': 1,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }

                }
            });
        } else {
            fetch(window.API_URL_DATA, {
                method: 'POST',
                body: JSON.stringify({
                    'type': 'getCustomers',
                    'title': searchTitle,
                    'export': 1,
                    'sort': sortColNum,
                    'sortType': sortType,
                    'schoolId': schoolId,
                    'specificThingId': specificThingId,
                    'regionId': regionId,
                    'memberId': memberId,
                    'driverId': '',
                    'centerId': centerId,
                    'voted': voted,
                    'spicificCustomers': 1,
                    'fromLimit': -1,
                    'userId': localStorage.getItem('USER_ID'),
                    'token': localStorage.getItem('USER_TOKEN'),
                    'canVote': -1,
                    'getType': '',
                    'fromReport': 1,
                }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                },
            }).then(function (response) {
                return response.json();
            }).then(data => {
                if (typeof data !== 'Nothing found') {
                    if (data == 'tokenExpired') {
                        localStorage.setItem('USER_ID', '');
                        window.location.href = "/logout";
                    }
                    if (data['resNo'] == 0) {
                        this.reportAlert(event, 'تم استلام طلبك بنجاح , قم بالتحقق من جاهزية طلبك من خلال ايقونة ملفك الشخصي');
                    } else {
                        this.reportAlert(event, 'تعذر استلام طلبك !');
                    }
                }
            });
        }
    }
    reportAlert = (event, text) => {
        event.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui alert-box-view'>
                        <p>{text}</p>
                        <Button variant="contained" className='delete-accept-action-btn' onClick={onClose}> اغلاق </Button>
                    </div>

                );
            }
        });
    }
    sortCoulmn = (colNum) => {
        this.setState({ sortColNum: colNum }, function () {
            if ((this.state.selectedSortType[colNum] == '' || this.state.selectedSortType[colNum] == undefined || this.state.selectedSortType[colNum] == null) && this.state.selectedSortType[colNum] != 0) {
                var sortVal = 0 // desc
            } else if (this.state.selectedSortType[colNum] == 1) {
                var sortVal = 0 // desc
            } else {
                var sortVal = 1 // asc
            }
            const newArray = this.state.selectedSortType;
            newArray[colNum] = sortVal;
            let sort = sortVal == 0 ? 'desc' : 'asc';
            this.setState({ selectedSortType: newArray, sortType: sort }, function () {
                this.getCustomerLimit();
            });
        });
    }
    render() {
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            <Row>
                                <Col md={4}>
                                    <AvForm className="form-inline">
                                        <Col md={12}>
                                            <Row>
                                                <Col md={2}>
                                                    <Button variant="contained" className='refresh-action-btn' onClick={this.handleRefresh} title='تحديث'>
                                                        <i className='flaticon-refresh reset-filter-btn'  ></i>
                                                    </Button>
                                                </Col>
                                                <Col md={10}>
                                                    <AvField type="select" name="memberId" value={this.state.memberId} onChange={this.handleInputChange.bind(this)} className='form-input-width'>
                                                        <option style={{
                                                            'display': 'none'
                                                        }} value="">صاحب الكشف</option>
                                                        <option value=''>الكل</option>
                                                        {this.state.allMembers.map((data, input) => this.setUpOptionsList(data, 'allMembers', input))}
                                                    </AvField>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={12}>
                                        </Col>
                                    </AvForm>
                                </Col>
                                <Col md={8} className='float-right text-right'>
                                    {(this.props.allPermission == 1 || this.props.excelPermission == 1) &&
                                        <Button variant="contained" className='export-btn ml-2' style={{ fontSize: 14 }} onClick={(event) => this.exportCustomers(event, 'excel')}> تصدير &nbsp;&nbsp;<i class="fas fa-file-download"></i></Button>
                                    }
                                    {(this.props.allPermission == 1 || this.props.pdfPermission == 1) &&
                                        <Button variant="contained" className='pdf-btn' style={{ fontSize: 14 }} onClick={(event) => this.exportCustomers(event, 'pdf')}> Pdf &nbsp;&nbsp;<i class="far fa-file-pdf"></i></Button>
                                    }
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div className='kt-portlet kt-portlet--height-fluid p-3'>
                            {this.state.loading == 1 &&
                                <div className="data-loading-spinner">Loading...</div>
                            }

                            {(this.state.loading == 0 && this.state.data.length <= 0) &&
                                <Row>
                                    <Col md={12} className='text-center no-data-available-view'>
                                        <img src='/media/electra/no-data-icon-68.png' />
                                        <p>لا يوجد بيانات  .&nbsp;.&nbsp; !</p>
                                    </Col>
                                </Row>
                            }

                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={2} className='pl-0 mb-2'>
                                    </Col>
                                    <Col md={10} className='pl-0 text-right'>
                                        <div className='d-inline-flex mb-2'>
                                            <div className='table-display-limit-text-1 mr-2 ml-2'> عرض</div>
                                            <AvForm className="form-inline table-display-limit-select">
                                                <AvField type="select" name="limit" value={this.state.limit} onChange={this.handleChangeLimit}>
                                                    <option value="10" >10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </AvField>
                                            </AvForm>
                                            <div className='table-display-limit-text-2 mr-2 ml-2'> مدخلات </div>
                                        </div>
                                    </Col>
                                </Row>
                            }


                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={12}>
                                        <Row className='table-header-row'>
                                            <Col md={1} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    #
                                                </div>
                                            </Col>
                                            <Col md={3} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('name')}> الاسم {this.state.sortColNum == 'name' ? (this.state.selectedSortType['name'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('idNumber')}>الرقم الوطني {this.state.sortColNum == 'idNumber' ? (this.state.selectedSortType['idNumber'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    مركز الاقتراع
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('memberName')}>صاحب الكشف {this.state.sortColNum == 'memberName' ? (this.state.selectedSortType['memberName'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                            <Col md={2} className='d-inline-flex'>
                                                <div className="overflow-text-length ml-3">
                                                    <div className='pointer-cursor' onClick={() => this.sortCoulmn('phoneNumber')}>رقم الهاتف{this.state.sortColNum == 'phoneNumber' ? (this.state.selectedSortType['phoneNumber'] == 0 ? <i className="fas fa-sort-amount-down active-sort ml-1 sort-icon-size"></i> : <i className="fas fa-sort-amount-up-alt active-sort ml-1 sort-icon-size"></i>) : <i className="fas fa-sort-amount-down not-active-sort ml-1 sort-icon-size"></i>}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={12} className='table-body-scroll table-body-row-view'>
                                        {this.returnRowData()}
                                    </Col>
                                </Row>
                            }

                            {(this.state.loading == 0 && this.state.data.length > 0) &&
                                <Row className='m-1'>
                                    <Col md={6} className='pagination-style float-left text-left'>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.dataCount}
                                            pageRangeDisplayed={5}
                                            onChange={this.handlePageChange}
                                            prevPageText={<i className="flaticon2-right-arrow"></i>}
                                            nextPageText={<i className="flaticon2-left-arrow"></i>}
                                            hideFirstLastPages
                                            innerClass={'pagination  pagination-rounded pagenation-view my-2'}
                                            itemClass={'page-item'}
                                            linkClass={'page-link'}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <div className='mt-2 float-right text-right'>
                                            عرض {this.state.activePage != 1 ? this.state.activePage + ((this.state.limit * (this.state.activePage - 1)) - this.state.activePage + 1) : this.state.activePage} الى {(this.state.activePage * this.state.limit) > this.state.dataCount ? this.state.dataCount : this.state.activePage * this.state.limit} من {this.state.dataCount} مدخلات
                                    </div>
                                    </Col>
                                </Row>
                            }

                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Reports6;