import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Collapse, Button } from 'reactstrap';
import { CircularProgress } from "@material-ui/core";
import { tr } from 'date-fns/locale';

class TreeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parentDepartmentData: [],
            subDepartmentData: [],
            collaps: [],
            deptLoading: [],
            loading: 1,
            permations: false
        };
    }

    onToggle = (e, id) => {
        let collaps = [...this.state.collaps];
        collaps[id] = collaps[id] == undefined ? true : !collaps[id];
        this.setState({
            collaps: collaps,
        })
    }
    componentWillMount() {
        this.getPermation();
    }

    getPermation() {
        var permations = false;
        if (localStorage.getItem('USER_LEVEL') == 1) {
            var permations = JSON.parse(localStorage.getItem('permations'))
            if (permations != undefined && permations != null && permations != '') {
                if (permations['resultReportData'] != undefined && permations['resultReportData'] != null && permations['resultReportData'] != '' && permations['resultReportData']['1_1'] != undefined && permations['resultReportData']['1_1'] != null && permations['resultReportData']['1_1'] != '') {
                    permations = permations['resultReportData']['1_1']
                }
            }
        } else if (localStorage.getItem('USER_LEVEL') == 3) {
            permations = true; // all
        } else {
            permations = false; // all

        }
        this.setState({
            permations: permations
        }, function () {
            this.getParentDepartment();
        })

    }
    getParentDepartment() {
        fetch(window.API_URL_DATA, {
            method: 'POST',
            body: JSON.stringify({
                'type': 'getDataByCatIdAndLimit',
                'catId': 2,
                'title': '',
                'page': '',
                'limit': '',
                'sort': 'id',
                'sortType': '',
                'getType': 'all',
                'parentId': 0,
                'userId': localStorage.getItem('USER_ID'),
                'token': localStorage.getItem('USER_TOKEN'),
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
            },
        }).then(function (response) {
            return response.json();
        }).then(data => {
            if (typeof data !== 'Nothing found') {
                if (data == 'tokenExpired') {
                    localStorage.setItem('USER_ID', '');
                    window.location.href = "/logout";
                }
                this.setState({
                    parentDepartmentData: data,
                    loading: 0
                })
            }
        });
    }
    getSubDepartment(e, id, featured) {
        if (this.state.collaps[id] == false || this.state.collaps[id] == undefined) {
            if (featured == 0) {
                let deptLoading = [...this.state.deptLoading];
                deptLoading[id] = true;
                this.setState({ deptLoading: deptLoading });
                fetch(window.API_URL_DATA, {
                    method: 'POST',
                    body: JSON.stringify({
                        'type': 'getDataByCatIdAndLimit',
                        'catId': 2,
                        'title': '',
                        'page': '',
                        'limit': '',
                        'sort': 'id',
                        'sortType': '',
                        'getType': 'all',
                        'parentId': id,
                        'userId': localStorage.getItem('USER_ID'),
                        'token': localStorage.getItem('USER_TOKEN'),
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                    },
                }).then(function (response) {
                    return response.json();
                }).then(data => {
                    if (typeof data !== 'Nothing found') {
                        if (data == 'tokenExpired') {
                            localStorage.setItem('USER_ID', '');
                            window.location.href = "/logout";
                        }
                        let subDepartmentData = [...this.state.subDepartmentData];
                        if (subDepartmentData[id] == undefined) {
                            subDepartmentData[id] = [];
                        }
                        subDepartmentData[id] = data;
                        deptLoading[id] = false;
                        this.setState({
                            subDepartmentData: subDepartmentData,
                            deptLoading: deptLoading,
                        }, function () {
                            this.onToggle(e, id);
                        })
                    }
                });
            } else {
                let deptLoading = [...this.state.deptLoading];
                deptLoading[id] = true;
                this.setState({ deptLoading: deptLoading });
                fetch(window.API_URL_DATA, {
                    method: 'POST',
                    body: JSON.stringify({
                        'userId': localStorage.getItem('USER_ID'),
                        'token': localStorage.getItem('USER_TOKEN'),
                        'canVote': -1,
                        'getType': "all",
                        'memberId': id,
                        'type': "getCustomers",
                        'voted': 0
                    }),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Request-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
                    },
                }).then(function (response) {
                    return response.json();
                }).then(data => {
                    if (typeof data !== 'Nothing found') {
                        if (data == 'tokenExpired') {
                            localStorage.setItem('USER_ID', '');
                            window.location.href = "/logout";
                        }
                        let subDepartmentData = [...this.state.subDepartmentData];
                        if (subDepartmentData[id] == undefined) {
                            subDepartmentData[id] = [];
                        }
                        subDepartmentData[id] = data;
                        deptLoading[id] = false;
                        this.setState({
                            subDepartmentData: subDepartmentData,
                            deptLoading: deptLoading,
                        }, function () {
                            this.onToggle(e, id);
                        })
                    }
                });
            }
        } else {
            this.onToggle(e, id);
        }
    }
    getSubDepartmentDiv(id, featured) {
        let subDepartmentData = [];
        if (this.state.subDepartmentData[id] != undefined && this.state.subDepartmentData[id].length > 0) {
            this.state.subDepartmentData[id].map((rowData, index) => {
                subDepartmentData.push(
                    <div>
                        <Row key={index} onClick={featured == 0 ? (e) => this.getSubDepartment(e, rowData.id, rowData.featured) : ''} className={(this.state.collaps[rowData.id] == true ? "toglleOpen" : '') + (featured == 0 ? " pointer-cursor " : "") + " p-1 pt-3 pb-3 mr-2 ml-2 subDepartmentRow"}>
                            <Col md={rowData.featured == 0 ? 9 : (featured == 0 ? 3 : 6)} sm={rowData.featured == 0 ? 6 : (featured == 0 ? 12 : 12)} xs={rowData.featured == 0 ? 12 : (featured == 0 ? 12 : 12)} >
                                {this.state.collaps[rowData.id] == true ? <i class="fas fa-times"></i> : (rowData.featured == 1 ? <i class="fas fa-users"></i> : (featured == 0 ? <i class="fas fa-bars"></i> : (rowData.voted == 0 ? <i class="fas fa-user-times text-danger"></i> : <i class="fas fa-user-check text-success"></i>)))}
                                &nbsp;&nbsp;
                                <span className={rowData.featured != 0 && featured != 0 && rowData.voted != undefined && rowData.voted == 0 ? 'text-danger' : (rowData.voted != undefined && rowData.voted != 0 && 'text-success')}>{rowData.name == '' ? (rowData['keyword_SEO'] + ' ' + rowData['desc_SEO'] + ' ' + rowData['countries'] + ' ' + rowData['location']) : (featured == 0 ? rowData.name : rowData.customerFullName)}</span>
                                &nbsp;&nbsp;
                                <span>{this.state.deptLoading[rowData.id] == true &&
                                    <CircularProgress className="kt-splash-screen__spinner mr-3 ml-3 mt-1 mb-n1" style={{ width: 15, height: 15, color: '#646C9A' }} />
                                }</span>
                            </Col>
                            {rowData.featured == 1 &&
                                <Col md={2} sm={4} xs={5} className='text-right'>
                                    عدد المصوتيين :
                            </Col>
                            }
                            {rowData.featured == 1 &&

                                <Col md={1} sm={2} xs={7} className='font-weight-bold' style={{ color: '#06258a' }}>
                                    {rowData.votedTotal}
                                </Col>
                            }
                            {rowData.featured == 1 &&
                                <Col md={2} sm={4} xs={5} className='text-right'>
                                    عدد الغير مصوتيين :
                            </Col>
                            }
                            {rowData.featured == 1 &&
                                <Col md={1} sm={2} xs={7} className='font-weight-bold' style={{ color: '#06258a' }}>
                                    {rowData.notVotedTotal}
                                </Col>
                            }
                            {featured == 0 &&
                                <Col md={2} sm={4} xs={5} className='text-right'>
                                    عدد الناخبين :
                            </Col>
                            }
                            {featured == 0 &&
                                <Col md={1} sm={2} xs={7} className='font-weight-bold' style={{ color: '#06258a' }}>
                                    {rowData.votersTotal}
                                </Col>
                            }
                            {featured == 1 &&
                                <Col md={4} sm={4} xs={5} className='text-right'>
                                    الرقم الوطني :
                            </Col>
                            }
                            {featured == 1 &&
                                <Col md={2} sm={2} xs={7} className='font-weight-bold' style={{ color: '#06258a' }}>
                                    {rowData.idNumber}
                                </Col>
                            }
                        </Row>
                        <Collapse
                            className='ml-5 mr-0 subDepartment mt-3 mb-3'
                            key={index}
                            isOpen={this.state.collaps[rowData.id] == undefined ? false : this.state.collaps[rowData.id]}
                        >
                            {this.getSubDepartmentDiv(rowData.id, rowData.featured)}
                        </Collapse>
                    </div>
                )
            })
        } else {
            subDepartmentData.push(
                <Row>
                    <Col className='text-center'>
                        هذا المركز / اللجنة فارغ
                    </Col>
                </Row>
            )
        }
        return subDepartmentData;
    }
    render() {
        var result = []
        if (this.state.loading == 0 && this.state.parentDepartmentData.length > 0) {
            this.state.parentDepartmentData.map((rowData, index) => {
                result.push(<div key={index}>
                    <Row key={index} onClick={(e) => this.getSubDepartment(e, rowData.id, rowData.featured)} className={(this.state.collaps[rowData.id] == true ? "toglleOpen" : '') + " p-1 pt-3 pb-3 mr-2 ml-2 mainDepartment"}>
                        <Col md={8} sm={6} xs={12}>
                            {this.state.collaps[rowData.id] == true ? <i class="fas fa-times"></i> : <i class="fas fa-bars"></i>}
                            &nbsp;&nbsp;
                            <span>{rowData.name}</span>
                            <span>{this.state.deptLoading[rowData.id] == true &&
                                <CircularProgress className="kt-splash-screen__spinner mr-3 ml-3 mt-1 mb-n1" style={{ width: 15, height: 15, color: '#646C9A' }} />
                            }</span>
                        </Col>
                        <Col md={3} sm={4} xs={5} className='text-right'>
                            عدد الناخبين :
                        </Col>
                        <Col md={1} sm={2} xs={7} className='font-weight-bold' style={{ color: '#06258a' }}>
                            {rowData.votersTotal}
                        </Col>
                    </Row>
                    <Collapse
                        className='ml-5 mr-0 subDepartment mt-3 mb-3'
                        key={index}
                        isOpen={this.state.collaps[rowData.id] == undefined ? false : this.state.collaps[rowData.id]}
                    >
                        {rowData.featured == 0 && this.getSubDepartmentDiv(rowData.id, rowData.featured)}
                    </Collapse>
                </div>)
            })
        }
        return (
            <div>
                {(this.state.permations != undefined && this.state.permations == true) ?
                    <div className='kt-portlet kt-portlet--height-fluid p-3 overflow-auto treeReport'>
                        {this.state.loading == 1 &&
                            <div className="data-loading-spinner">Loading...</div>
                        }
                        {(this.state.loading == 0 && this.state.parentDepartmentData.length <= 0) &&
                            <Row>
                                <Col md={12} className='text-center no-data-available-view'>
                                    <img src='/media/electra/no-data-icon-68.png' />
                                    <p>لا يوجد بيانات لعرضها .&nbsp;.&nbsp; !</p>
                                </Col>
                            </Row>
                        }
                        {(this.state.loading == 0 && this.state.parentDepartmentData.length > 0) &&
                            result
                        }
                    </div>
                    :
                    <div>
                        <p className='report-no-permission main-color-1'>
                            <i className='far fa-times-circle'></i> لا تمتلك صلاحية لاستعراض هذه الصفحة
                        </p>
                    </div>
                }
            </div>
        );
    }
}
export default TreeReport;

