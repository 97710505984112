import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label } from 'reactstrap';
import { Button, Drawer } from "@material-ui/core";
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import Pagination from "react-js-pagination";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Picky from 'react-picky';
import 'react-picky/dist/picky.css';

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            multiValue: (Object.keys(this.props.usersListDataMultiValue).length > 0 ? this.props.usersListDataMultiValue : []),
            reportActionList: (Object.keys(this.props.usersListData).length > 0 ? this.props.usersListData : []),
        }
    }

    checkRow = (event, reportId) => {
        const name = event.target.name
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        const newArray = this.state.reportActionList
        var nameElement = name
        newArray[reportId] = value;



        this.setState({ reportActionList: newArray },function(){
            this.props.handleGetTabData(5,this.state.reportActionList,this.state.multiValue)
        });

    }

    setReportActionRadio() {
        return (
            <Row className='text-left mt-1 p-2 pl-5 font-weight-500 main-color-1'>
                <Col md={5} className='text-left mt-3 p-3 pl-5 font-weight-500 main-color-1'>
                    سيتم تطبيق الصلاحية على هذه الاقسام:
                    <ul>
                        <li className='mt-4 mb-4'><h5>المستخدمين من نوع مسؤول</h5></li>
                        <li className='mt-4 mb-4'><h5>المستخدمين من نوع موظف</h5></li>
                    </ul>
                </Col>
                <Col md={7} className='mt-4'>
                    <Row style={{ 'color': 'black' }} className='mt-5 mb-5'>
                        <Col md={6}>
                            <div className="custom-control custom-checkbox p-0">
                                <input type="checkbox" className="custom-control-input" id={'reportAction-1-1'} checked={this.state.reportActionList['0']} name={'0'} onClick={(event) => this.checkRow(event, 0)} />
                                <label className="custom-control-label" htmlFor={'reportAction-1-1'}>&nbsp;</label>
                                <span>كل شيئ</span>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'reportAction-2-2'} disabled={this.state.reportActionList['0']} checked={(this.state.reportActionList['1'] || this.state.reportActionList['0'])} name={'1'} onClick={(event) => this.checkRow(event, 1)} />
                                <label className="custom-control-label" htmlFor={'reportAction-2-2'}>&nbsp;</label>
                                <span>عرض</span>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ 'color': 'black' }}>
                        <Col md={6}>
                            <div className="custom-control custom-checkbox p-0">
                                <input type="checkbox" className="custom-control-input" id={'reportAction-3-3'} disabled={this.state.reportActionList['0']} checked={(this.state.reportActionList['2'] || this.state.reportActionList['0'])} name={'2'} onClick={(event) => this.checkRow(event, 2)} />
                                <label className="custom-control-label" htmlFor={'reportAction-3-3'}>&nbsp;</label>
                                <span>تعديل / اضافة</span>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'reportAction-4-4'} name={'3'} disabled={this.state.reportActionList['0']} checked={(this.state.reportActionList['3'] || this.state.reportActionList['0'])} onClick={(event) => this.checkRow(event, 3)} />
                                <label className="custom-control-label" htmlFor={'reportAction-4-4'}>&nbsp;</label>
                                <span>حذف</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row >
        )
    }

    render() {
        return (
            <div>
                <div className='mt-n5 mr-3 ml-3' style={{ minHeight: 355, maxHeight: 355, backgroundColor: '#ECECEF' }}>
                    <Row className='mt-5'>
                        <Col md={12}>
                            {this.setReportActionRadio()}
                        </Col>

                    </Row>
                </div>
            </div>
        )
    }
}

export default Users;